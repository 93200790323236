import { FC } from 'react';
import { useSelector } from 'react-redux';
import { typeSelector } from 'components/redux/selector';
import { pageSelector } from 'components/redux/selector';
import { nameSelector } from 'Product/redux/selector';
import { AccountDialog } from './AccountDialog';
import { ToggleColorMode } from './ToggleColorMode';
import { AddProduct } from 'components/Header/AddProduct';
import { AddImages } from 'components/Header/AddImages';
import { DeleteProduct } from 'components/Header/DeleteProduct';
import LogoWh from 'assets/logo-wh.png';
import { Toolbar, Box, Typography, Divider } from '@mui/material';

export const Header: FC = () => {
  const type = useSelector(typeSelector);
  const page = useSelector(pageSelector);
  const name = useSelector(nameSelector);

  return (
    <>
      {type === 'campaign' && (
        <>
          <Toolbar>
            <Box sx={{ ml: 1, flexGrow: 1 }}>
              <img src={LogoWh} alt='CloudPIX' style={{ width: '80px' }} />
            </Box>
            {/* 右上ナビゲーション */}
            <AccountDialog page={page} />
          </Toolbar>
        </>
      )}
      {type === 'application' && (
        <>
          {/* ページタイトル */}
          <Typography
            variant='h6'
            component='h1'
            sx={{ flexGrow: 1, ml: 1, pr: 3, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          >
            {page === 'dashboard' && <>ダッシュボード</>}
            {page === 'products' && <>商品一覧</>}
            {page === 'product' && <>{name.input}</>}
            {page === 'settings' && <>設定</>}
            {page === '404' && <>404 Not Found</>}
          </Typography>

          {/* 右上ナビゲーション */}
          {page === 'dashboard' && <AddProduct />}
          {page === 'products' && <AddProduct />}
          {page === 'product' && (
            <>
              <AddImages />
              <DeleteProduct />
            </>
          )}
          <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
          <ToggleColorMode />
          <AccountDialog />
        </>
      )}
    </>
  );
};
