import { FC, createContext, useContext, useState, useEffect } from 'react';
import { auth } from 'app/firebase';
import firebase from 'firebase/app';

const AuthContext = createContext(auth);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User>(undefined!);
  const [loading, setLoading] = useState<boolean>(true);

  // メール/パスワード認証
  const createUserWithEmailAndPassword = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const signInWithEmailAndPassword = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  // サインアウト
  const signOut = () => {
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        setLoading(false);
      } else {
        setLoading(false);
        // console.log('Not Login');
      }
    });
    return unsubscribe;
  }, []);

  const value: any = {
    currentUser,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
