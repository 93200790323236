import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { productsEntitiesSelector } from 'Products/redux/selector';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AppsIcon from '@material-ui/icons/Apps';
import PhotoIcon from '@material-ui/icons/Photo';

export const Products: FC = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const products = useSelector(productsEntitiesSelector);

  return (
    <Box>
      <List disablePadding>
        <ListItem disableGutters sx={{ p: 0 }}>
          <ListItemButton onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <PhotoLibraryIcon />
            </ListItemIcon>
            <ListItemText primary='商品' />
            {isAccordionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
        </ListItem>
      </List>

      <Collapse in={isAccordionOpen} timeout='auto' unmountOnExit>
        <List disablePadding component='div'>
          {products.slice(0, 12).map((product: any) => (
            <ListItemButton component={Link} to={`/product/${product?.id}`} sx={{ pl: 4 }} key={product?.id}>
              <ListItemAvatar>
                {product?.cover?.thumb480url ? (
                  <Avatar src={product?.cover?.thumb480url + '.webp'} alt='thumbnail' />
                ) : (
                  <Avatar>
                    <PhotoIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={product?.name?.input}
                sx={{
                  '& span': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            </ListItemButton>
          ))}
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton component={Link} to='/products' sx={{ pl: 5 }}>
              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary='全ての商品' />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </Box>
  );
};
