import { FC, useState } from 'react';
import { Tooltip, FormControl, OutlinedInput, IconButton, InputAdornment } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CopyToClipBoard from 'react-copy-to-clipboard';

type Props = {
  fullWidth: boolean;
  textData: string;
};

export const CodeToClipboard: FC<Props> = ({ fullWidth, textData }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <FormControl variant='outlined' fullWidth={fullWidth}>
        <OutlinedInput
          type='text'
          fullWidth={fullWidth}
          disabled
          value={textData}
          sx={{
            '& > input': {
              cursor: 'text',
            },
          }}
          startAdornment={
            <InputAdornment position='start' sx={{ mr: 0 }}>
              <Tooltip
                arrow
                open={isOpen}
                onClose={() => setIsOpen(false)}
                disableHoverListener
                placement='top'
                title='Copied!'
                aria-label='add'
              >
                <InputAdornment position='start' sx={{ mr: 0 }}>
                  <CopyToClipBoard text={textData}>
                    <IconButton onClick={() => setIsOpen(true)}>
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipBoard>
                </InputAdornment>
              </Tooltip>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};
