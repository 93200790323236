import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateSelectOptionStatus = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedVender: string;
    section: string;
    subSection: string;
    selectedCategory: string;
    currentOptionId: string;
  }
>(
  'product/updateSelectOptionStatus',
  async ({ uid, productId, selectedVender, section, subSection, selectedCategory, currentOptionId }) => {
    let docsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    let disableTargetIds: string[] = [];

    switch (section) {
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection);
        break;
      default:
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section);
        break;
    }

    // 更新前にstatusになっているdocを取得
    await docsRef
      .where('status', '==', 'enable')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          disableTargetIds.push(doc.id);
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });

    // 取得したdocのstatusをdisableにする
    if (disableTargetIds.length > 0) {
      await Promise.all(
        disableTargetIds.map(async (disableTargetId) => {
          await docsRef
            .doc(disableTargetId)
            .update({
              status: 'disable',
            })
            .then(() => {
              console.log('Document successfully updated!');
            })
            .catch((error) => {
              console.error('Error updating document: ', error);
            });
        })
      );
    }

    // 新しい選択肢のstatusをenableにする
    await docsRef
      .doc(currentOptionId)
      .update({
        status: 'enable',
      })
      .then(() => {
        console.log('Document successfully updated!');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
    // }

    return {
      selectedVender: selectedVender,
      section: section,
      subSection: subSection,
      disableTargetIds: disableTargetIds,
      currentOptionId: currentOptionId,
    };
  }
);
