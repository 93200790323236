import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Products } from 'components/Sidebar/Products';
import { Settings } from 'components/Sidebar/Settings';
import { Footer } from 'components/Sidebar/Footer';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import DashboardIcon from '@material-ui/icons/Dashboard';

export const Sidebar: FC = () => {
  return (
    <Box
      component='nav'
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
    >
      <Box>
        <List>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton component={Link} to='/'>
              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary='ダッシュボード' />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Products />
      <Divider />
      <Settings />
      <Footer />
    </Box>
  );
};
