import { FC } from 'react';
import { Typography, Box } from '@mui/material';

type Props = {
  title: string;
};

export const Section: FC<Props> = ({ title, children }) => {
  return (
    <Box component='section' sx={{ mt: 5, mb: 9 }}>
      <Typography
        component='h1'
        variant='h6'
        paragraph
        sx={{
          py: 1,
          fontWeight: 'bold',
          textAlign: 'center',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
