import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { uidSelector } from 'Auth/redux/selector';
import { typeSelector, isSidebarHiddenSelector } from 'components/redux/selector';
import { updateIsSidebarHidden } from 'components/firebase/updateIsSidebarHidden';
import { Main } from 'components/Main';
import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { Footer } from 'components/Footer';
import LogoWh from 'assets/logo-wh.png';
import Background01 from 'assets/pawel-czerwinski-l8DUam8vtbc-unsplash.jpg';
// import Background02 from 'assets/vojtech-bruzek-mCjA1I8SlS8-unsplash.jpg';
import Background02 from 'assets/bady-abbas-hxi_yRxODNc-unsplash.jpg';
import Background03 from 'assets/mink-mingle-AlEyuIKuSmc-unsplash.jpg';
import Background04 from 'assets/mitchell-luo-8qodBMf7MqQ-unsplash.jpg';
// import Background05 from 'assets/sharon-mccutcheon-62vi3TG5EDg-unsplash.jpg';
import { Box, Stack, Toolbar, Divider, Slide, useScrollTrigger } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

// 背景画像をランダムに表示する
const backgroundImages = [Background01, Background02, Background03, Background04];
const backgroundImageNumber = Math.floor(Math.random() * backgroundImages.length);
const backgroundImage = backgroundImages[backgroundImageNumber];

export const Layout: FC = ({ children }) => {
  const uid = useSelector(uidSelector);
  const type = useSelector(typeSelector);
  const isSidebarHidden = useSelector(isSidebarHiddenSelector);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleUpdateIsSidebarHidden = () => {
    dispatch(updateIsSidebarHidden({ uid, newIsSidebarHidden: !isSidebarHidden }));
  };

  const trigger = useScrollTrigger();

  return (
    <>
      {type === 'fullpage' && <>{children}</>}
      {type === 'onBackground' && (
        <>
          <Box
            sx={{
              display: 'flex',
              height: '100vh',
              alignItems: 'center',
              backgroundImage: `url(${backgroundImage})`,
              // backgroundImage: 'url(https://unsplash.com/collections/8961198/patterns)',
              backgroundSize: 'cover',
            }}
          >
            {children}
          </Box>
        </>
      )}
      {type === 'campaign' && (
        <>
          {/* ヘッダー */}
          <Slide appear={false} direction='down' in={!trigger}>
            <AppBar>
              <Header />
            </AppBar>
          </Slide>
          <Toolbar />

          {/* メイン */}
          <Main>{children}</Main>

          {/* フッター */}
          <Footer />
        </>
      )}
      {type === 'application' && (
        <Box sx={{ display: 'flex' }}>
          {/* ヘッダー */}
          <AppBar position='fixed' open={!isSidebarHidden}>
            <Toolbar component='nav'>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleUpdateIsSidebarHidden}
                edge='start'
                sx={{
                  mr: 4,
                  ...(!isSidebarHidden && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Header />
            </Toolbar>
          </AppBar>

          {/* サイドバー */}
          <Drawer variant='permanent' open={!isSidebarHidden}>
            <DrawerHeader>
              <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ ml: 1 }}>
                  <img src={LogoWh} alt='CloudPIX' style={{ width: '80px' }} />
                </Box>
                <IconButton onClick={handleUpdateIsSidebarHidden}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </Stack>
            </DrawerHeader>
            <Divider />
            <Sidebar />
          </Drawer>

          {/* メイン */}
          <Box component='main' sx={{ flexGrow: 1, px: 2, py: 0 }}>
            <DrawerHeader />
            <Main>{children}</Main>
          </Box>
        </Box>
      )}
    </>
  );
};
