import { FC, ChangeEvent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { productEntitiesSelector } from 'Product/redux/selector';
import { appearancesSelector } from 'Product/redux/selector';
import { paymentSelector } from 'Product/redux/selector';
import { useDispatch } from 'react-redux';
import { updateOptionStatus } from 'Product/firebase/updateOptionStatus';
import { updateInputEn } from 'Product/firebase/updateInputEn';
import { updateOrderUp } from 'Product/firebase/updateOrderUp';
import { updateOrderDown } from 'Product/firebase/updateOrderDown';
import { deleteOption } from 'Product/firebase/deleteOption';
import { addOption } from 'Product/firebase/addOption';
import { getOptions } from 'Product/firebase/getOptions';
import { initOptions } from 'Product/firebase/initOptions';
import { useParams } from 'react-router-dom';
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Tooltip,
  ClickAwayListener,
  Input,
  Stack,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  title: string;
  selectedVender: string;
  section: string;
  subSection: string;
  selectedCategory: string;
};

export const Options: FC<Props> = ({ title, selectedVender, section, subSection, selectedCategory }) => {
  const dispatch = useDispatch();
  const uid = useSelector(uidSelector);
  const product = useSelector(productEntitiesSelector);
  const appearances = useSelector(appearancesSelector);
  const payment = useSelector(paymentSelector);
  const { productId } = useParams<{ productId: string }>();
  const [newInputEn, setNewInputEn] = useState('');
  const [isInputActive, setIsInputActive] = useState('');
  const [newOption, setNewOption] = useState<string>('');
  let options;

  useEffect(() => {
    if (selectedCategory) {
      dispatch(
        initOptions({
          uid: uid,
          productId: productId,
          selectedVender: selectedVender,
          section: section,
          subSection: subSection,
          selectedCategory: selectedCategory,
        })
      );
    }
    return () => {
      if (selectedCategory) {
        dispatch(
          initOptions({
            uid: uid,
            productId: productId,
            selectedVender: selectedVender,
            section: section,
            subSection: subSection,
            selectedCategory: selectedCategory,
          })
        );
      }
    };
  }, [dispatch, productId, section, selectedVender, subSection, selectedCategory, uid]);

  // optionsをセット
  switch (section) {
    case 'categories':
      switch (subSection) {
        case 'appearances':
          // setOptions(appearances);
          options = appearances;
          break;
        default:
          break;
      }
      break;
    case 'payment':
      // setOptions(payment);
      options = payment;
      break;
    default:
      break;
  }

  // checkbox
  const handleCheckboxChange = (status: 'enable' | 'disable', currentOptionId: string) => {
    dispatch(
      updateOptionStatus({
        uid: uid,
        productId: productId,
        selectedVender: selectedVender,
        section: section,
        subSection: subSection,
        currentOptionId: currentOptionId,
        selectedCategory: selectedCategory,
        status: status,
      })
    );
  };

  // 英訳inputのアクティブ切り替え
  const handleInputActive = (newOption: string) => {
    setNewInputEn('');
    setIsInputActive(newOption);
  };

  // 英訳input
  const handlenewInputEnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewInputEn(e.target.value);
  };

  // 要素外がクリックされた場合は元の状態に戻す
  const handleClickAway = () => {
    setNewInputEn('');
    setIsInputActive('');
  };

  // 英訳を変更
  const handleUpdateInputEn = (currentOptionId: string) => {
    dispatch(
      updateInputEn({
        uid: uid,
        productId: product.id,
        selectedVender: selectedVender,
        section: section,
        subSection: subSection,
        currentOptionId: currentOptionId,
        newInputEn: newInputEn,
        selectedCategory: selectedCategory,
      })
    );
  };

  // 新しい項目のinput
  const handleNewOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewOption(e.target.value);
  };

  // DBに新しい選択肢のdocを追加する
  const handleNewOptionAdd = () => {
    dispatch(
      addOption({
        uid: uid,
        productId: productId,
        selectedVender: selectedVender,
        selectedCategory: selectedCategory,
        section: section,
        subSection: subSection,
        newOption: newOption,
        optionsLength: options.length,
      })
    );
    setNewOption('');
    setIsInputActive('');
    setTimeout(() => {
      dispatch(
        getOptions({
          uid: uid,
          selectedVender: selectedVender,
          section: section,
          subSection: subSection,
          productId: productId,
          selectedCategory: selectedCategory,
        })
      );
    }, 2500);
  };

  return (
    <Box component='section'>
      <Table size='small'>
        <TableBody>
          {options?.map((option: any, index: number) => (
            <TableRow key={option.id}>
              {index === 0 && (
                <TableCell sx={{ width: '12%', px: 1 }} rowSpan={options.length + 1}>
                  {title}
                </TableCell>
              )}

              <TableCell sx={{ width: '37%', px: 1 }}>
                {/* Japanese */}
                <FormGroup row>
                  {option.status === 'disable' ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={false}
                          name={option.id}
                          onChange={() => handleCheckboxChange('enable', option.id)}
                        />
                      }
                      label={option.input ? option.input : option.ja}
                      sx={{
                        '& span': {
                          fontSize: (theme) => theme.typography.body2.fontSize,
                        },
                      }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={true}
                          name={option.id}
                          onChange={() => handleCheckboxChange('disable', option.id)}
                        />
                      }
                      label={option.input ? option.input : option.ja}
                      sx={{
                        '& span': {
                          fontSize: (theme) => theme.typography.body2.fontSize,
                        },
                      }}
                    />
                  )}
                </FormGroup>
              </TableCell>

              <TableCell sx={{ width: '37%', px: 1 }}>
                {/* English */}
                {isInputActive === option.id ? (
                  // 入力フォームを表示
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Input
                        placeholder='English'
                        defaultValue={option?.inputEn ? option?.inputEn : option?.translated?.en}
                        value={newInputEn ? newInputEn : option?.inputEn ? option?.inputEn : option?.translated?.en}
                        onChange={handlenewInputEnChange}
                        fullWidth
                        multiline
                      />
                    </ClickAwayListener>
                    <Tooltip title='保存する'>
                      <IconButton aria-label='save'>
                        <SaveAltIcon onClick={() => handleUpdateInputEn(option.id)} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  // テキストを表示
                  <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                    {/* 英語表記：チェックされている時に表示する */}
                    <Box>
                      {option.status !== 'disable' && (
                        <>{option.en ? option.en : option.inputEn ? option.inputEn : option.translated?.en}</>
                      )}
                    </Box>
                    {/* アイコン：チェックされていない時はdisabledにする */}
                    {option.status !== 'disable' ? (
                      <Tooltip title='英語表記を修正する'>
                        <IconButton aria-label='edit'>
                          <CreateIcon onClick={() => handleInputActive(option.id)} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton aria-label='desabled' disabled>
                        <CreateIcon />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </TableCell>

              <TableCell align='right' sx={{ width: '14%', whiteSpace: 'nowrap', px: 1 }}>
                {/* Up */}
                {option.order !== 0 ? (
                  <IconButton aria-label='edit'>
                    <KeyboardArrowUpIcon
                      onClick={() =>
                        dispatch(
                          updateOrderUp({
                            uid: uid,
                            productId: productId,
                            selectedVender: selectedVender,
                            section: section,
                            subSection: subSection,
                            currentOptionId: option.id,
                            currentOptionOrder: option.order,
                            selectedCategory: selectedCategory,
                          })
                        )
                      }
                    />
                  </IconButton>
                ) : (
                  <IconButton aria-label='edit' disabled>
                    <KeyboardArrowUpIcon color='disabled' />
                  </IconButton>
                )}

                {/* Down */}
                {option.order !== options.length - 1 ? (
                  <IconButton aria-label='edit'>
                    <KeyboardArrowDownIcon
                      onClick={() =>
                        dispatch(
                          updateOrderDown({
                            uid: uid,
                            productId: productId,
                            selectedVender: selectedVender,
                            section: section,
                            subSection: subSection,
                            currentOptionId: option.id,
                            currentOptionOrder: option.order,
                            selectedCategory: selectedCategory,
                          })
                        )
                      }
                    />
                  </IconButton>
                ) : (
                  <IconButton aria-label='edit' disabled>
                    <KeyboardArrowDownIcon color='disabled' />
                  </IconButton>
                )}

                {/* Delete */}
                <Tooltip title='この項目を削除'>
                  <IconButton aria-label='edit'>
                    <DeleteIcon
                      onClick={() =>
                        dispatch(
                          deleteOption({
                            uid: uid,
                            productId: productId,
                            selectedVender: selectedVender,
                            section: section,
                            subSection: subSection,
                            currentOptionId: option.id,
                            optionOrder: option.order,
                            selectedCategory: selectedCategory,
                          })
                        )
                      }
                      color='disabled'
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2}>
              <TextField
                label='新しい項目（日本語で入力）'
                color='secondary'
                value={newOption}
                onChange={handleNewOptionChange}
                fullWidth
                multiline
                sx={{ my: 2 }}
              />
            </TableCell>
            <TableCell>
              <Tooltip title='追加する'>
                <IconButton aria-label='edit'>
                  <AddCircleOutlineIcon onClick={handleNewOptionAdd} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
