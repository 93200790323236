import { createSelector } from 'reselect';
import { RootState } from 'store';
import { CategoriesState } from 'Settings/Categories/redux/slice';

export const categoriesSelector: (state: RootState) => CategoriesState = (state: RootState) => state.categories;

// export const categoriesEntitiesSelector = createSelector(categoriesSelector, (categories) => {
//   return categories.entities;
// });

// export const primarySelector = createSelector(categoriesSelector, (categories) => {
//   return categories.primary;
// });

// export const secondarySelector = createSelector(categoriesSelector, (categories) => {
//   return categories.secondary;
// });

// export const tertiarySelector = createSelector(categoriesSelector, (categories) => {
//   return categories.tertiary;
// });

export const presetCategoriesSelector = createSelector(categoriesSelector, (categories) => {
  return categories.presetCategories;
});

export const preparedCategoriesSelector = createSelector(categoriesSelector, (categories) => {
  return categories.preparedCategories;
});

export const activeCategoriesSelector = createSelector(categoriesSelector, (categories) => {
  return categories.activeCategories;
});

// export const subscriptionsSelector = createSelector(categoriesSelector, (categories) => {
//   return categories.entities.subscriptions;
// });
