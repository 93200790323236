import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from 'components/redux/slice';
import { uidSelector } from 'Auth/redux/selector';
import { pageSelector } from 'components/redux/selector';
import { productsEntitiesSelector } from 'Products/redux/selector';
import { isDocEmptySelector } from 'Products/redux/selector';
import { lastDocSelector } from 'Products/redux/selector';
import { getProducts } from 'Products/firebase/getProducts';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CardGrid } from './CardGrid';
import { Box, Button } from '@mui/material';

export const Products: FC = () => {
  const uid = useSelector(uidSelector);
  const page = useSelector(pageSelector);
  const products = useSelector(productsEntitiesSelector);
  const isDocEmpty = useSelector(isDocEmptySelector);
  const lastDoc = useSelector(lastDocSelector);
  const dispatch = useDispatch();

  const handleFetchMore = () => {
    dispatch(getProducts({ uid, limit: 18, isDocEmpty, lastDoc, fetchMore: true }));
  };

  useEffect(() => {
    dispatch(setPage('products'));
    return () => {
      dispatch(setPage('products'));
    };
  }, [dispatch]);

  return (
    <>
      {page === 'products' && (
        <>
          <Box id='scrollableDiv' sx={{ width: '100%', height: '100vh', overflow: 'auto' }}>
            <InfiniteScroll
              dataLength={products.length}
              next={handleFetchMore}
              hasMore={true}
              scrollableTarget='scrollableDiv'
              // loader={!isDocEmpty && <h4>Loading...</h4>}
              loader={<div>　</div>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <CardGrid />
            </InfiniteScroll>
          </Box>
          {!isDocEmpty && <Button onClick={handleFetchMore}>more</Button>}
        </>
      )}
      {page === 'dashboard' && (
        <>
          <CardGrid />
          <Box sx={{ my: 5 }}>
            <Button variant='contained' color='primary' size='large' fullWidth component={Link} to='/products'>
              全ての商品を表示
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
