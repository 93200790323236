import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getCategories = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; selectedVender: string }
>('templates/getCategories', async ({ uid, selectedVender }) => {
  let res: firebase.firestore.DocumentData = [];

  const docsRef = db.collection('users').doc(uid).collection('templates').doc(selectedVender).collection('categories');

  await docsRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      res.push({ id: doc.id, ...doc.data() });
    });
  });

  return { res: res };
});
