import firebase from 'firebase/app';
import { createSlice } from '@reduxjs/toolkit';
import { getVenders } from 'Settings/Templates/firebase/getVenders';
import { getCategories } from 'Settings/Templates/firebase/getCategories';
import { deleteAtPath } from 'Settings/Templates/firebase/deleteAtPath';
// import { getPresetOptions } from 'Settings/Templates/firebase/getPresetOptions';
import { getOptions } from 'Settings/Templates/firebase/getOptions';
import { updateOptionOrderUp } from 'Settings/Templates/firebase/updateOptionOrderUp';
import { updateOptionOrderDown } from 'Settings/Templates/firebase/updateOptionOrderDown';
import { addOption } from 'Settings/Templates/firebase/addOption';
import { deleteOption } from 'Settings/Templates/firebase/deleteOption';
import { updateInputEn } from 'Settings/Templates/firebase/updateInputEn';
import { initOptions } from 'Settings/Templates/firebase/initOptions';

export interface TemplatesState {
  venders: firebase.firestore.DocumentData[];
  categories: firebase.firestore.DocumentData[];
  grades: firebase.firestore.DocumentData[];
  appearances: firebase.firestore.DocumentData[];
  payment: firebase.firestore.DocumentData[];
  status: string | null;
}

const initialState: TemplatesState = {
  venders: [],
  categories: [],
  grades: [],
  appearances: [],
  payment: [],
  status: null,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    resetAppearances: (state) => {
      state.appearances = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // venderを取得
      .addCase(getVenders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getVenders.fulfilled, (state, action) => {
        state.status = 'success';
        state.venders = action.payload.venders;
      })
      .addCase(getVenders.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // categoryを取得
      .addCase(getCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = 'success';
        state.categories = action.payload.res;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // categoryを削除
      .addCase(deleteAtPath.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAtPath.fulfilled, (state, action) => {
        state.status = 'success';
        state.categories = state.categories.filter((v: any) => v.id !== action.payload.selectedCategoryId);
      })
      .addCase(deleteAtPath.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // プリセットoptionを取得
      // .addCase(getPresetOptions.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(getPresetOptions.fulfilled, (state, action) => {
      //   state.status = 'success';
      //   switch (action.payload.section) {
      //     case 'appearances':
      //       state.appearances = [...action.payload.res];
      //       break;
      //     case 'payment':
      //       state.payment = [...action.payload.res];
      //       break;
      //     default:
      //       break;
      //   }
      // })
      // .addCase(getPresetOptions.rejected, (state, action) => {
      //   state.status = 'failed';
      //   console.log('action.error', action.error);
      // })

      // optionを取得
      .addCase(getOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                state.appearances = [...action.payload.res];
                break;
              case 'appearances':
                state.appearances = [...action.payload.res];
                break;
              default:
                break;
            }
            break;
          case 'payment':
            state.payment = [...action.payload.res];
            break;
          default:
            break;
        }
      })
      .addCase(getOptions.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // // カテゴリー別optionを取得
      // .addCase(getOptionsByCategory.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(getOptionsByCategory.fulfilled, (state, action) => {
      //   state.status = 'success';
      //   switch (action.payload.section) {
      //     case 'appearances':
      //       state.appearances = [...action.payload.res];
      //       break;
      //     default:
      //       break;
      //   }
      // })
      // .addCase(getOptionsByCategory.rejected, (state, action) => {
      //   state.status = 'failed';
      //   console.log('action.error', action.error);
      // })

      // 英訳を追加
      .addCase(updateInputEn.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateInputEn.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                const GRADES = state.grades.find((v: any) => v.id === action.payload.optionId);
                if (GRADES) {
                  GRADES.inputEn = action.payload.inputEn;
                }
                break;
              case 'appearances':
                const APPEARANCES = state.appearances.find((v: any) => v.id === action.payload.optionId);
                if (APPEARANCES) {
                  APPEARANCES.inputEn = action.payload.inputEn;
                }
                break;
              default:
                break;
            }
            break;
          case 'payment':
            const PAYMENT = state.payment.find((v: any) => v.id === action.payload.optionId);
            if (PAYMENT) {
              PAYMENT.inputEn = action.payload.inputEn;
            }
            break;
          default:
            break;
        }
      })
      .addCase(updateInputEn.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // optionの順序を上げる
      .addCase(updateOptionOrderUp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOptionOrderUp.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                const GRADES = state.grades.find((v: any) => v.id === action.payload.optionId);
                if (GRADES) {
                  GRADES.order -= 1;
                }
                const GRADES_upperTarget = state.grades.find((v: any) => v.id === action.payload.sameOrderDocId);
                if (GRADES_upperTarget) {
                  GRADES_upperTarget.order += 1;
                }
                state.grades.sort(function (a, b) {
                  return a.order - b.order;
                });
                break;
              case 'appearances':
                const APPEARANCES = state.appearances.find((v: any) => v.id === action.payload.optionId);
                if (APPEARANCES) {
                  APPEARANCES.order -= 1;
                }
                const APPEARANCES_upperTarget = state.appearances.find(
                  (v: any) => v.id === action.payload.sameOrderDocId
                );
                if (APPEARANCES_upperTarget) {
                  APPEARANCES_upperTarget.order += 1;
                }
                state.appearances.sort(function (a, b) {
                  return a.order - b.order;
                });
                break;

              default:
                break;
            }
            break;
          case 'payment':
            const PAYMENT = state.payment.find((v: any) => v.id === action.payload.optionId);
            if (PAYMENT) {
              PAYMENT.order -= 1;
            }
            const PAYMENT_upperTarget = state.payment.find((v: any) => v.id === action.payload.sameOrderDocId);
            if (PAYMENT_upperTarget) {
              PAYMENT_upperTarget.order += 1;
            }
            state.payment.sort(function (a, b) {
              return a.order - b.order;
            });
            break;
          default:
            break;
        }
      })
      .addCase(updateOptionOrderUp.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // optionの順序を下げる
      .addCase(updateOptionOrderDown.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOptionOrderDown.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                const GRADES = state.grades.find((v: any) => v.id === action.payload.optionId);
                if (GRADES) {
                  GRADES.order += 1;
                }
                const GRADES_lowerTarget = state.grades.find((v: any) => v.id === action.payload.sameOrderDocId);
                if (GRADES_lowerTarget) {
                  GRADES_lowerTarget.order -= 1;
                }
                state.grades.sort(function (a, b) {
                  return a.order - b.order;
                });
                break;
              case 'appearances':
                const APPEARANCES = state.appearances.find((v: any) => v.id === action.payload.optionId);
                if (APPEARANCES) {
                  APPEARANCES.order += 1;
                }
                const APPEARANCES_lowerTarget = state.appearances.find(
                  (v: any) => v.id === action.payload.sameOrderDocId
                );
                if (APPEARANCES_lowerTarget) {
                  APPEARANCES_lowerTarget.order -= 1;
                }
                state.appearances.sort(function (a, b) {
                  return a.order - b.order;
                });
                break;

              default:
                break;
            }
            break;
          case 'payment':
            const PAYMENT = state.payment.find((v: any) => v.id === action.payload.optionId);
            if (PAYMENT) {
              PAYMENT.order += 1;
            }
            const PAYMENT_lowerTarget = state.payment.find((v: any) => v.id === action.payload.sameOrderDocId);
            if (PAYMENT_lowerTarget) {
              PAYMENT_lowerTarget.order -= 1;
            }
            state.payment.sort(function (a, b) {
              return a.order - b.order;
            });
            break;
          default:
            break;
        }
      })
      .addCase(updateOptionOrderDown.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 新しいoptionを追加
      .addCase(addOption.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addOption.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.section) {
              case 'grades':
                state.grades = [
                  ...state.grades,
                  {
                    id: action.payload.newOptionId,
                    input: action.payload.newOption,
                    order: action.payload.optionsLength,
                  },
                ];
                break;
              case 'appearances':
                state.appearances = [
                  ...state.appearances,
                  {
                    id: action.payload.newOptionId,
                    input: action.payload.newOption,
                    order: action.payload.optionsLength,
                  },
                ];
                break;
              default:
                break;
            }
            break;
          case 'payment':
            state.payment = [
              ...state.payment,
              {
                id: action.payload.newOptionId,
                input: action.payload.newOption,
                order: action.payload.optionsLength,
              },
            ];
            break;
          default:
            break;
        }
      })
      .addCase(addOption.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // optionを削除
      .addCase(deleteOption.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteOption.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                state.grades = state.grades.filter((v: any) => v.id !== action.payload.optionId);
                action.payload.upperOrderDocIds.map((upperOrderDocId: any) => {
                  const target = state.grades.find((v: any) => v.id === upperOrderDocId);
                  if (target) {
                    target.order -= 1;
                  }
                });
                break;
              case 'appearances':
                state.appearances = state.appearances.filter((v: any) => v.id !== action.payload.optionId);
                action.payload.upperOrderDocIds.map((upperOrderDocId: any) => {
                  const target = state.appearances.find((v: any) => v.id === upperOrderDocId);
                  if (target) {
                    target.order -= 1;
                  }
                });
                break;
              default:
                break;
            }
            break;
          case 'payment':
            state.payment = state.payment.filter((v: any) => v.id !== action.payload.optionId);
            action.payload.upperOrderDocIds.map((upperOrderDocId: any) => {
              const target = state.payment.find((v: any) => v.id === upperOrderDocId);
              if (target) {
                target.order -= 1;
              }
            });
            break;
          default:
            break;
        }
      })
      .addCase(deleteOption.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // optionをプリセットに戻す
      .addCase(initOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initOptions.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                state.grades = action.payload.presetOptions;
                break;
              case 'appearances':
                state.appearances = action.payload.presetOptions;
                break;
              default:
                break;
            }
            break;
          case 'payment':
            state.payment = action.payload.presetOptions;
            break;
          default:
            break;
        }
      })
      .addCase(initOptions.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      });

    // .addMatcher(
    //   (action) => action.type.endsWith('/rejected'),
    //   (state, action) => {
    //     state[action.meta.requestId] = 'rejected';
    //   }
    // )
    // .addDefaultCase((state, action) => {
    //   state.otherActions++;
    // });
  },
});

// Later, dispatch the thunk as needed in the app
// dispatch(fetchUserById(123))

// Action creators are generated for each case reducer function
export const { resetAppearances } = templatesSlice.actions;
export default templatesSlice.reducer;
