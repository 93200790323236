import { FC, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { AppDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { isDocEmptySelector } from 'Products/redux/selector';
import { lastDocSelector } from 'Products/redux/selector';
import { productEntitiesSelector } from 'Product/redux/selector';
import { imagesSelector } from 'Product/redux/selector';
import { addImages } from 'Product/firebase/addImages';
import { uploadFilesToStorage } from 'Product/firebase/uploadFilesToStorage';
import { useDropzone } from 'react-dropzone';
import { DropZoneFiles } from 'types';
import { getProducts } from 'Products/firebase/getProducts';
import { Button, ImageList, ImageListItem, ImageListItemBar, Typography, Box, Stack, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { setTimeout } from 'timers';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SetImages: FC<Props> = ({ setIsOpen }) => {
  const uid = useSelector(uidSelector);
  const product = useSelector(productEntitiesSelector);
  const images = useSelector(imagesSelector);
  const isDocEmpty = useSelector(isDocEmptySelector);
  const lastDoc = useSelector(lastDocSelector);
  const [error, setError] = useState('');
  const [files, setFiles] = useState<DropZoneFiles[]>([]);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const dispatch: AppDispatch = useDispatch();

  // files配列をファイル名の昇順に並べ替える
  const sortedFiles = files.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else {
      return -1;
    }
  });

  // プレビューの追加
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  // Fileバリデーション
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg, image/webp, image/gif',
    maxSize: 1024 * 1024 * 30,
    // maxFiles: 31,
  });

  // DropZoneエラーメッセージ
  let fileRejectionMsg = (
    <Typography>
      {fileRejections.map(({ file, errors }) => {
        return (
          <Typography key={file.name}>
            <Typography component='span' color='secondary'>
              {file.name}
            </Typography>{' '}
            ： {errors.map((e) => e.message).join(',')}
          </Typography>
        );
      })}
    </Typography>
  );

  // リセットボタンが押下されたらStateをリセットする
  const handleReset = () => {
    // 登録ボタンをアクティブに戻す
    setIsButtonActive(true);
    // ファイルをリセット
    setFiles([]);
    // エラーをリセット
    setError('');
    onDrop([]);
  };

  // 登録ボタンが押下されたら処理を開始
  const handleUpload = async () => {
    setIsButtonActive(false);

    // ファイル数が30を超える場合はエラーを返す
    if (files.length > 30) {
      setError('一度にアップロードできる画像は30枚までです');
      return;
    }

    // 同名ファイルがある場合はエラーを返す
    let isDeplicate;
    if (images.length > 0) {
      files.forEach((file) => {
        if (images.find((v) => v.originalFileName === file.name)) {
          setError('同名のファイルが既に存在します');
          isDeplicate = true;
        }
      });
    }

    // エラーの場合は処理を中断する
    if (isDeplicate || !files) {
      setIsButtonActive(false);
      return;
    }

    // アップロード処理
    // Storageにアップロード
    await uploadFilesToStorage({ uid: uid, productId: product.id, files: files });
    // setTimeout(async () => {
    // DBに画像情報を追加
    await dispatch(addImages({ uid: uid, files: files, images: images, productId: product.id }));
    // productsを再取得してサイドバーを更新する
    await dispatch(getProducts({ uid: uid, limit: 18, isDocEmpty: isDocEmpty, lastDoc: lastDoc, fetchMore: false }));
    // リセット
    handleReset();
    setIsOpen(false);
    // }, files.length * 2000);
  };

  // console.log('error', error);

  return (
    <>
      {error && (
        <Alert severity='error' sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {isDragActive ? (
        <Box
          {...getRootProps()}
          sx={{
            borderWidth: 2,
            borderColor: (theme) => theme.palette.grey[500],
            borderStyle: 'solid',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            cursor: 'context-menu',
            mb: 3,
          }}
        >
          <input {...getInputProps()} />
          <Typography sx={{ py: 7, px: 7 }}>ここにドラッグ&amp;ドロップ、またはクリック</Typography>
        </Box>
      ) : (
        <Box
          {...getRootProps()}
          sx={{
            borderWidth: 2,
            borderColor: (theme) => theme.palette.grey[500],
            borderStyle: 'dashed',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            cursor: 'context-menu',
            mb: 3,
          }}
        >
          <input {...getInputProps()} />
          <Typography sx={{ py: 7, px: 7 }}>ここにドラッグ&amp;ドロップ、またはクリック</Typography>
        </Box>
      )}
      {files.length > 0 && <Box sx={{ mb: 2 }}>{fileRejectionMsg}</Box>}

      <Stack direction='row' spacing={1}>
        <Button variant='outlined' size='large' startIcon={<RotateLeftIcon />} onClick={handleReset}>
          リセット
        </Button>
        {isButtonActive ? (
          <Button variant='contained' size='large' color='primary' startIcon={<SaveAltIcon />} onClick={handleUpload}>
            登録する
          </Button>
        ) : (
          <LoadingButton
            loading
            loadingPosition='start'
            variant='contained'
            size='large'
            color='primary'
            startIcon={<SaveAltIcon />}
          >
            登録する
          </LoadingButton>
        )}
      </Stack>

      <Box component='aside' sx={{ mt: 5 }}>
        <ImageList rowHeight={200} cols={3} gap={8}>
          {sortedFiles.map((file) => (
            <ImageListItem key={file.preview}>
              <img src={file.preview} alt={file.name} loading='lazy' style={{ overflow: 'hidden' }} />
              <ImageListItemBar title={file.name} subtitle={Math.round(file.size / 1000) + ' kB'} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
};
