import { createSelector } from 'reselect';
import { RootState } from 'store';
import { AuthState } from 'Auth/redux/slice';

const authSelector: (state: RootState) => AuthState = (state: RootState) => state.auth;

export const displayNameSelector = createSelector(authSelector, (auth) => {
  return auth.displayName;
});

export const emailSelector = createSelector(authSelector, (auth) => {
  return auth.email;
});

export const emailVerifiedSelector = createSelector(authSelector, (auth) => {
  return auth.emailVerified;
});

export const isUserAuthenticatedSelector = createSelector(authSelector, (auth) => {
  return auth.authenticated;
});

export const uidSelector = createSelector(authSelector, (auth) => {
  return auth.uid;
});

export const errorSelector = createSelector(authSelector, (auth) => {
  return auth.error;
});

export const providerDataSelector = createSelector(authSelector, (auth) => {
  return auth.providerData;
});
