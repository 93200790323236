import { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  text: CSSProperties['color'];
  background: CSSProperties['backgroundColor'];
};

export const useStyles = makeStyles(() => ({
  article: {
    margin: '24px 0',
    padding: '40px',
    backgroundColor: 'white',
    color: (styleProps: Props) => styleProps.text,
    fontSize: '16px',
    lineHeight: '1.8',
    '& ul': {
      margin: '8px 0 0',
      padding: 0,
      listStyle: 'none',
    },
  },
  header: {},
  headerProductName: {
    margin: 0,
    lineHeight: 1.5,
    fontWeight: 'bold',
    fontSize: '32px',
  },
  thumbs: {
    marginTop: '32px',
  },
  thumbsUl: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gap: '8px',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  thumbsLi: {
    position: 'relative',
    height: 0,
    paddingBottom: '100%' /* 幅に対する縦のサイズ */,
    overflow: 'hidden',
  },
  thumbsItem: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 'auto',
    height: 'auto',
    maxWidth: '179%' /* 横向き16:9の画像が表示エリア一杯になるように指定 */,
    maxHeight: 'initial',
  },
  notes: {},
  section: {
    marginTop: '32px',
  },
  sectionTitle: {
    margin: 0,
    padding: '4px 16px',
    backgroundColor: (styleProps: Props) => styleProps.background,
    borderRadius: '3px',
    color: 'white',
    fontSize: '21px',
  },
  subsectionTitle: {
    margin: '24px 8px 0',
    fontSize: '24px',
  },
  subsectionBody: {
    padding: '0 19px',
  },
  images: {
    marginTop: '32px',
  },
  imagesUl: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1,1fr)',
    gap: '8px',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  imagesLi: {
    lineHeight: 0,
  },
  imagesItem: {
    width: '100%',
    borderRadius: '3px',
  },
}));
