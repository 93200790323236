import { FC, Dispatch, SetStateAction } from 'react';
import { AppDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { isDocEmptySelector } from 'Products/redux/selector';
import { lastDocSelector } from 'Products/redux/selector';
import { productEntitiesSelector } from 'Product/redux/selector';
import { imagesSelector } from 'Product/redux/selector';
import { deleteAtPath } from 'app/deleteAtPath';
import { getProducts } from 'Products/firebase/getProducts';
import { buildThumbUrls } from 'components/buildThumbUrls';
import { deleteFilesFromStorage } from 'Product/firebase/deleteFilesFromStorage';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

type Props = {
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export const ConfirmDialog: FC<Props> = ({ isDialogOpen, setIsDialogOpen }) => {
  const uid = useSelector(uidSelector);
  const isDocEmpty = useSelector(isDocEmptySelector);
  const lastDoc = useSelector(lastDocSelector);
  const product = useSelector(productEntitiesSelector);
  const images = useSelector(imagesSelector);
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const handloDeleteProduct = async () => {
    // DBの商品を削除
    await dispatch(deleteAtPath({ path: `users/${uid}/products/${product.id}`, productId: product.id }));
    // Storageの画像を削除する
    images.forEach((image) => {
      const { thumb480, thumb960, thumb1920, square480, square960, square1920 } = buildThumbUrls({
        uid: uid,
        productId: product.id,
        originalFileName: image.originalFileName,
      });
      deleteFilesFromStorage({
        uid: uid,
        productId: product.id,
        originalFileName: image.originalFileName,
        thumb480: thumb480,
        thumb960: thumb960,
        thumb1920: thumb1920,
        square480: square480,
        square960: square960,
        square1920: square1920,
      });
    });
    setIsDialogOpen(false);
    await dispatch(getProducts({ uid, limit: 18, isDocEmpty, lastDoc, fetchMore: false }));
    history.push({ pathname: '/products' });
  };

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        keepMounted
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle>
          {product?.name?.input ? product?.name?.input : 'この商品'}
          を削除してよろしいですか？
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color='secondary'>
            キャンセル
          </Button>
          <Button onClick={handloDeleteProduct} color='secondary'>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
