import { FC, useState, ChangeEvent } from 'react';
import { useSelector } from 'store';
import { emailSelector } from 'Auth/redux/selector';
import { emailVerifiedSelector } from 'Auth/redux/selector';
import { useAuth } from 'contexts/AuthContext';
import { auth } from 'app/firebase';
import { SnackbarAlert } from 'components/SnackbarAlert';
import { Typography, Stack, Box, Button, TextField } from '@mui/material';

export const Email: FC = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [info, setInfo] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const email = useSelector(emailSelector);
  const emailVerified = useSelector(emailVerifiedSelector);
  const [newEmail, setNewEmail] = useState(email);

  // inputの状態管理
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value);
  };

  const updateEmail = (email: string) => {
    if (currentUser) {
      return currentUser.updateEmail(email);
    }
  };

  // メールの確認が済んでいない場合は確認メールを送信
  const sendEmail = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      await auth.currentUser.sendEmailVerification();
      setIsOpen(true);
      setInfo('確認用メールを送信しました');
      return;
    } else if (auth.currentUser && auth.currentUser.emailVerified) {
      setSuccess('メールアドレスを変更しました');
      return;
    }
  };

  // const handleSubmit = () => {};

  const handleSubmit = () => {
    if (currentUser) {
      const promises = [];
      // setLoading(true);
      setError('');

      if (email && email !== currentUser.email) {
        promises.push(updateEmail(email));
      }

      Promise.all(promises)
        .then(() => {
          // history.push('/');
          setIsOpen(true);
          // setSuccess('メールアドレスを変更しました');
          sendEmail();
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/weak-password':
              setIsOpen(true);
              setError('既に登録されているメールアドレスです');
              break;
            case 'auth/requires-recent-login':
              setIsOpen(true);
              setError('一度ログアウトして再度お試しください');
              break;
            default:
              setIsOpen(true);
              setError('エラー');
              break;
          }
          console.log('error', error);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
    setError('');
    setSuccess('');
    setInfo('');
  };

  const handleCancel = () => {
    setIsActive(false);
    setNewEmail(email);
  };

  // console.log('currentUser', currentUser);

  return (
    <Box component='section'>
      <Box sx={{ mt: 7, mb: 2 }}>
        <Typography component='h1' variant='h6' sx={{ fontWeight: 'bold' }}>
          メールアドレス
        </Typography>
        {/* <Typography></Typography> */}
      </Box>
      {error && (
        <SnackbarAlert severity='error' isOpen={isOpen} handleClose={() => setIsOpen(false)}>
          {error}
        </SnackbarAlert>
      )}
      {success && (
        <SnackbarAlert severity='success' isOpen={isOpen} handleClose={() => setIsOpen(false)}>
          {success}
        </SnackbarAlert>
      )}
      {info && (
        <SnackbarAlert severity='info' isOpen={isOpen} handleClose={() => setIsOpen(false)}>
          {info}
        </SnackbarAlert>
      )}
      <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          {isActive ? (
            <TextField
              variant='outlined'
              label='メールアドレス'
              type='email'
              value={newEmail}
              onChange={handleEmailChange}
              required
              fullWidth
              placeholder='変更する場合は、新しいメールアドレスを入力してください'
            />
          ) : (
            <Typography>{email}</Typography>
          )}
        </Box>
        {isActive ? (
          <>
            <Button variant='outlined' size='large' sx={{ whiteSpace: 'nowrap' }} onClick={() => handleCancel()}>
              キャンセル
            </Button>
            <Button variant='contained' size='large' sx={{ whiteSpace: 'nowrap' }} onClick={() => handleSubmit()}>
              保存
            </Button>
          </>
        ) : (
          <Button variant='contained' size='large' sx={{ whiteSpace: 'nowrap' }} onClick={() => setIsActive(true)}>
            編集
          </Button>
        )}
      </Stack>
      {emailVerified ? (
        <Box sx={{ mt: 1 }}>このメールアドレスは確認済みです。</Box>
      ) : (
        <Box sx={{ mt: 1 }}>このメールアドレスは確認が完了していません。</Box>
      )}
    </Box>
  );
};
