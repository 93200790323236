import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getImages = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; productId: string }
>('product/getImages', async ({ uid, productId }) => {
  let res: firebase.firestore.DocumentData = [];

  const docsRef = db
    .collection('users')
    .doc(uid)
    .collection('products')
    .doc(productId)
    .collection('images')
    .orderBy('order');

  await docsRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // フィールドを取得してtimestampをエポック秒に変換
      const data = {
        id: doc.id,
        order: doc.data().order,
        originalFileName: doc.data().originalFileName,
        originalFileSize: doc.data().originalFileSize,
        thumb1920url: doc.data().thumb1920url,
        thumb960url: doc.data().thumb960url,
        thumb480url: doc.data().thumb480url,
        square1920url: doc.data().square1920url,
        square960url: doc.data().square960url,
        square480url: doc.data().square480url,
        createdAt: doc.data().createdAt?.toMillis(),
      };
      res.push(data);
    });
  });

  // console.log('res', res);
  return { res: res };
});
