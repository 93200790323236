import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateImageFormat = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; checked: boolean }
  // { uid: string | undefined; imageFormat: 'webp' | 'jpeg' }
>('user/updateImageFormat', async ({ uid, checked }) => {
  let imageFormat: 'webp' | 'jpeg' = 'jpeg';
  if (checked) {
    imageFormat = 'webp';
  } else {
    imageFormat = 'jpeg';
  }

  const docRef = db.collection('users').doc(uid);
  await docRef
    .update({
      imageFormat: imageFormat,
    })
    .then(() => {
      console.log('Document successfully updated!');
    })
    .catch((error: any) => {
      console.error('Error updating document: ', error);
    });

  return { imageFormat: imageFormat };
});
