import { FC, useState, ChangeEvent, useEffect, FormEvent } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useCheckProvider } from 'Auth/useCheckProvider';
import { Link, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { auth } from 'app/firebase';
import * as firebaseui from 'firebaseui-ja';
import 'firebaseui-ja/dist/firebaseui.css';
import { useDispatch } from 'react-redux';
import { setType } from 'components/redux/slice';
import { setLightmode } from 'User/redux/slice';
import { useError } from 'components/Header/AccountDialog/useError';
import { Container, Paper, Typography, TextField, Button, Box, Snackbar, Divider, Alert } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const SignIn: FC = () => {
  const { signInWithEmailAndPassword } = useAuth();
  const [alert, setAlert] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string>('');
  const [isEmailVerified, setIsEmailVarified] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const { checkProvider, isProvider } = useCheckProvider({ setFlag });
  const { handleLogout } = useError();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const dispatch = useDispatch();

  dispatch(setType('onBackground'));

  // 一時的にライトモードを強制する
  dispatch(setLightmode());

  // Input
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // clearError();
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  // メールが確認済みかチェック
  const checkEmailVerified = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      // 確認が済んでいない場合は確認メールを送信
      await auth.currentUser.sendEmailVerification();
      setIsOpen(true);
      setAlert('確認用メールを送信しました');
    } else {
      // 確認が済んでいる場合
      setIsEmailVarified(true);
    }
  };

  const displaySentEmail = () => {
    if (auth.currentUser && !isEmailVerified && !isDisplayed) {
      setTimeout(() => {
        setIsDisplayed(true);
      }, 12000);
    }
  };

  const handleIsDisplayed = () => {
    setIsDisplayed(true);
  };

  // メール・パスワードログイン
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || isProvider) {
      return;
    }

    try {
      setAlert('');
      setLoading(true);
      await signInWithEmailAndPassword(email, password);
      // sendEmail();
      // setIsEmailVarified(true);
      history.push('/');
    } catch {
      setIsOpen(true);
      setError('ログインできませんでした');
    }

    setLoading(false);
  };

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  };

  const getProviderButton = () => {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (ui) {
      ui.start('#firebaseui-auth-container', uiConfig);
    } else {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  };

  useEffect(() => {
    // ログイン済みの場合はDashboardへ遷移
    const query = window.location.search;
    // console.log('query', query);
    if (auth.currentUser) {
      if (query) {
        history.push({ pathname: '/', state: { priceId: query } });
      }
    }

    // メールが確認済みかチェック
    checkEmailVerified();

    // Provider認証かチェック
    checkProvider();

    // メール送信済みを表示
    displaySentEmail();

    // Providerボタンを生成
    if (isDisplayed || !auth.currentUser) {
      getProviderButton();
    }

    return () => {};
  }, [checkProvider, isDisplayed]);

  // console.log('isDisplayed', isDisplayed);
  // console.log('auth.currentUser', auth.currentUser);

  return (
    <Container maxWidth='xs'>
      {!auth.isSignInWithEmailLink(window.location.href) ? (
        <Paper
          sx={{
            py: 8,
            px: 1.5,
            textAlign: 'center',
            color: (theme) => theme.palette.text.secondary,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <LockOpenIcon
            sx={{
              mb: 1,
              backgroundColor: (theme) => theme.palette.secondary.main,
              borderRadius: '50%',
              p: 1,
              width: '48px',
              height: '48px',
              color: 'white',
            }}
          />
          <Typography component='h1' variant='h4'>
            Cloud PIX
          </Typography>
          {alert && (
            <Snackbar
              open={isOpen}
              autoHideDuration={5000}
              onClose={() => setIsOpen(false)}
              sx={{ top: 'auto', bottom: 'auto' }}
            >
              <Alert severity='success' sx={{ m: 2 }}>
                {alert}
              </Alert>
            </Snackbar>
          )}
          {error && (
            <Snackbar
              open={isOpen}
              autoHideDuration={5000}
              onClose={() => setIsOpen(false)}
              sx={{ top: 'auto', bottom: 'auto' }}
            >
              <Alert severity='error' sx={{ m: 2 }}>
                {error}
              </Alert>
            </Snackbar>
          )}
          {/* {(!auth.currentUser || (auth.currentUser && isProvider) || isDisplayed) && (
                            <> */}
          {isDisplayed || isEmailVerified || !auth.currentUser ? (
            <>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    m: 4,
                    '&.MuiOutlinedInput-input:-webkit-autofill': {
                      boxShadow: 'none',
                    },
                  }}
                >
                  <TextField
                    label='メールアドレス'
                    variant='outlined'
                    type='email'
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    label='パスワード'
                    variant='outlined'
                    type='password'
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Button
                    disabled={loading}
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    size='large'
                    sx={{ mt: 3 }}
                  >
                    ログイン
                  </Button>
                </Box>
              </form>
              <Box component='p' sx={{ mt: 3 }}>
                <Link to='/forgot-password'>パスワードを忘れた方</Link>
              </Box>
              <Box component='p' sx={{ mt: 2 }}>
                <Link to='/signup'>新規アカウントを作成</Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '80%',
                  my: 4,
                  mx: 'auto',
                  fontSize: (theme) => theme.typography.caption.fontSize,
                  '&::before, &::after': {
                    content: '""',
                    height: '1px',
                    backgroundColor: (theme) => theme.palette.grey[500],
                    flexGrow: 1,
                    m: 1,
                  },
                }}
              >
                または
              </Box>
              <Box
                id='firebaseui-auth-container'
                sx={{
                  mt: 3,
                  '& button': {
                    maxWidth: '100%',
                    border: '1px solid grey',
                    boxShadow: 'none',
                    py: 1.5,
                    px: 2,
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
              ></Box>
            </>
          ) : (
            <>
              <Box sx={{ mt: 4, px: 3 }}>
                <Typography variant='h6'>メールの確認</Typography>
                <Box component='p' sx={{ textAlign: 'justify' }}>
                  メールアドレスがご本人のものであることを確認するため、{email}{' '}
                  にメールを送信しました。メール本文に記載されたURLをクリックして登録を完了してください。
                </Box>
                <Button
                  disabled={loading}
                  type='submit'
                  variant='outlined'
                  color='primary'
                  // size='large'
                  // onClick={sendEmail}
                  onClick={checkEmailVerified}
                >
                  再送信する
                </Button>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Button
                disabled={loading}
                type='submit'
                variant='contained'
                color='primary'
                // size='large'
                onClick={handleIsDisplayed}
              >
                ログインフォームへ
              </Button>
            </>
          )}
        </Paper>
      ) : (
        <Box id='firebaseui-auth-container'></Box>
      )}
    </Container>
  );
};
