import { createSelector } from 'reselect';
import { RootState } from 'store';
import { UserState } from 'User/redux/slice';

export const userSelector: (state: RootState) => UserState = (state: RootState) => state.user;

export const subscriptionsSelector = createSelector(userSelector, (user) => {
  return user.entities.subscriptions;
});

export const modeSelector = createSelector(userSelector, (user) => {
  return user.entities.mode;
});

export const imageFormatSelector = createSelector(userSelector, (user) => {
  return user.entities.imageFormat;
});

export const imageCountSelector = createSelector(userSelector, (user) => {
  return user.entities.imageCount;
});
