import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getName = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; productId: string }
>('product/getName', async ({ uid, productId }) => {
  let res: firebase.firestore.DocumentData | undefined;

  const docRef = db.collection('users').doc(uid).collection('products').doc(productId).collection('name').doc('doc');

  await docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        res = doc.data();
      } else {
        console.log('No such document!');
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });

  return { res: res };
});
