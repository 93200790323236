import { FC, useEffect, useState, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProduct } from 'Product/firebase/getProduct';
import { getName } from 'Product/firebase/getName';
import { getImages } from 'Product/firebase/getImages';
import { getOptions } from 'Product/firebase/getOptions';
import { productEntitiesSelector, colorSelector, imagesSelector } from 'Product/redux/selector';
import { isUserAuthenticatedSelector } from 'Auth/redux/selector';
import { subscriptionsSelector } from 'User/redux/selector';
import { uidSelector } from 'Auth/redux/selector';
import { setPage } from 'components/redux/slice';
import { useParams } from 'react-router-dom';
import { Images } from 'Product/Images';
import { Settings } from 'Product/Settings';
import { Preview } from 'Product/Preview';
import { SourceCode } from 'Product/SourceCode';
import { Tab, Tooltip } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import CollectionsIcon from '@mui/icons-material/Collections';
// import FilterIcon from '@mui/icons-material/Filter';
// import ImageIcon from '@mui/icons-material/Image';
import CreateIcon from '@mui/icons-material/Create';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import CodeIcon from '@mui/icons-material/Code';

export const Product: FC = () => {
  const uid = useSelector(uidSelector);
  const authenticated = useSelector(isUserAuthenticatedSelector);
  const subscriptions = useSelector(subscriptionsSelector);
  const product = useSelector(productEntitiesSelector);
  const images = useSelector(imagesSelector);
  const [tabValue, setTabValue] = useState('images');
  const [selectedVender, setSelectedVender] = useState('ebay');
  const [selectedCategory, setSelectedCategory] = useState('');
  const { productId } = useParams<{ productId: string }>();
  const selectedColor = useSelector(colorSelector);
  const [colorTheme, setColorTheme] = useState<{ text: string; background: string }>();
  const dispatch = useDispatch();

  // レイアウト用
  useEffect(() => {
    dispatch(setPage('product'));
    return () => {
      dispatch(setPage('product'));
    };
  }, [dispatch]);

  // タブ切り替え
  const handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  // productを取得
  useEffect(() => {
    let subscriptionStatus = '';
    if (subscriptions && subscriptions[0]) {
      subscriptionStatus = subscriptions[0].status;
    }
    if (authenticated && uid && (subscriptionStatus === 'active' || subscriptionStatus === 'trialing')) {
      dispatch(getProduct({ uid, productId }));
    }
    return () => {
      dispatch(getProduct({ uid, productId }));
    };
  }, [authenticated, dispatch, uid, productId, subscriptions]);

  // productに値がセットされたら実行
  useEffect(() => {
    // // 商品のカテゴリーを絞り込む
    if (product.categoryTertiary && product.categoryTertiary !== 'n/a') {
      setSelectedCategory(product.categoryTertiary);
    } else if (product.categorySecondary && product.categorySecondary !== 'n/a') {
      setSelectedCategory(product.categorySecondary);
    } else if (product.categoryPrimary && product.categoryPrimary !== 'n/a') {
      setSelectedCategory(product.categoryPrimary);
    }
  }, [dispatch, uid, product]);

  // 商品名を取得
  useEffect(() => {
    dispatch(
      getName({
        uid: uid,
        productId: productId,
      })
    );
    return () => {
      dispatch(
        getName({
          uid: uid,
          productId: productId,
        })
      );
    };
  }, [dispatch, productId, uid]);

  // 画像を取得
  useEffect(() => {
    dispatch(
      getImages({
        uid: uid,
        productId: productId,
      })
    );
    return () => {
      dispatch(
        getImages({
          uid: uid,
          productId: productId,
        })
      );
    };
  }, [dispatch, productId, uid]);

  // selectedCategoryに値がセットされたら実行
  useEffect(() => {
    // 商品のoptionsを取得
    if (selectedCategory) {
      dispatch(
        getOptions({
          uid: uid,
          productId: productId,
          selectedVender: selectedVender,
          section: 'categories',
          subSection: 'grades',
          selectedCategory: selectedCategory,
        })
      );
      dispatch(
        getOptions({
          uid: uid,
          productId: productId,
          selectedVender: selectedVender,
          section: 'categories',
          subSection: 'appearances',
          selectedCategory: selectedCategory,
        })
      );
      dispatch(
        getOptions({
          uid: uid,
          productId: productId,
          selectedVender: selectedVender,
          section: 'payment',
          subSection: '',
          selectedCategory: selectedCategory,
        })
      );
    }
    return () => {
      if (selectedCategory) {
        dispatch(
          getOptions({
            uid: uid,
            productId: productId,
            selectedVender: selectedVender,
            section: 'categories',
            subSection: 'grades',
            selectedCategory: selectedCategory,
          })
        );
        dispatch(
          getOptions({
            uid: uid,
            productId: productId,
            selectedVender: selectedVender,
            section: 'categories',
            subSection: 'appearances',
            selectedCategory: selectedCategory,
          })
        );
        dispatch(
          getOptions({
            uid: uid,
            productId: productId,
            selectedVender: selectedVender,
            section: 'payment',
            subSection: '',
            selectedCategory: selectedCategory,
          })
        );
      }
    };
  }, [dispatch, uid, selectedCategory, productId, selectedVender]);

  // カラーをセット
  useEffect(() => {
    switch (selectedColor) {
      case '888888':
        setColorTheme({
          text: '#373737',
          background: '#888888',
        });
        break;
      case 'A5977A':
        setColorTheme({
          text: '#44382F',
          background: '#A5977A',
        });
        break;
      case '1EC0B8':
        setColorTheme({
          text: '#4F4F7D',
          background: '#1EC0B8',
        });
        break;
      case 'BAB509':
        setColorTheme({
          text: '#4C473F',
          background: '#BAB509',
        });
        break;
      case 'FD5569':
        setColorTheme({
          text: '#654560',
          background: '#FD5569',
        });
        break;
      case '619B3B':
        setColorTheme({
          text: '#253C22',
          background: '#619B3B',
        });
        break;
      default:
        break;
    }
    return () => {};
  }, [selectedColor]);

  // console.log('product.categoryPrimary', product.categoryPrimary);

  return (
    <TabContext value={tabValue}>
      <TabList onChange={handleTabChange} aria-label='simple tabs example' variant='scrollable' scrollButtons='auto'>
        <Tab icon={<PhotoLibraryIcon />} label='商品画像' value='images' sx={{ px: 3 }} />
        <Tab icon={<CreateIcon />} label='商品情報' value='settings' sx={{ px: 3 }} />
        {images.length && product.categoryPrimary ? (
          <Tab icon={<AutoAwesomeIcon />} label='プレビュー' value='preview' sx={{ px: 3 }} />
        ) : !images.length ? (
          <Tab icon={<AutoAwesomeIcon color='disabled' />} label='プレビュー' value='preview' sx={{ px: 3 }} />
        ) : (
          !product.categoryPrimary && (
            <Tab icon={<AutoAwesomeIcon color='disabled' />} label='プレビュー' value='preview' sx={{ px: 3 }} />
          )
        )}
        {images.length && product.categoryPrimary ? (
          <Tab icon={<CodeIcon />} label='出品用コード' value='sourceCode' sx={{ px: 3 }} />
        ) : !images.length ? (
          <Tab icon={<CodeIcon color='disabled' />} label='出品用コード' value='sourceCode' sx={{ px: 3 }} />
        ) : (
          !product.categoryPrimary && (
            <Tab icon={<CodeIcon color='disabled' />} label='出品用コード' value='sourceCode' sx={{ px: 3 }} />
          )
        )}
      </TabList>
      <TabPanel value='images' sx={{ px: 0 }}>
        <Images selectedVender={selectedVender} />
      </TabPanel>
      <TabPanel value='settings' sx={{ px: 0 }}>
        <Settings
          selectedVender={selectedVender}
          setSelectedVender={setSelectedVender}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </TabPanel>
      <TabPanel value='preview' sx={{ px: 0 }}>
        <Preview colorTheme={colorTheme} />
      </TabPanel>
      <TabPanel value='sourceCode' sx={{ px: 0 }}>
        <SourceCode colorTheme={colorTheme} />
      </TabPanel>
    </TabContext>
  );
};
