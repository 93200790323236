import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setType } from 'components/redux/slice';
import { setLightmode } from 'User/redux/slice';
import { useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { useAuth } from 'contexts/AuthContext';
import { db } from 'app/firebase';
import { Plans } from 'Payment/Plans';
import queryString from 'query-string';
import { Box } from '@mui/material';

type LocationState = {
  priceId?: string;
};

export const Checkout = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const { currentUser } = useAuth();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  dispatch(setType('onBackground'));

  // 一時的にライトモードを強制する
  dispatch(setLightmode());

  const handleClick = async (e: { currentTarget: { id: string } }) => {
    setLoading(true);
    const docRef = await db
      .collection('users')
      .doc(currentUser?.uid)
      .collection('checkout_sessions')
      .add({
        price: e.currentTarget.id,
        // tax_rates: ['txr_1J6EaQLY0SxtMHkvPyOsRk5N'], // Test Mode
        tax_rates: ['txr_1JKU60LY0SxtMHkvWsYyTCWh'],
        success_url: window.location.origin,
        cancel_url: 'http://localhost:3000/account/checkout',
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      // const { error, sessionId }: any = snap.data();
      const error = snap.data()?.error;
      const sessionId = snap.data()?.sessionId;

      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        // console.log('An error occured', error.message);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        stripe?.redirectToCheckout({ sessionId });
      }
    });
    // setLoading(false);
  };

  useEffect(() => {
    // web(Next.js)ページから遷移してきた場合
    if (location.state?.priceId) {
      setLoading(true);
      const query = queryString.parse(location.state.priceId);
      if (query.priceId && !Array.isArray(query.priceId)) {
        handleClick({ currentTarget: { id: query.priceId } });
      }
    }

    // apps(React)内で遷移してきた場合
    setLoading(false);
    // __Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return message ? (
    <Box component='section'>
      <Box component='p'>{message}</Box>
    </Box>
  ) : (
    <Plans handleClick={handleClick} loading={loading} />
  );
};
