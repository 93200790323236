// stripe-firebase-extensions
// POSTINSTALL.md
// List available plans and prices
// https://github.com/stripe/stripe-firebase-extensions/blob/next/firestore-stripe-subscriptions/POSTINSTALL.md
import { FC, useState, useEffect } from 'react';
import { db } from 'app/firebase';
import firebase from 'firebase/app';
import { IntervalSwitch } from 'Payment/IntervalSwitch';
import { Container, Typography, Grid, Paper, Button, CircularProgress, Backdrop, Box, List } from '@mui/material';

type FbDocumentState = firebase.firestore.DocumentData[];
type Props = {
  handleClick: (e: {
    currentTarget: {
      id: string;
    };
  }) => Promise<void>;
  loading: boolean;
};
export const Plans: FC<Props> = ({ handleClick, loading }) => {
  const [plans, setPlans] = useState<FbDocumentState>([]);
  const [isYearly, setIsYearly] = useState(false);

  const handleIsYearlyChange = async () => {
    setIsYearly(!isYearly);
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      const getPlans = async () => {
        await db
          .collection('plans')
          .where('active', '==', true)
          .orderBy('stripe_metadata_order')
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
              // 商品IDとデータを取得
              const fetchData = {
                planId: doc.id,
                ...doc.data(),
              };
              // 商品IDに含まれる価格リスト(SubCollections)を取得して、商品データにmargeしてStateにセット
              let temp: any[] = [];
              await db
                .collection('plans')
                .doc(fetchData.planId)
                .collection('prices')
                .where('active', '==', true)
                .get()
                .then(function (querySnapshot) {
                  querySnapshot.forEach(async function (doc) {
                    const fetchData = {
                      priceId: doc.id,
                      ...doc.data(),
                    };
                    temp.push(fetchData);
                  });
                });
              const data = { ...fetchData, prices: temp };
              setPlans((prevPlans) => [...prevPlans, data]);
            });
          });
      };
      getPlans();
    }
    return () => {
      unmounted = true;
    };
  }, []);

  // console.log('plans', plans);

  return (
    <Box component='section' sx={{ minHeight: `calc(100vh - 258px)`, p: 15 }}>
      <Typography variant='h3' component='h1' sx={{ textAlign: 'center', mb: 5 }}>
        プランを選択
      </Typography>
      {loading && (
        <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Container maxWidth='md' sx={{ textAlign: 'center' }}>
        <IntervalSwitch isYearly={isYearly} handleIsYearlyChange={handleIsYearlyChange} />
        <Grid container spacing={5}>
          {plans?.map((plan: any, index: number) => (
            <Grid key={index} item xs={12} md={6}>
              <Paper sx={{ p: 7, height: '100%' }}>
                <Typography variant='h6' component='h2'>
                  {plan.name}
                </Typography>
                {plan.prices.map((price: any, index: number) => (
                  <Box key={index}>
                    {!isYearly && price.currency === 'jpy' && price.interval === 'month' && (
                      <>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                          <Typography variant='h3' component='h3'>
                            {price.unit_amount.toLocaleString()}
                          </Typography>
                          <Typography component='span' sx={{ mb: 1, ml: 1 }}>
                            円 / 月額
                          </Typography>
                        </Box>
                        <Box component='p'>（税込{(price.unit_amount * 1.1).toLocaleString()}円）</Box>
                        <Button
                          onClick={handleClick}
                          id={price.priceId}
                          variant='contained'
                          color='primary'
                          size='large'
                          fullWidth
                          sx={{ mt: 3 }}
                        >
                          無料でお試し
                        </Button>
                        <List
                          component='ul'
                          sx={{
                            listStyle: 'disc',
                            textAlign: 'left',
                            lineHeight: 2.5,
                            mt: 5,
                            py: 1,
                            pl: 3,
                            pr: 1,
                          }}
                        >
                          <li>14日間の無料トライアル</li>
                          <li>アップロード数に制限なし</li>
                          {plan.role === '01_basic' && (
                            <li>最大保存容量10GBまで（アップロードした元ファイル容量で計算）</li>
                          )}
                          {plan.role === '02_premium' && <li>最大保存容量無制限</li>}
                          <li>商品機能</li>
                          <li>IMGタグコードを自動生成（商品でまとめて/画像単体）</li>
                          <li>ALT埋め込み</li>
                          <li>画像URLを出力</li>
                          <li>最適なサイズ（3種類の解像度）に自動変換</li>
                          <li>Jpeg/Webp切り替え</li>
                          <li>元の縦横比</li>
                          <li>正方形に切り出し</li>
                        </List>
                      </>
                    )}
                    {isYearly && price.currency === 'jpy' && price.interval === 'year' && (
                      <>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                          <Typography variant='h3' component='h3'>
                            {price.unit_amount.toLocaleString()}
                          </Typography>
                          <Typography component='span' sx={{ mb: 1, ml: 1 }}>
                            円 / 年額
                          </Typography>
                        </Box>
                        <Box component='p'>（税込{(price.unit_amount * 1.1).toLocaleString()}円）</Box>
                        <Typography
                          sx={{
                            borderBottom: '6px solid yellow',
                            width: 'fit-content',
                            mt: 0,
                            mx: 'auto',
                            mb: 3,
                            fontWeight: 'bold',
                          }}
                        >
                          ２ヶ月分お得！
                        </Typography>
                        <Button
                          onClick={handleClick}
                          id={price.priceId}
                          variant='contained'
                          color='primary'
                          size='large'
                          fullWidth
                          sx={{ mt: 3 }}
                        >
                          無料でお試し
                        </Button>
                        <List
                          component='ul'
                          sx={{
                            listStyle: 'disc',
                            textAlign: 'left',
                            lineHeight: 2.5,
                            mt: 5,
                            py: 1,
                            pl: 3,
                            pr: 1,
                          }}
                        >
                          <li>14日間の無料トライアル</li>
                          <li>アップロード数に制限なし</li>
                          {plan.role === '01_basic' && (
                            <li>最大保存容量10GBまで（アップロードした元ファイル容量で計算）</li>
                          )}
                          {plan.role === '02_premium' && <li>最大保存容量無制限</li>}
                          <li>商品機能</li>
                          <li>IMGタグコードを自動生成（商品でまとめて/画像単体）</li>
                          <li>ALT埋め込み</li>
                          <li>画像URLを出力</li>
                          <li>最適なサイズ（3種類の解像度）に自動変換</li>
                          <li>Jpeg/Webp切り替え</li>
                          <li>元の縦横比</li>
                          <li>正方形に切り出し</li>
                        </List>
                      </>
                    )}
                  </Box>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
