import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getPresetCategories = createAsyncThunk<
  firebase.firestore.DocumentData,
  { vender: 'ebay' | 'yahooAuction' }
>('cotegories/getPresetCategories', async ({ vender }) => {
  let res: firebase.firestore.DocumentData = [];

  const docsRef = db.collection('templates').doc(vender).collection('categories');

  await docsRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      res.push({ id: doc.id, ...doc.data() });
    });
  });

  return { res: res };
});
