import { FC, useState, FormEvent, ChangeEvent } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { SnackbarAlert } from 'components/SnackbarAlert';
import { Typography, Stack, Box, Button, TextField } from '@mui/material';

export const Password: FC = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passworConfirmd, setPassworConfirmd] = useState<string>('');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePassworConfirmdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassworConfirmd(e.target.value);
  };

  const updatePassword = (password: string) => {
    if (currentUser) {
      return currentUser.updatePassword(password);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (currentUser) {
      e.preventDefault();

      if (password !== passworConfirmd) {
        setIsOpen(true);
        return setError('パスワードが一致しません');
      }

      const promises = [];
      setLoading(true);
      setError('');

      // if (email && email !== currentUser.email) {
      //     promises.push(updateEmail(email));
      // }
      if (password) {
        promises.push(updatePassword(password));
      }

      Promise.all(promises)
        .then(() => {
          // history.push('/');
          setIsOpen(true);
          setSuccess('パスワードを変更しました');
        })
        .catch((error) => {
          if (error.code === 'auth/weak-password') {
            setIsOpen(true);
            setError('パスワードは6文字以上にしてください');
          } else {
            setIsOpen(true);
            setError('エラー');
          }
          console.log('error', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box component='section'>
      <Box sx={{ mt: 7, mb: 2 }}>
        <Typography component='h1' variant='h6' sx={{ fontWeight: 'bold' }}>
          パスワード
        </Typography>
        {/* <Typography></Typography> */}
      </Box>
      {error && (
        <SnackbarAlert severity='error' isOpen={isOpen} handleClose={() => setIsOpen(false)}>
          {error}
        </SnackbarAlert>
      )}
      {success && (
        <SnackbarAlert severity='success' isOpen={isOpen} handleClose={() => setIsOpen(false)}>
          {success}
        </SnackbarAlert>
      )}
      <Box>
        <form onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            label='パスワード'
            type='password'
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            // placeholder='変更しない場合は、空欄のままにしてください'
            sx={{ mb: 3 }}
          />
          <TextField
            variant='outlined'
            label='パスワード（確認用）'
            type='password'
            value={passworConfirmd}
            onChange={handlePassworConfirmdChange}
            fullWidth
            // placeholder='変更しない場合は、空欄のままにしてください'
            sx={{ mb: 3 }}
          />
          <Box>
            <Button disabled={loading} type='submit' variant='contained' color='primary' size='large'>
              保存
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
