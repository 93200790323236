import { FC } from 'react';
import { Typography, Grid, Switch, Box } from '@mui/material';

type Props = {
  isYearly: boolean;
  handleIsYearlyChange: () => Promise<void>;
};
export const IntervalSwitch: FC<Props> = ({ isYearly, handleIsYearlyChange }) => {
  return (
    <Box component='section' sx={{ mb: 6 }}>
      <Grid container justifyContent='center' alignItems='center' spacing={1}>
        <Grid item component='label'>
          <Typography>月払い</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={isYearly}
            onChange={handleIsYearlyChange}
            color='default'
            inputProps={{ 'aria-label': 'change interval' }}
          />
        </Grid>
        <Grid item component='label'>
          <Typography>年払い</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
