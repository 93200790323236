import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import firebase from 'firebase';

export interface AuthState {
  uid?: string;
  displayName?: string | null;
  email?: string | null;
  emailVerified?: boolean;
  photoURL?: string | null;
  providerData?: {
    uid: string;
    displayName: string;
    photoURL: string;
    email: string;
    phoneNumber: string | null;
    providerId: string;
  }[];
  authenticated?: boolean;
  error?: SerializedError;
}

const initialState: AuthState = {
  uid: undefined,
  displayName: undefined,
  email: undefined,
  emailVerified: false,
  photoURL: undefined,
  providerData: undefined,
  authenticated: undefined,
  error: undefined,
};

interface PayLoad {
  uid?: string;
  displayName?: string;
  email?: string;
  emailVerified?: boolean;
  photoURL?: string;
  providerData?: {
    uid: string;
    displayName: string;
    photoURL: string;
    email: string;
    phoneNumber: string | null;
    providerId: string;
  }[];
}

const provider = new firebase.auth.GoogleAuthProvider();

export const login = createAsyncThunk<AuthState, PayLoad>('login', async (req, thunkAPI) => {
  try {
    if (req.displayName === undefined) {
      const response = await firebase.auth().signInWithPopup(provider);
      const uid = response.user?.uid;
      const displayName = response.user?.displayName;
      const email = response.user?.email;
      const emailVerified = response.user?.emailVerified;
      const photoURL = response.user?.photoURL;
      const providerData = response.user?.providerData;
      return { uid, displayName, email, emailVerified, photoURL, providerData } as PayLoad;
    } else {
      const uid = req.uid;
      const displayName = req.displayName;
      const email = req.email;
      const emailVerified = req.emailVerified;
      const photoURL = req.photoURL;
      const providerData = req.providerData;
      return { uid, displayName, email, emailVerified, photoURL, providerData } as PayLoad;
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const logout = createAsyncThunk('logout', async (_, thunkAPI) => {
  try {
    await firebase.auth().signOut();
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.uid = action.payload.uid;
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
      state.emailVerified = action.payload.emailVerified;
      state.photoURL = action.payload.photoURL;
      state.providerData = action.payload.providerData;
      state.authenticated = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.authenticated = false;
      state.uid = initialState.uid;
      state.displayName = initialState.displayName;
      state.email = initialState.email;
      state.emailVerified = initialState.emailVerified;
      state.photoURL = initialState.photoURL;
      state.providerData = initialState.providerData;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});
