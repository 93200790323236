import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const addCategory = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    preparedCategories: firebase.firestore.DocumentData[];
    selectedVender: string;
    ja: string;
    level: 'primary' | 'secondary' | 'tertiary';
    targetCategory: any;
    secondaryChildren?: any;
    tertiaryChildren?: any;
    primaryParent?: any;
    secondaryParent?: any;
  }
>(
  'templates/addCategory',
  async ({
    uid,
    preparedCategories,
    selectedVender,
    ja,
    level,
    targetCategory,
    secondaryChildren,
    tertiaryChildren,
    primaryParent,
    secondaryParent,
  }) => {
    // 有効化（初回）：設定画面/商品カテゴリーで最初にチェックを入れた時のみプリセットからコピーして追加する。
    // 無効化：チェックを外した場合は、status:disable を付ける
    // 有効化：次回以降再チェックした場合は、status:enable を付ける
    // 理由：docを消去して無効化する設計だと、ユーザーがカスタマイズした状態がチェックのON/OFFで消去されてしまうため

    const docsRef = db
      .collection('users')
      .doc(uid)
      .collection('templates')
      .doc(selectedVender)
      .collection('categories');

    // 既にプリセットからコピー済みの場合は何もしない
    // 未登録の場合は追加する
    if (preparedCategories.some((e) => e.id === targetCategory.id)) {
    } else {
      switch (level) {
        case 'primary':
          await docsRef
            .doc(targetCategory.id)
            .set({
              id: targetCategory.id,
              status: 'enable',
              ja: targetCategory.ja,
              level: targetCategory.level,
            })
            .then(() => {})
            .catch((error) => {
              console.error('Error adding document: ', error);
            });
          break;
        case 'secondary':
          await docsRef
            .doc(targetCategory.id)
            .set({
              id: targetCategory.id,
              status: 'enable',
              ja: targetCategory.ja,
              level: targetCategory.level,
              primary: targetCategory.primary,
            })
            .then(() => {})
            .catch((error) => {
              console.error('Error adding document: ', error);
            });
          break;
        case 'tertiary':
          await docsRef
            .doc(targetCategory.id)
            .set({
              id: targetCategory.id,
              status: 'enable',
              ja: targetCategory.ja,
              level: targetCategory.level,
              primary: targetCategory.primary,
              secondary: targetCategory.secondary,
            })
            .then(() => {})
            .catch((error) => {
              console.error('Error adding document: ', error);
            });
          break;
        default:
          break;
      }
    }

    // 子を追加する
    if (secondaryChildren) {
      await Promise.all(
        secondaryChildren?.map(async (secondaryChild: any) => {
          // 既にプリセットからコピー済みの場合は何もしない
          // 未登録の場合は追加する
          if (preparedCategories.some((e) => e.id === secondaryChild.id)) {
          } else {
            await docsRef
              .doc(secondaryChild.id)
              .set({
                status: 'enable',
                ja: secondaryChild.ja,
                level: secondaryChild.level,
                primary: secondaryChild.primary,
              })
              .then(() => {})
              .catch((error) => {
                console.error('Error adding document: ', error);
              });
          }
        })
      );
    }
    if (tertiaryChildren) {
      await Promise.all(
        tertiaryChildren?.map(async (tertiaryChild: any) => {
          // 既にプリセットからコピー済みの場合は何もしない
          // 未登録の場合は追加する
          if (preparedCategories.some((e) => e.id === tertiaryChild.id)) {
          } else {
            await docsRef
              .doc(tertiaryChild.id)
              .set({
                status: 'enable',
                ja: tertiaryChild.ja,
                level: tertiaryChild.level,
                primary: tertiaryChild.primary,
                secondary: tertiaryChild.secondary,
              })
              .then(() => {})
              .catch((error) => {
                console.error('Error adding document: ', error);
              });
          }
        })
      );
    }

    // 親を追加する
    if (primaryParent) {
      // 既にプリセットからコピー済みの場合は何もしない
      // 未登録の場合は追加する
      if (preparedCategories.some((e) => e.id === primaryParent.id)) {
      } else {
        await docsRef
          .doc(primaryParent.id)
          .set({
            status: 'enable',
            ja: primaryParent.ja,
            level: primaryParent.level,
          })
          .then(() => {})
          .catch((error) => {
            console.error('Error adding document: ', error);
          });
      }
    }
    if (secondaryParent) {
      // 既にプリセットからコピー済みの場合は何もしない
      // 未登録の場合は追加する
      if (preparedCategories.some((e) => e.id === secondaryParent.id)) {
      } else {
        await docsRef
          .doc(secondaryParent.id)
          .set({
            status: 'enable',
            ja: secondaryParent.ja,
            level: secondaryParent.level,
            primary: secondaryParent.primary,
          })
          .then(() => {})
          .catch((error) => {
            console.error('Error adding document: ', error);
          });
      }
    }

    return {
      selectedVender: selectedVender,
      targetCategory: targetCategory,
      ja: ja,
      level: level,
      secondaryChildren: secondaryChildren,
      tertiaryChildren: tertiaryChildren,
      primaryParent: primaryParent,
      secondaryParent: secondaryParent,
    };
  }
);
