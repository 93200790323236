import { FC } from 'react';
import { Box } from '@mui/material';

type Props = {
  type?: string;
};

export const Main: FC<Props> = ({ type, children }) => {
  return (
    <>
      {type === 'campaign' ? (
        <Box component='main' maxWidth='lg'>
          {children}
        </Box>
      ) : (
        <Box component='main'>
          <Box maxWidth='lg' sx={{ py: 7, px: 5 }}>
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};
