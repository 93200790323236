import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { uidSelector } from 'Auth/redux/selector';
import { getVenders } from 'Settings/Templates/firebase/getVenders';
import { ImageFormat } from 'Settings/Templates/ImageFormat';
import { Options } from 'Settings/Templates/Options';
import { SelectTemplate } from 'Settings/Templates/SelectTemplate';
import { SelectCategory } from 'Settings/Templates/SelectCategory';
import { Typography, Paper, Stack, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const Templates: FC = () => {
  const uid = useSelector(uidSelector);
  const [selectedVender, setSelectedVender] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedPrimaryId, setSelectedPrimaryId] = useState('');
  const [selectedSecondaryId, setSelectedSecondaryId] = useState('');
  const [selectedTertiaryId, setSelectedTertiaryId] = useState('');
  const dispatch = useDispatch();

  // vendersを取得
  useEffect(() => {
    dispatch(getVenders({ uid }));
    return () => {
      dispatch(getVenders({ uid }));
    };
  }, [dispatch, uid]);

  // アクティブなカテゴリーをセット
  useEffect(() => {
    if (selectedTertiaryId) {
      setSelectedCategoryId(selectedTertiaryId);
    } else if (selectedSecondaryId) {
      setSelectedCategoryId(selectedSecondaryId);
    } else if (selectedPrimaryId) {
      setSelectedCategoryId(selectedPrimaryId);
    }
  }, [selectedPrimaryId, selectedSecondaryId, selectedTertiaryId]);

  // console.log('selectedCategoryId', selectedCategoryId);

  return (
    <Box component='section'>
      <Box sx={{ mt: 7, mb: 7 }}>
        <Typography component='h1' variant='h6' sx={{ mb: 3, fontWeight: 'bold' }}>
          出品テンプレート設定
        </Typography>
        <Typography>ここで保存した内容は、商品登録画面の選択肢に反映されます。</Typography>
      </Box>
      <ImageFormat />
      <SelectTemplate
        selectedVender={selectedVender}
        setSelectedVender={setSelectedVender}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
      {selectedSection === 'description' && (
        <SelectCategory
          selectedVender={selectedVender}
          selectedPrimaryId={selectedPrimaryId}
          setSelectedPrimaryId={setSelectedPrimaryId}
          selectedSecondaryId={selectedSecondaryId}
          setSelectedSecondaryId={setSelectedSecondaryId}
          selectedTertiaryId={selectedTertiaryId}
          setSelectedTertiaryId={setSelectedTertiaryId}
        />
      )}
      {selectedVender === 'ebay' && (
        <>
          <Paper component='aside' sx={{ mb: 7, padding: 3 }}>
            <Stack direction='row' spacing={2}>
              <InfoIcon />
              <Typography variant='body2'>
                ebay用テンプレートは、最終的に英語表記で出力されます。各商品の編集は日本語で行いますので、日本語/英語どちらも設定してください。
              </Typography>
            </Stack>
          </Paper>
          {selectedSection === 'description' && (
            <>
              <Options
                selectedVender={selectedVender}
                selectedCategoryId={selectedCategoryId}
                title='商品のグレード'
                section='categories'
                subSection='grades'
              />
              <Options
                selectedVender={selectedVender}
                selectedCategoryId={selectedCategoryId}
                title='商品の外観'
                section='categories'
                subSection='appearances'
              />
            </>
          )}
          {selectedSection === 'payment' && (
            <Options selectedVender={selectedVender} title='支払い方法' section='payment' subSection='' />
          )}
          {selectedSection === 'shipping' && <>発送について</>}
          {selectedSection === 'return' && <>返品について</>}
          {selectedSection === 'notes' && <>販売条件・その他</>}
        </>
      )}
    </Box>
  );
};
