import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getActiveCategories = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; selectedVender: string }
>('cotegories/getActiveCategories', async ({ uid, selectedVender }) => {
  let res: firebase.firestore.DocumentData = [];

  const docsRef = db
    .collection('users')
    .doc(uid)
    .collection('templates')
    .doc(selectedVender)
    .collection('categories')
    .where('status', '==', 'enable');

  await docsRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      res.push({ id: doc.id, ...doc.data() });
    });
  });

  return { res: res };
});
