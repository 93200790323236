import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { getProduct } from 'Product/firebase/getProduct';
import { getName } from 'Product/firebase/getName';
import { getImages } from 'Product/firebase/getImages';
import { getOptions } from 'Product/firebase/getOptions';
import { initOptions } from 'Product/firebase/initOptions';
import { updateSelectOptionStatus } from 'Product/firebase/updateSelectOptionStatus';
import { updateCategory } from 'Product/firebase/updateCategory';
import { updateOptionStatus } from 'Product/firebase/updateOptionStatus';
import { updateInputJa } from 'Product/firebase/updateInputJa';
import { updateInputEn } from 'Product/firebase/updateInputEn';
import { updateOrderUp } from 'Product/firebase/updateOrderUp';
import { updateOrderDown } from 'Product/firebase/updateOrderDown';
import { deleteOption } from 'Product/firebase/deleteOption';
import { addOption } from 'Product/firebase/addOption';
// import { deleteProduct } from 'firebase/deleteProduct';
import { deleteAtPath } from 'app/deleteAtPath';
import { addImages } from 'Product/firebase/addImages';
import { updateColor } from 'Product/firebase/updateColor';
// import { setTimeout } from 'timers';

export interface ProductState {
  entities: firebase.firestore.DocumentData;
  // preparedOptions: {
  //   ebay: firebase.firestore.DocumentData[];
  //   yahooAuction: firebase.firestore.DocumentData[];
  // };
  name: firebase.firestore.DocumentData;
  images: firebase.firestore.DocumentData[];
  grades: firebase.firestore.DocumentData[];
  appearances: firebase.firestore.DocumentData[];
  payment: firebase.firestore.DocumentData[];
  color: string | null;
  status: string | null;
}

const initialState: ProductState = {
  entities: {},
  // preparedOptions: { ebay: [], yahooAuction: [] },
  name: {},
  images: [],
  grades: [],
  appearances: [],
  payment: [],
  color: null,
  status: null,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 商品情報を取得
      .addCase(getProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.status = 'success';
        state.entities = action.payload.res;
        state.color = action.payload.color;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 商品名を取得
      .addCase(getName.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getName.fulfilled, (state, action) => {
        state.status = 'success';
        state.name = action.payload.res;
      })
      .addCase(getName.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 画像を取得
      .addCase(getImages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getImages.fulfilled, (state, action) => {
        state.status = 'success';
        state.images = action.payload.res;
      })
      .addCase(getImages.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // SelectのStatusを更新
      .addCase(updateSelectOptionStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSelectOptionStatus.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    // 既存のstatusをdisableに
                    action.payload.disableTargetIds.forEach((disableTargetId: string) => {
                      const toDisable = state.grades.find((v) => v.id === disableTargetId);
                      if (toDisable) {
                        toDisable.status = 'disable';
                      }
                    });
                    // 新しいstatusを追加enableに
                    const toEnable = state.grades.find((v) => v.id === action.payload.currentOptionId);
                    if (toEnable) {
                      toEnable.status = 'enable';
                    }
                    break;
                  default:
                    break;
                }
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            break;
          default:
            break;
        }
      })
      .addCase(updateSelectOptionStatus.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // statusを更新
      .addCase(updateOptionStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOptionStatus.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    const grades = state.grades.find((v) => v.id === action.payload.currentOptionId);
                    if (grades) {
                      grades.status = action.payload.status;
                    }
                    break;
                  case 'appearances':
                    const appearances = state.appearances.find((v) => v.id === action.payload.currentOptionId);
                    if (appearances) {
                      appearances.status = action.payload.status;
                    }
                    break;
                  default:
                    break;
                }
                break;
              case 'payment':
                const payment = state.payment.find((v) => v.id === action.payload.currentOptionId);
                if (payment) {
                  payment.status = action.payload.status;
                }
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            switch (action.payload.section) {
              default:
                break;
            }
            break;
          default:
            break;
        }
      })
      .addCase(updateOptionStatus.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // カテゴリーを追加・更新
      .addCase(updateCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.level) {
          case 'primary':
            state.entities.categoryPrimary = action.payload.selectedId;
            state.entities.categorySecondary = 'n/a';
            state.entities.categoryTertiary = 'n/a';
            break;
          case 'secondary':
            state.entities.categorySecondary = action.payload.selectedId;
            state.entities.categoryTertiary = 'n/a';
            break;
          case 'tertiary':
            state.entities.categoryTertiary = action.payload.selectedId;
            break;
          default:
            break;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 商品のoptionsを取得
      .addCase(getOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.section) {
          case 'categories':
            switch (action.payload.subSection) {
              case 'grades':
                state.grades = action.payload.res;
                break;
              case 'appearances':
                state.appearances = action.payload.res;
                break;
              default:
                break;
            }
            break;
          case 'payment':
            state.payment = action.payload.res;
            break;
          default:
            break;
        }
      })
      .addCase(getOptions.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 日本語をオリジナルに変更
      .addCase(updateInputJa.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateInputJa.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'name':
                state.name.input = action.payload.newInputJa;
                break;
            }
            break;
          case 'yahooAuction':
            break;
          default:
            break;
        }
      })
      .addCase(updateInputJa.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 英語をオリジナルに変更
      .addCase(updateInputEn.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateInputEn.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'name':
                state.name.inputEn = action.payload.newInputEn;
                break;
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    const grades = state.grades.find((v) => v.id === action.payload.currentOptionId);
                    if (grades) {
                      grades.inputEn = action.payload.newInputEn;
                    }
                    break;
                  case 'appearances':
                    const appearances = state.appearances.find((v) => v.id === action.payload.currentOptionId);
                    if (appearances) {
                      appearances.inputEn = action.payload.newInputEn;
                    }
                    break;
                  default:
                    break;
                }
                break;
              case 'payment':
                const payment = state.payment.find((v) => v.id === action.payload.currentOptionId);
                if (payment) {
                  payment.inputEn = action.payload.newInputEn;
                }
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            break;
          default:
            break;
        }
      })
      .addCase(updateInputEn.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 項目の順序を更新（UP）
      .addCase(updateOrderUp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderUp.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'images':
                // 自身のorderをdecrementする
                const curentImages = state.images.find((v) => v.id === action.payload.currentOptionId);
                if (curentImages) {
                  curentImages.order -= 1;
                }
                // orderの値が一つ小さいoptionのorderをincrementする
                const upperImages = state.images.find((v) => v.id === action.payload.upperOptionId);
                if (upperImages) {
                  upperImages.order += 1;
                }
                // order順にソートし直す
                state.images.sort((a: any, b: any) => {
                  return a.order - b.order;
                });
                break;
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    // 自身のorderをdecrementする
                    const curentGrades = state.grades.find((v) => v.id === action.payload.currentOptionId);
                    if (curentGrades) {
                      curentGrades.order -= 1;
                    }
                    // orderの値が一つ小さいoptionのorderをincrementする
                    const upperGrades = state.grades.find((v) => v.id === action.payload.upperOptionId);
                    if (upperGrades) {
                      upperGrades.order += 1;
                    }
                    // order順にソートし直す
                    state.grades.sort((a: any, b: any) => {
                      return a.order - b.order;
                    });
                    break;
                  case 'appearances':
                    // 自身のorderをdecrementする
                    const curentAppearances = state.appearances.find((v) => v.id === action.payload.currentOptionId);
                    if (curentAppearances) {
                      curentAppearances.order -= 1;
                    }
                    // orderの値が一つ小さいoptionのorderをincrementする
                    const upperAppearances = state.appearances.find((v) => v.id === action.payload.upperOptionId);
                    if (upperAppearances) {
                      upperAppearances.order += 1;
                    }
                    // order順にソートし直す
                    state.appearances.sort((a: any, b: any) => {
                      return a.order - b.order;
                    });
                    break;
                  default:
                    break;
                }
                break;
              case 'payment':
                // 自身のorderをdecrementする
                const curentPayment = state.payment.find((v) => v.id === action.payload.currentOptionId);
                if (curentPayment) {
                  curentPayment.order -= 1;
                }
                // orderの値が一つ小さいoptionのorderをincrementする
                const upperPayment = state.payment.find((v) => v.id === action.payload.upperOptionId);
                if (upperPayment) {
                  upperPayment.order += 1;
                }
                // order順にソートし直す
                state.payment.sort((a: any, b: any) => {
                  return a.order - b.order;
                });
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            switch (action.payload.section) {
              default:
                break;
            }
            break;
          default:
            break;
        }
      })
      .addCase(updateOrderUp.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 項目の順序を更新（DOWN）
      .addCase(updateOrderDown.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderDown.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'images':
                // 自身のorderをincrementする
                const curentImages = state.images.find((v) => v.id === action.payload.currentOptionId);
                if (curentImages) {
                  curentImages.order += 1;
                }
                // orderの値が一つ大きいoptionのorderをdecrementする
                const upperImages = state.images.find((v) => v.id === action.payload.lowerOptionId);
                if (upperImages) {
                  upperImages.order -= 1;
                }
                // order順にソートし直す
                state.images.sort((a: any, b: any) => {
                  return a.order - b.order;
                });
                break;
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    // 自身のorderをincrementする
                    const curentGrades = state.grades.find((v) => v.id === action.payload.currentOptionId);
                    if (curentGrades) {
                      curentGrades.order += 1;
                    }
                    // orderの値が一つ大きいoptionのorderをdecrementする
                    const upperGrades = state.grades.find((v) => v.id === action.payload.lowerOptionId);
                    if (upperGrades) {
                      upperGrades.order -= 1;
                    }
                    // order順にソートし直す
                    state.grades.sort((a: any, b: any) => {
                      return a.order - b.order;
                    });
                    break;
                  case 'appearances':
                    // 自身のorderをincrementする
                    const curentAppearances = state.appearances.find((v) => v.id === action.payload.currentOptionId);
                    if (curentAppearances) {
                      curentAppearances.order += 1;
                    }
                    // orderの値が一つ大きいoptionのorderをdecrementする
                    const upperAppearances = state.appearances.find((v) => v.id === action.payload.lowerOptionId);
                    if (upperAppearances) {
                      upperAppearances.order -= 1;
                    }
                    // order順にソートし直す
                    state.appearances.sort((a: any, b: any) => {
                      return a.order - b.order;
                    });
                    break;
                  default:
                    break;
                }
                break;
              case 'payment':
                // 自身のorderをincrementする
                const curentPayment = state.payment.find((v) => v.id === action.payload.currentOptionId);
                if (curentPayment) {
                  curentPayment.order += 1;
                }
                // orderの値が一つ大きいoptionのorderをdecrementする
                const upperPayment = state.payment.find((v) => v.id === action.payload.lowerOptionId);
                if (upperPayment) {
                  upperPayment.order -= 1;
                }
                // order順にソートし直す
                state.payment.sort((a: any, b: any) => {
                  return a.order - b.order;
                });
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            break;
          default:
            break;
        }
      })
      .addCase(updateOrderDown.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 項目を削除
      .addCase(deleteOption.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteOption.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'images':
                // orderが削除するdocより大きいdocIdのorderを-1する
                action.payload.biggerOrderDocIds.forEach((biggerOrderDocId: string) => {
                  const biggerImage = state.images.find((v) => v.id === biggerOrderDocId);
                  if (biggerImage) {
                    biggerImage.order -= 1;
                  }
                });
                // 削除
                state.images = state.images.filter((v: any) => v.id !== action.payload.currentOptionId);
                break;
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    // orderが削除するdocより大きいdocIdのorderを-1する
                    action.payload.biggerOrderDocIds.forEach((biggerOrderDocId: string) => {
                      const biggerGradeOption = state.grades.find((v) => v.id === biggerOrderDocId);
                      if (biggerGradeOption) {
                        biggerGradeOption.order -= 1;
                      }
                    });
                    // 削除
                    state.grades = state.grades.filter((v: any) => v.id !== action.payload.currentOptionId);
                    break;
                  case 'appearances':
                    // orderが削除するdocより大きいdocIdのorderを-1する
                    action.payload.biggerOrderDocIds.forEach((biggerOrderDocId: string) => {
                      const biggerAppearanceOption = state.appearances.find((v) => v.id === biggerOrderDocId);
                      if (biggerAppearanceOption) {
                        biggerAppearanceOption.order -= 1;
                      }
                    });
                    // 削除
                    state.appearances = state.appearances.filter((v: any) => v.id !== action.payload.currentOptionId);
                    break;
                  default:
                    break;
                }
                break;
              case 'payment':
                // orderが削除するdocより大きいdocIdのorderを-1する
                action.payload.biggerOrderDocIds.forEach((biggerOrderDocId: string) => {
                  const biggerPaymentOption = state.payment.find((v) => v.id === biggerOrderDocId);
                  if (biggerPaymentOption) {
                    biggerPaymentOption.order -= 1;
                  }
                });
                // 削除
                state.payment = state.payment.filter((v: any) => v.id !== action.payload.currentOptionId);
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            switch (action.payload.section) {
              default:
                break;
            }
            break;
          default:
            break;
        }
      })
      .addCase(deleteOption.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 項目を追加
      .addCase(addOption.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addOption.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.selectedVender) {
          case 'ebay':
            switch (action.payload.section) {
              case 'categories':
                switch (action.payload.subSection) {
                  case 'grades':
                    state.grades = [
                      ...state.grades,
                      { id: action.payload.id, input: action.payload.input, order: action.payload.order },
                    ];
                    break;
                  case 'appearances':
                    state.appearances = [
                      ...state.appearances,
                      { id: action.payload.id, input: action.payload.input, order: action.payload.order },
                    ];
                    break;
                  default:
                    break;
                }
                break;
              case 'payment':
                state.payment = [
                  ...state.payment,
                  { id: action.payload.id, input: action.payload.input, order: action.payload.order },
                ];
                break;
              default:
                break;
            }
            break;
          case 'yahooAuction':
            break;
          default:
            break;
        }
      })
      .addCase(addOption.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 商品を削除
      .addCase(deleteAtPath.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAtPath.fulfilled, (state, action) => {
        state.status = 'success';
      })
      .addCase(deleteAtPath.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 画像を追加
      .addCase(addImages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addImages.fulfilled, (state, action) => {
        state.status = 'success';
        state.images = [...state.images, ...action.payload.res];
      })
      .addCase(addImages.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 商品のオプションリストに選択したカテゴリーが追加されていなかったら、ユーザーのtemplatesからコピーして追加する
      .addCase(initOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initOptions.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload.res.length > 0) {
          switch (action.payload.section) {
            case 'categories':
              switch (action.payload.subSection) {
                case 'grades':
                  state.grades = action.payload.res;
                  break;
                case 'appearances':
                  state.appearances = action.payload.res;
                  break;
                default:
                  break;
              }
              break;
            case 'payment':
              state.payment = action.payload.res;
              break;
            default:
              break;
          }
        }
      })
      .addCase(initOptions.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // カラーを更新
      .addCase(updateColor.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateColor.fulfilled, (state, action) => {
        state.status = 'success';
        state.color = action.payload.color;
      })
      .addCase(updateColor.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      });

    // .addMatcher(
    //   (action) => action.type.endsWith('/rejected'),
    //   (state, action) => {
    //     state[action.meta.requestId] = 'rejected';
    //   }
    // )
    // .addDefaultCase((state, action) => {
    //   state.otherActions++;
    // });
  },
});

// Later, dispatch the thunk as needed in the app
// dispatch(fetchUserById(123))

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = productSlice.actions;

export default productSlice.reducer;
