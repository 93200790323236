import { FC, Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { uidSelector } from 'Auth/redux/selector';
import { vendersSelector } from 'Settings/Templates/redux/selector';
import { Stack, Box, Typography, FormControl, MenuItem, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
  selectedVender: string;
  setSelectedVender: Dispatch<SetStateAction<string>>;
  selectedSection: string;
  setSelectedSection: Dispatch<SetStateAction<string>>;
};

export const SelectTemplate: FC<Props> = ({
  selectedVender,
  setSelectedVender,
  selectedSection,
  setSelectedSection,
}) => {
  // const uid = useSelector(uidSelector);
  const venders = useSelector(vendersSelector);
  // const dispatch = useDispatch();

  // 出品先select
  const handleVenderChange = async (event: SelectChangeEvent) => {
    // venderをセット
    setSelectedVender(event.target.value);
  };

  // セクションselect
  const handleSectionChange = (event: SelectChangeEvent) => {
    setSelectedSection(event.target.value);
  };

  // console.log('venders', venders);

  return (
    <Box component='section' sx={{ mb: 5 }}>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        テンプレート
      </Typography>

      <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormControl fullWidth>
            <InputLabel id='vender-label'>出品先</InputLabel>
            <Select
              labelId='vender-label'
              id='vender'
              label='出品先'
              value={selectedVender}
              onChange={handleVenderChange}
            >
              {venders.map((vender) => (
                <MenuItem value={vender.id} key={vender.id}>
                  {vender.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {selectedVender && (
          <>
            <ArrowForwardIosIcon fontSize='small' />
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='section-label'>セクション</InputLabel>
                <Select
                  labelId='section-label'
                  id='section'
                  label='セクション'
                  value={selectedSection}
                  onChange={handleSectionChange}
                >
                  <MenuItem value={'description'}>商品説明</MenuItem>
                  <MenuItem value={'payment'}>支払い方法</MenuItem>
                  <MenuItem value={'shipping'}>発送について</MenuItem>
                  <MenuItem value={'return'}>返品について</MenuItem>
                  <MenuItem value={'notes'}>販売条件・その他</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
