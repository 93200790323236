import { storage } from 'app/firebase';

type Props = {
  uid: string | undefined;
  productId: string;
  originalFileName: string;
  thumb480: string;
  thumb960: string;
  thumb1920: string;
  square480: string;
  square960: string;
  square1920: string;
};

export const deleteFilesFromStorage = ({
  uid,
  productId,
  originalFileName,
  thumb480,
  thumb960,
  thumb1920,
  square480,
  square960,
  square1920,
}: Props) => {
  const storageRef = storage.ref();

  const fileNames = [
    originalFileName,
    thumb480 + '.webp',
    thumb960 + '.webp',
    thumb1920 + '.webp',
    square480 + '.webp',
    square960 + '.webp',
    square1920 + '.webp',
    thumb480 + '.jpeg',
    thumb960 + '.jpeg',
    thumb1920 + '.jpeg',
    square480 + '.jpeg',
    square960 + '.jpeg',
    square1920 + '.jpeg',
    'square_' + originalFileName,
  ];

  // TODO: アップロード直後に削除するとファイルが残ることがある

  fileNames.forEach((fileName) => {
    const desertRef = storageRef.child(uid + '/public/' + productId + '/' + originalFileName + '/' + fileName);
    desertRef
      .delete()
      .then(function () {
        console.log('Storage: File deleted successfully');
      })
      .catch(function (error) {
        console.error('Storage: Uh-oh, an error occurred!');
      });
  });
};
