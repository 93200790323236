import { createSelector } from 'reselect';
import { RootState } from 'store';
import { ProductsState } from 'Products/redux/slice';

export const productsSelector: (state: RootState) => ProductsState = (state: RootState) => state.products;

export const productsEntitiesSelector = createSelector(productsSelector, (products) => {
  return products.entities;
});

export const isDocEmptySelector = createSelector(productsSelector, (products) => {
  return products.isDocEmpty;
});

export const lastDocSelector = createSelector(productsSelector, (products) => {
  return products.lastDoc;
});
