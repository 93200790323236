import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getProduct = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; productId: string }
>('product/getProduct', async ({ uid, productId }) => {
  const docRef = db.collection('users').doc(uid).collection('products').doc(productId);
  let res: firebase.firestore.DocumentData | undefined = {};
  let color: firebase.firestore.DocumentData | undefined = {};

  await docRef.get().then(async (doc) => {
    if (doc.exists) {
      // idとフィールドを取得してtimestampをエポック秒に変換
      const data = {
        id: doc.id,
        createdAt: doc.data()?.createdAt.toMillis(),
        categoryPrimary: doc.data()?.categoryPrimary,
        categorySecondary: doc.data()?.categorySecondary,
        categoryTertiary: doc.data()?.categoryTertiary,
      };
      res = { ...data };
      color = doc.data()?.color;
    } else {
      console.log('No such document!');
    }
  });
  // console.log('res', res);
  return { res: res, color: color };
});
