import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateMode = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; newMode: 'light' | 'dark' }
>('user/updateMode', async ({ uid, newMode }) => {
  const docRef = db.collection('users').doc(uid);

  await docRef
    .update({
      mode: newMode,
    })
    .then(() => {
      console.log('Document successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating document: ', error);
    });

  return { newMode: newMode };
});
