import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const addOption = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    selectedCategoryId?: string;
    section: string;
    subSection: string;
    newOption: string;
    optionsLength: number;
  }
>(
  'templates/addOption',
  async ({ uid, selectedVender, selectedCategoryId, section, subSection, newOption, optionsLength }) => {
    let docsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | undefined;
    let newOptionId = '';

    switch (section) {
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection('categories')
          .doc(selectedCategoryId)
          .collection(subSection);
        break;
      case 'payment':
        docsRef = db.collection('users').doc(uid).collection('templates').doc(selectedVender).collection(section);
        break;

      default:
        break;
    }

    if (docsRef) {
      await docsRef
        .add({
          input: newOption,
          order: optionsLength,
        })
        .then((res: { id: string }) => {
          newOptionId = res.id;
        })
        .catch((error: any) => {
          console.error('Error adding document: ', error);
        });
    }

    return {
      section: section,
      subSection: subSection,
      newOptionId: newOptionId,
      newOption: newOption,
      optionsLength: optionsLength,
    };
  }
);
