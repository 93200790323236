import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateOptionOrderDown = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    selectedCategoryId?: string;
    section: string;
    subSection: string;
    optionId: string;
    order: number;
    optionsLength: number;
  }
>(
  'templates/updateOptionOrderDown',
  async ({ uid, selectedVender, selectedCategoryId, section, subSection, optionId, order, optionsLength }) => {
    // 自身のorderが最大値の場合は何もしない
    if (order === optionsLength - 1) {
      return;
    }

    let docsRef;

    switch (section) {
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection('categories')
          .doc(selectedCategoryId)
          .collection(subSection);
        break;
      case 'payment':
        docsRef = db.collection('users').doc(uid).collection('templates').doc(selectedVender).collection(section);
        break;
      default:
        break;
    }

    // 更新前の自身のorderと同じ値のdoc.idを取得する
    let sameOrderDocIds: string[] = [];
    const lowerOrder = (order += 1);
    if (docsRef) {
      await docsRef
        .where('order', '==', lowerOrder)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            sameOrderDocIds.push(doc.id);
          });
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    }

    // 自身のorderをincrementする
    if (docsRef) {
      await docsRef.doc(optionId).update({
        order: firebase.firestore.FieldValue.increment(1),
      });
    }

    // 取得したdoc.idのorderをdecrementする
    const sameOrderDocId = sameOrderDocIds[0];
    if (docsRef) {
      await docsRef.doc(sameOrderDocId).update({
        order: firebase.firestore.FieldValue.increment(-1),
      });
    }

    return { section: section, subSection: subSection, optionId: optionId, sameOrderDocId: sameOrderDocId };
  }
);
