import { storage } from 'app/firebase';

type Props = {
  uid: string | undefined;
  productId: string;
  files: DropZoneFiles[];
};

// DropZone
type DropZoneFiles = File & {
  preview: string;
};

// Storageに画像を追加
export const uploadFilesToStorage = async ({ uid, productId, files }: Props) => {
  await Promise.all(
    files.map(async (file: any) => {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(uid + '/public/' + productId + '/' + file.name + '/' + file.name);
      const uploadTask = fileRef.put(file);

      await uploadTask.on('state_canged', (snapshot: { bytesTransferred: number; totalBytes: number }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (error: any) => {
          console.log('Storage Error', error);
        };
      });
    })
  );
};
