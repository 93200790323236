import { FC, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
// import { Login } from './components/screens/Login/Login';
// import { LandingPage } from './components/screens/LandingPage/LandingPage';
// import { ErrorsPage } from './components/screens/LandingPage/ErrorsPage';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { isUserAuthenticatedSelector } from 'Auth/redux/selector';
import firebase from 'firebase';
import { login, logout } from 'Auth/redux/slice';
// import { Loading } from './components/screens/Login/Loading';
// import { SignIn } from 'Auth/SignIn';
// import { SignUp } from 'Auth/SignUp';

export const Auth: FC = ({ children }) => {
  const authenticated = useSelector(isUserAuthenticatedSelector);
  const dispatch = useDispatch();

  // authSliceをFirebaseの認証情報でリフレッシュする
  const refresh = useCallback(
    async (uid, displayName, email, emailVerified, photoURL, providerData) => {
      const userData = {
        uid,
        displayName,
        email,
        emailVerified,
        photoURL,
        providerData,
      };
      return dispatch(login(userData));
    },
    [dispatch]
  );

  // Firebase認証されているか、authSliceに保存されているかをチェックする
  useEffect(() => {
    const checkAuthenticated = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user && !authenticated) {
          return await refresh(
            user.uid,
            user.displayName,
            user.email,
            user.emailVerified,
            user.photoURL,
            user.providerData
          );
        }
        if (!user && !authenticated) {
          dispatch(logout());
        }
      });
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    };
    checkAuthenticated();
  });

  switch (authenticated) {
    case undefined:
      // return <Loading />;
      return <></>;
    case false:
      return (
        <>
          <Redirect to='/signin' />
          {/* <Route exact path='/signin' component={SignIn} />
          <Redirect to='/signup' />
          <Route exact path='/signup' component={SignUp} /> */}
          {children}
        </>
      );
    case true:
      return <>{children}</>;
    default:
      return <></>;
  }
  // return <>{children}</>;
};
