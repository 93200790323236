import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateInputEn = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedVender: string;
    section: string;
    subSection: string;
    currentOptionId: string;
    newInputEn: string;
    selectedCategory: string;
  }
>(
  'product/updateInputEn',
  async ({ uid, productId, selectedVender, section, subSection, currentOptionId, newInputEn, selectedCategory }) => {
    let docRef;

    switch (section) {
      case 'name':
        docRef = db.collection('users').doc(uid).collection('products').doc(productId).collection('name').doc('doc');
        break;
      case 'categories':
        docRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection)
          .doc(currentOptionId);
        break;
      default:
        docRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(currentOptionId);
        break;
    }

    await docRef
      .update({
        inputEn: newInputEn,
      })
      .then(() => {
        console.log('Document successfully updated!');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });

    return {
      selectedVender: selectedVender,
      section: section,
      subSection: subSection,
      newInputEn: newInputEn,
      currentOptionId: currentOptionId,
    };
  }
);
