import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
// import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';

export const Settings: FC = () => {
  return (
    <Box>
      <List>
        {/* <ListItem  disableGutters sx={{ p: 0 }}>
          <ListItemButton component='a' href='/settings'>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary='ゴミ箱' />
          </ListItemButton>
        </ListItem> */}
        <ListItem disableGutters sx={{ p: 0 }}>
          <ListItemButton component={Link} to='/settings'>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='設定' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};
