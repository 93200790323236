import firebase from 'firebase/app';
import { createSlice } from '@reduxjs/toolkit';
import { getPresetCategories } from 'Settings/Categories/firebase/getPresetCategories';
import { getPreparedCategories } from 'Settings/Categories/firebase/getPreparedCategories';
import { getActiveCategories } from 'Settings/Categories/firebase/getActiveCategories';
import { addCategory } from 'Settings/Categories/firebase/addCategory';
import { updateCategory } from 'Settings/Categories/firebase/updateCategory';

export interface CategoriesState {
  presetCategories: firebase.firestore.DocumentData[];
  preparedCategories: firebase.firestore.DocumentData[];
  activeCategories: firebase.firestore.DocumentData[];
  status: string | null;
}

const initialState: CategoriesState = {
  presetCategories: [],
  preparedCategories: [],
  activeCategories: [],
  status: null,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // プリセットを取得
      .addCase(getPresetCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPresetCategories.fulfilled, (state, action) => {
        state.status = 'success';
        state.presetCategories = action.payload.res;
      })
      .addCase(getPresetCategories.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // categoriesを取得
      .addCase(getPreparedCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPreparedCategories.fulfilled, (state, action) => {
        state.status = 'success';
        state.preparedCategories = action.payload.res;
      })
      .addCase(getPreparedCategories.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // preparedCategoriesを追加
      .addCase(addCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.status = 'success';
        // 既にプリセットからコピー済みの場合は何もしない
        // 未登録の場合は追加する
        if (state.preparedCategories.some((e) => e.id === action.payload.targetCategory.id)) {
          return;
        } else {
          state.preparedCategories = [
            ...state.preparedCategories,
            {
              id: action.payload.targetCategory.id,
              status: 'enable',
              ja: action.payload.targetCategory.ja,
              level: action.payload.targetCategory.level,
              primary: action.payload.targetCategory.primary,
              secondary: action.payload.targetCategory.secondary,
            },
          ];
        }
        // 子を追加する
        if (action.payload.secondaryChildren && action.payload.secondaryChildren.length > 0) {
          action.payload.secondaryChildren.map(async (secondaryChild: any) => {
            // 既にプリセットからコピー済みの場合は何もしない
            // 未登録の場合は追加する
            if (state.preparedCategories.some((e) => e.id === secondaryChild.id)) {
              return;
            } else {
              state.preparedCategories = [
                ...state.preparedCategories,
                {
                  id: secondaryChild.id,
                  status: 'enable',
                  ja: secondaryChild.ja,
                  level: secondaryChild.level,
                  primary: secondaryChild.primary,
                },
              ];
            }
          });
        }
        if (action.payload.tertiaryChildren && action.payload.tertiaryChildren.length > 0) {
          action.payload.tertiaryChildren.map(async (tertiaryChild: any) => {
            // 既にプリセットからコピー済みの場合は何もしない
            // 未登録の場合は追加する
            if (state.preparedCategories.some((e) => e.id === tertiaryChild.id)) {
              return;
            } else {
              state.preparedCategories = [
                ...state.preparedCategories,
                {
                  id: tertiaryChild.id,
                  status: 'enable',
                  ja: tertiaryChild.ja,
                  level: tertiaryChild.level,
                  primary: tertiaryChild.primary,
                },
              ];
            }
          });
        }
        // 親を追加する
        if (action.payload.primaryParent) {
          // 既にプリセットからコピー済みの場合は何もしない
          // 未登録の場合は追加する
          if (state.preparedCategories.some((e) => e.id === action.payload.primaryParent.id)) {
            return;
          } else {
            state.preparedCategories = [
              ...state.preparedCategories,
              {
                id: action.payload.primaryParent.id,
                status: 'enable',
                ja: action.payload.primaryParent.ja,
                level: action.payload.primaryParent.level,
              },
            ];
          }
        }
        if (action.payload.secondaryParent) {
          // 既にプリセットからコピー済みの場合は何もしない
          // 未登録の場合は追加する
          if (state.preparedCategories.some((e) => e.id === action.payload.secondaryParent.id)) {
            return;
          } else {
            state.preparedCategories = [
              ...state.preparedCategories,
              {
                id: action.payload.secondaryParent.id,
                status: 'enable',
                ja: action.payload.secondaryParent.ja,
                level: action.payload.secondaryParent.level,
                primary: action.payload.secondaryParent.primary,
              },
            ];
          }
        }
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // preparedCategoriesをON/OFF
      .addCase(updateCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = 'success';
        const target = state.preparedCategories.find((e) => e.id === action.payload.targetCategory.id);
        const secondaryTarget = state.preparedCategories.find((e) => e.id === action.payload.primaryParentId);
        const tertiaryTarget = state.preparedCategories.find((e) => e.id === action.payload.secondaryParentId);
        switch (action.payload.status) {
          // OFFにする
          case 'enable':
            switch (action.payload.level) {
              case 'primary':
                if (target) {
                  target.status = 'disable';
                }
                // 子をdisableにする
                if (action.payload.secondaryChildren) {
                  action.payload.secondaryChildren.map((secondaryChild: firebase.firestore.DocumentData) => {
                    const target = state.preparedCategories.find((e) => e.id === secondaryChild.id);
                    if (target) {
                      target.status = 'disable';
                    }
                  });
                }
                if (action.payload.tertiaryChildren) {
                  action.payload.tertiaryChildren.map((tertiaryChild: firebase.firestore.DocumentData) => {
                    const target = state.preparedCategories.find((e) => e.id === tertiaryChild.id);
                    if (target) {
                      target.status = 'disable';
                    }
                  });
                }
                break;
              case 'secondary':
                if (target) {
                  target.status = 'disable';
                }
                console.log('action.payload.tertiaryChildren', action.payload.tertiaryChildren);
                // 子をdisableにする
                if (action.payload.tertiaryChildren.length > 0) {
                  action.payload.tertiaryChildren.map((tertiaryChild: firebase.firestore.DocumentData) => {
                    const target = state.preparedCategories.find((e) => e.id === tertiaryChild.id);
                    if (target) {
                      target.status = 'disable';
                    }
                  });
                }
                break;
              case 'tertiary':
                if (target) {
                  target.status = 'disable';
                }
                break;
              default:
                break;
            }
            break;
          // ONにする
          case 'disable':
            switch (action.payload.level) {
              case 'primary':
                if (target) {
                  target.status = 'enable';
                }
                // 子をenableにする
                if (action.payload.secondaryChildren.length > 0) {
                  action.payload.secondaryChildren.map((secondaryChild: firebase.firestore.DocumentData) => {
                    const target = state.preparedCategories.find((e) => e.id === secondaryChild.id);
                    if (target) {
                      target.status = 'enable';
                    }
                  });
                }
                if (action.payload.tertiaryChildren.length > 0) {
                  action.payload.tertiaryChildren.map((tertiaryChild: firebase.firestore.DocumentData) => {
                    const target = state.preparedCategories.find((e) => e.id === tertiaryChild.id);
                    if (target) {
                      target.status = 'enable';
                    }
                  });
                }
                break;
              case 'secondary':
                if (target) {
                  target.status = 'enable';
                }
                // 親をenableにする
                if (secondaryTarget) {
                  secondaryTarget.status = 'enable';
                }
                // 子をenableにする
                if (action.payload.tertiaryChildren.length > 0) {
                  action.payload.tertiaryChildren.map((tertiaryChild: firebase.firestore.DocumentData) => {
                    const target = state.preparedCategories.find((e) => e.id === tertiaryChild.id);
                    if (target) {
                      target.status = 'enable';
                    }
                  });
                }
                break;
              case 'tertiary':
                if (target) {
                  target.status = 'enable';
                }
                // 親をenableにする
                if (secondaryTarget) {
                  secondaryTarget.status = 'enable';
                }
                if (tertiaryTarget) {
                  tertiaryTarget.status = 'enable';
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // アクティブカテゴリーを取得
      .addCase(getActiveCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getActiveCategories.fulfilled, (state, action) => {
        state.status = 'success';
        state.activeCategories = action.payload.res;
      })
      .addCase(getActiveCategories.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      });
  },
});

// Later, dispatch the thunk as needed in the app
// dispatch(fetchUserById(123))

// Action creators are generated for each case reducer function
// export const { setLightmode } = categoriesSlice.actions;
export default categoriesSlice.reducer;
