import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Email } from './Email';
import { Password } from './Password';
import { Plan } from './Plan';
import { Divider, Box } from '@mui/material';

export const Account: FC = () => {
  return (
    <Box component='section'>
      <Email />
      <Password />
      <Divider sx={{ my: 5 }} />
      <Plan />
      <Divider sx={{ my: 5 }} />
      <Box
        sx={{
          '& > a': {
            color: 'inherit',
            opacity: '0.6',
          },
        }}
      >
        <Link to='/settings/account/unsubscribe/'>アカウントを削除</Link>
      </Box>
    </Box>
  );
};
