import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPage } from 'components/redux/slice';
import { Products } from 'Products';
import { Stats } from './Stats';
import { Typography, Box, Grid } from '@mui/material';

export const Dashboard: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('dashboard'));
    return () => {
      dispatch(setPage('dashboard'));
    };
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 10 }}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stats component='count' />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stats component='volume' />
        </Grid> */}
      </Grid>
      <Box component='section'>
        <Typography component='h2' variant='h5' sx={{ lineHeight: 2, mb: 3 }}>
          最新の商品
        </Typography>
        <Products />
      </Box>
    </>
  );
};
