import { FC, useState, useEffect } from 'react';
import { AppDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { setPage } from 'components/redux/slice';
import { Account } from './Account';
import { Categories } from './Categories';
import { Templates } from './Templates';
import { Box, Tabs, Tab } from '@mui/material';

export const Settings: FC = () => {
  const [value, setValue] = useState(0);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('settings'));
    return () => {
      dispatch(setPage('settings'));
    };
  }, [dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='アカウント' id='tab-0' aria-controls='tabpanel-0' sx={{ fontWeight: 'bold' }} />
          <Tab label='出品カテゴリー' id='tab-1' aria-controls='tabpanel-1' sx={{ fontWeight: 'bold' }} />
          <Tab label='出品テンプレート' id='tab-2' aria-controls='tabpanel-2' sx={{ fontWeight: 'bold' }} />
        </Tabs>
      </Box>
      <Box role='tabpanel' hidden={value !== 0} id='tabpanel-0' aria-labelledby='tab-0'>
        {value === 0 && <Account />}
      </Box>
      <Box role='tabpanel' hidden={value !== 1} id='tabpanel-1' aria-labelledby='tab-1'>
        {value === 1 && <Categories />}
      </Box>
      <Box role='tabpanel' hidden={value !== 2} id='tabpanel-2' aria-labelledby='tab-2'>
        {value === 2 && <Templates />}
      </Box>
    </>
  );
};
