import { createSlice } from '@reduxjs/toolkit';
import { updateIsSidebarHidden } from 'components/firebase/updateIsSidebarHidden';

export interface ComponentsState {
  type: 'application' | 'onBackground' | 'campaign' | 'fullpage';
  page: string;
  isSidebarHidden: boolean;
  status: string | null;
}

const initialState: ComponentsState = {
  type: 'application',
  page: 'dashboard',
  isSidebarHidden: false,
  status: null,
};

export const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // サイドバーの開閉
      .addCase(updateIsSidebarHidden.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateIsSidebarHidden.fulfilled, (state, action) => {
        state.status = 'success';
        state.isSidebarHidden = action.payload.newIsSidebarHidden;
      })
      .addCase(updateIsSidebarHidden.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      });
  },
});

// Action creators are generated for each case reducer function
export const { setType, setPage } = componentsSlice.actions;
export default componentsSlice.reducer;
