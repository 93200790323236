import { FC, ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { uidSelector } from 'Auth/redux/selector';
import { gradesSelector } from 'Settings/Templates/redux/selector';
import { appearancesSelector } from 'Settings/Templates/redux/selector';
import { paymentSelector } from 'Settings/Templates/redux/selector';
import { getOptions } from 'Settings/Templates/firebase/getOptions';
import { updateOptionOrderUp } from 'Settings/Templates/firebase/updateOptionOrderUp';
import { updateOptionOrderDown } from 'Settings/Templates/firebase/updateOptionOrderDown';
import { addOption } from 'Settings/Templates/firebase/addOption';
import { deleteOption } from 'Settings/Templates/firebase/deleteOption';
import { updateInputEn } from 'Settings/Templates/firebase/updateInputEn';
import { initOptions } from 'Settings/Templates/firebase/initOptions';
import {
  Typography,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Input,
  TextField,
  ClickAwayListener,
  Tooltip,
  Button,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Props = {
  selectedVender: 'ebay';
  title: string;
  section: string;
  subSection: string;
  selectedCategoryId?: string;
};

export const Options: FC<Props> = ({ selectedVender, title, section, subSection, selectedCategoryId }) => {
  const [isInputActive, setIsInputActive] = useState<string | null>(null);
  const [newOption, setNewOption] = useState('');
  const [inputEn, setInputEn] = useState('');
  const uid = useSelector(uidSelector);
  const gradeOptions = useSelector(gradesSelector);
  const appearanceOptions = useSelector(appearancesSelector);
  const paymentOptions = useSelector(paymentSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptions({ uid, selectedVender, section: section, subSection: subSection, selectedCategoryId }));
    return () => {
      dispatch(getOptions({ uid, selectedVender, section: section, subSection: subSection, selectedCategoryId }));
    };
  }, [dispatch, section, selectedCategoryId, selectedVender, subSection, uid]);

  // Optionを絞り込む
  let options;
  switch (section) {
    case 'categories':
      switch (subSection) {
        case 'grades':
          options = gradeOptions;
          break;
        case 'appearances':
          options = appearanceOptions;
          break;
        default:
          break;
      }
      break;
    case 'payment':
      options = paymentOptions;
      break;
    default:
      break;
  }

  // 英訳inputをアクティブにする
  const handleSetIsInputActive = (optionId: string) => {
    setIsInputActive(optionId);
  };

  // 英訳inputの状態管理
  const handleInputEnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputEn(e.target.value);
  };

  // 英訳を追加する
  const handleUpdateInputEn = async (optionId: string) => {
    dispatch(
      updateInputEn({
        uid,
        selectedVender,
        selectedCategoryId,
        section: section,
        subSection: subSection,
        optionId,
        inputEn,
      })
    );
  };

  // 英訳input 領域外をクリックした場合
  const handleClickAway = () => {
    setInputEn('');
    setIsInputActive(null);
  };

  // 項目の順序を上げる
  const handleOrderUp = async (optionId: string, order: number) => {
    dispatch(
      updateOptionOrderUp({
        uid,
        selectedVender,
        selectedCategoryId,
        section: section,
        subSection: subSection,
        optionId,
        order,
      })
    );
  };

  // 項目の順序を下げる
  const handleOrderDown = async (optionId: string, order: number, optionsLength: number) => {
    dispatch(
      updateOptionOrderDown({
        uid,
        selectedVender,
        selectedCategoryId,
        section: section,
        subSection: subSection,
        optionId,
        order,
        optionsLength,
      })
    );
  };

  // 項目を削除する
  const handleOptionDelete = async (optionId: string, order: number) => {
    dispatch(
      deleteOption({
        uid,
        selectedVender,
        selectedCategoryId,
        section: section,
        subSection: subSection,
        optionId,
        order,
      })
    );
  };

  // 新しい項目input
  const handleNewOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewOption(e.target.value);
  };

  // 新しい項目を追加
  const handleSetNewOption = (optionsLength: number) => {
    dispatch(
      addOption({
        uid,
        selectedVender,
        selectedCategoryId,
        section: section,
        subSection: subSection,
        newOption,
        optionsLength,
      })
    );
    setNewOption('');
    setTimeout(() => {
      dispatch(
        getOptions({
          uid: uid,
          selectedVender: selectedVender,
          section: section,
          subSection: subSection,
          selectedCategoryId: selectedCategoryId,
        })
      );
    }, 2500);
  };

  // プリセットに戻す
  const handleInitOptions = () => {
    dispatch(
      initOptions({
        uid,
        selectedVender,
        selectedCategoryId,
        section: section,
        subSection: subSection,
      })
    );
  };

  // console.log('inputEn', inputEn);

  return (
    <Box component='section' sx={{ mb: 7 }}>
      <Typography component='h2' variant='body1' sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Table size='small'>
        <TableBody>
          {options?.map((option: any, index: number) => (
            <TableRow key={index}>
              {/* Japanese */}
              <TableCell sx={{ width: 5 / 12, whiteSpace: 'pre-line' }}>
                {option.input ? option.input : option.ja}
              </TableCell>

              {/* English */}
              <TableCell sx={{ width: 5 / 12 }}>
                {isInputActive === option.id ? (
                  // 入力フォームを表示
                  <Grid container spacing={2} wrap='nowrap'>
                    <Grid item xs={true}>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Grid item xs={true}>
                          <Input
                            placeholder='English'
                            value={
                              inputEn
                                ? inputEn
                                : option.inputEn
                                ? option.inputEn
                                : option.en
                                ? option.en
                                : option.translated?.en
                            }
                            onChange={handleInputEnChange}
                            fullWidth
                            multiline
                            sx={{ mt: 0.5 }}
                          />
                        </Grid>
                      </ClickAwayListener>
                    </Grid>
                    <Grid item>
                      <Tooltip title='保存する'>
                        <IconButton aria-label='save'>
                          <SaveAltIcon onClick={() => handleUpdateInputEn(option.id)} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  // テキストを表示
                  <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                    <Grid item xs={true} sx={{ whiteSpace: 'pre-line' }}>
                      {option.inputEn ? option.inputEn : option.translated?.en ? option.translated?.en : option.en}
                    </Grid>
                    <Grid item>
                      <Tooltip title='英語表記を修正する'>
                        <IconButton aria-label='edit'>
                          <CreateIcon onClick={() => handleSetIsInputActive(option.id)} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </TableCell>

              <TableCell align='right' sx={{ width: 2 / 12, whiteSpace: 'nowrap' }}>
                {/* Up */}
                {option.order !== 0 ? (
                  <IconButton aria-label='edit'>
                    <KeyboardArrowUpIcon onClick={() => handleOrderUp(option.id, option.order)} />
                  </IconButton>
                ) : (
                  <IconButton aria-label='edit' disabled>
                    <KeyboardArrowUpIcon color='disabled' />
                  </IconButton>
                )}

                {/* Down */}
                {option.order !== options.length - 1 ? (
                  <IconButton aria-label='edit'>
                    <KeyboardArrowDownIcon onClick={() => handleOrderDown(option.id, option.order, options.length)} />
                  </IconButton>
                ) : (
                  <IconButton aria-label='edit' disabled>
                    <KeyboardArrowDownIcon color='disabled' />
                  </IconButton>
                )}

                {/* Delete */}
                <Tooltip title='この項目を削除'>
                  <IconButton aria-label='edit'>
                    <DeleteIcon onClick={() => handleOptionDelete(option.id, option.order)} color='disabled' />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2}>
              <TextField
                label='新しい項目（日本語で入力）'
                variant='standard'
                color='secondary'
                value={newOption}
                onChange={handleNewOptionChange}
                fullWidth
                multiline
              />
            </TableCell>
            <TableCell>
              <Tooltip title='追加する'>
                <IconButton aria-label='edit'>
                  <AddCircleOutlineIcon onClick={() => handleSetNewOption(options.length)} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box sx={{ mt: 1 }}>
        <Button onClick={() => handleInitOptions()}>［{title}］をプリセットに戻す</Button>
      </Box>
    </Box>
  );
};
