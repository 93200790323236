import { FC, useEffect } from 'react';
import { updateSelectOptionStatus } from 'Product/firebase/updateSelectOptionStatus';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { gradesSelector } from 'Product/redux/selector';
import { useParams } from 'react-router-dom';
import { initOptions } from 'Product/firebase/initOptions';
import { Box, FormControl, MenuItem, Table, TableBody, TableRow, TableCell } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
  title: string;
  selectedVender: string;
  section: string;
  subSection: string;
  selectedCategory: string;
};

export const SelectOptions: FC<Props> = ({ title, selectedVender, section, subSection, selectedCategory }) => {
  const uid = useSelector(uidSelector);
  const grades = useSelector(gradesSelector);
  const { productId } = useParams<{ productId: string }>();
  const dispatch = useDispatch();
  let options;

  useEffect(() => {
    if (selectedCategory) {
      dispatch(
        initOptions({
          uid: uid,
          productId: productId,
          selectedVender: selectedVender,
          section: section,
          subSection: subSection,
          selectedCategory: selectedCategory,
        })
      );
    }
    return () => {
      if (selectedCategory) {
        dispatch(
          initOptions({
            uid: uid,
            productId: productId,
            selectedVender: selectedVender,
            section: section,
            subSection: subSection,
            selectedCategory: selectedCategory,
          })
        );
      }
    };
  }, [dispatch, productId, section, selectedVender, subSection, selectedCategory, uid]);

  // optionsをセット
  switch (section) {
    case 'categories':
      switch (subSection) {
        case 'grades':
          options = grades;
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  const handleSelectOptionChange = (event: SelectChangeEvent) => {
    dispatch(
      updateSelectOptionStatus({
        uid: uid,
        productId: productId,
        selectedVender: selectedVender,
        section: section,
        subSection: subSection,
        selectedCategory: selectedCategory,
        currentOptionId: event.target.value as string,
      })
    );
  };

  // console.log('value', value);

  return (
    <Box component='section'>
      <Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: '12%', px: 1 }}>{title}</TableCell>
            <TableCell sx={{ width: '37%', px: 1 }}>
              {/* Japanese */}
              <FormControl variant='standard' sx={{ width: '100%' }}>
                <Select
                  labelId='grades-label'
                  label='商品のグレード'
                  value={
                    options?.find((e) => e.status === 'enable')?.id
                      ? options?.find((e) => e.status === 'enable')?.id
                      : ''
                  }
                  onChange={handleSelectOptionChange}
                  fullWidth
                  sx={{ my: 1 }}
                >
                  {options?.map((option: any) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.input ? option.input : option.ja}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>

            <TableCell sx={{ width: '37%', px: 1 }}>
              {/* English */}
              {options?.find((e) => e.status === 'enable')?.en ? options?.find((e) => e.status === 'enable')?.en : ''}
            </TableCell>
            <TableCell sx={{ width: '14%', px: 1 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
