import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getSubscriptions = createAsyncThunk<firebase.firestore.DocumentData, { uid: string }>(
  'user/getSubscriptions',
  async ({ uid }) => {
    const docsRef = db
      .collection('users')
      .doc(uid)
      .collection('subscriptions')
      .where('status', 'in', ['active', 'trialing']);
    let res: firebase.firestore.DocumentData | undefined = {};

    await docsRef.get().then((querySnapshot) => {
      res = Promise.all(
        querySnapshot.docs.map(async (doc) => {
          // idと必要なフィールドを取得してtimestampをエポック秒に変換
          const data = {
            id: doc.id,
            current_period_end: doc.data()?.current_period_end.toMillis(),
            role: doc.data()?.role,
            status: doc.data()?.status,
          };

          // subcolectionsを取得
          // let xxx: firebase.firestore.DocumentData | undefined = undefined;
          // const xxxRef = doc.ref.collection('xxx').doc('doc');
          // await xxxRef
          //   .get()
          //   .then((doc) => {
          //     if (doc.exists) {
          //       xxx = doc.data();
          //     } else {
          //       console.log('No such document!');
          //     }
          //   })
          //   .catch((error) => {
          //     console.log('Error getting document:', error);
          //   });

          return { ...data };
          // return { id: doc.id, name, cover, ...doc.data() };
        })
      );
    });

    // await docsRef
    //   .get()
    //   .then((snapshot) => {
    //     res = snapshot.docs.map((doc) => Object.assign({ id: doc.id, ...doc.data() }));
    //   })
    //   .catch((error) => {
    //     console.log('Error getting documents: ', error);
    //   });

    return res;
  }
);
