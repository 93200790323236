import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getProducts = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    limit: number;
    isDocEmpty: boolean;
    // lastDoc: firebase.firestore.DocumentData | undefined;
    // lastDoc: string;
    lastDoc: number | undefined;
    fetchMore: boolean;
  }
>('products/getProducts', async ({ uid, limit, isDocEmpty, lastDoc, fetchMore }) => {
  let docsRef;
  let res: firebase.firestore.DocumentData = [];
  let lastDocTimeStamp;

  if (lastDoc) {
    lastDocTimeStamp = firebase.firestore.Timestamp.fromMillis(lastDoc);
  }

  switch (fetchMore) {
    case true:
      docsRef = db
        .collection('users')
        .doc(uid)
        .collection('products')
        .orderBy('createdAt', 'desc')
        .startAfter(lastDocTimeStamp)
        .limit(limit);
      break;
    case false:
      docsRef = db.collection('users').doc(uid).collection('products').orderBy('createdAt', 'desc').limit(limit);
      break;
    default:
      docsRef = undefined;
      break;
  }

  if (docsRef) {
    await docsRef.get().then(async (querySnapshot) => {
      lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1].data().createdAt.toMillis();

      if (querySnapshot.size < limit) {
        isDocEmpty = true;
      }

      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          // idとフィールドを取得してtimestampをエポック秒に変換
          const data = {
            id: doc.id,
            createdAt: doc.data().createdAt.toMillis(),
            category: doc.data().category,
          };

          // 商品名を取得
          let name: firebase.firestore.DocumentData | undefined = undefined;
          const nameRef = doc.ref.collection('name').doc('doc');
          await nameRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                name = doc.data();
              } else {
                console.log('No such document!');
              }
            })
            .catch((error) => {
              console.log('Error getting document:', error);
            });

          // カバー画像を取得
          let cover: firebase.firestore.DocumentData = [];
          const coverRef = doc.ref.collection('images').where('order', '==', 0);
          await coverRef.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // フィールドを取得してtimestampをエポック秒に変換
              const data = {
                originalFileName: doc.data().originalFileName,
                originalFileSize: doc.data().originalFileSize,
                thumb1920url: doc.data().thumb1920url,
                thumb960url: doc.data().thumb960url,
                thumb480url: doc.data().thumb480url,
                square1920url: doc.data().square1920url,
                square960url: doc.data().square960url,
                square480url: doc.data().square480url,
                createdAt: doc.data().createdAt?.toMillis(),
                // createdAt: doc.data().createdAt?.toDate(),
              };
              cover.push(data);
              cover = cover[0];
            });
          });
          res.push({ ...data, name, cover });
        })
      );
    });
  }

  return { res: res, isDocEmpty: isDocEmpty, lastDoc: lastDoc, fetchMore: fetchMore };
});
