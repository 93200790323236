import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateCategory = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    vender: 'ebay' | 'yahooAuction';
    categoryId: string;
    level: 'primary' | 'secondary' | 'tertiary';
    status: 'enable' | 'disable';
    targetCategory: firebase.firestore.DocumentData | undefined;
    primaryParentId: string | undefined;
    secondaryParentId: string | undefined;
    secondaryChildren: firebase.firestore.DocumentData[] | undefined;
    tertiaryChildren: firebase.firestore.DocumentData[] | undefined;
  }
>(
  'cotegories/updateCategory',
  async ({
    uid,
    vender,
    categoryId,
    level,
    status,
    targetCategory,
    primaryParentId,
    secondaryParentId,
    secondaryChildren,
    tertiaryChildren,
  }) => {
    const docsRef = db.collection('users').doc(uid).collection('templates').doc(vender).collection('categories');

    switch (status) {
      // OFFにする
      case 'enable':
        switch (level) {
          case 'primary':
            await docsRef.doc(targetCategory?.id).update({
              status: 'disable',
            });
            // 子をdisableにする
            if (secondaryChildren && secondaryChildren.length > 0) {
              await Promise.all(
                secondaryChildren.map(async (secondaryChild) => {
                  await docsRef.doc(secondaryChild.id).update({
                    status: 'disable',
                  });
                })
              );
            }
            if (tertiaryChildren && tertiaryChildren.length > 0) {
              await Promise.all(
                tertiaryChildren.map(async (tertiaryChild) => {
                  await docsRef.doc(tertiaryChild.id).update({
                    status: 'disable',
                  });
                })
              );
            }
            break;
          case 'secondary':
            await docsRef.doc(targetCategory?.id).update({
              status: 'disable',
            });
            // 子をdisableにする
            if (tertiaryChildren && tertiaryChildren.length > 0) {
              await Promise.all(
                tertiaryChildren.map(async (tertiaryChild) => {
                  await docsRef.doc(tertiaryChild.id).update({
                    status: 'disable',
                  });
                })
              );
            }
            break;
          case 'tertiary':
            await docsRef.doc(targetCategory?.id).update({
              status: 'disable',
            });
            break;
          default:
            break;
        }
        break;
      // ONにする
      case 'disable':
        switch (level) {
          case 'primary':
            await docsRef.doc(targetCategory?.id).update({
              status: 'enable',
            });
            // 子をenableにする
            if (secondaryChildren) {
              await Promise.all(
                secondaryChildren.map(async (secondaryChild) => {
                  await docsRef.doc(secondaryChild.id).update({
                    status: 'enable',
                  });
                })
              );
            }
            if (tertiaryChildren) {
              await Promise.all(
                tertiaryChildren.map(async (tertiaryChild) => {
                  await docsRef.doc(tertiaryChild.id).update({
                    status: 'enable',
                  });
                })
              );
            }
            break;
          case 'secondary':
            await docsRef.doc(targetCategory?.id).update({
              status: 'enable',
            });
            // 親をenableにする
            await docsRef.doc(primaryParentId).update({
              status: 'enable',
            });
            // 子をenableにする
            if (tertiaryChildren) {
              await Promise.all(
                tertiaryChildren.map(async (tertiaryChild) => {
                  await docsRef.doc(tertiaryChild.id).update({
                    status: 'enable',
                  });
                })
              );
            }
            break;
          case 'tertiary':
            await docsRef.doc(targetCategory?.id).update({
              status: 'enable',
            });
            // 親をenableにする
            await docsRef.doc(primaryParentId).update({
              status: 'enable',
            });
            await docsRef.doc(secondaryParentId).update({
              status: 'enable',
            });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    return {
      vender: vender,
      categoryId: categoryId,
      level: level,
      status: status,
      targetCategory: targetCategory,
      primaryParentId: primaryParentId,
      secondaryParentId: secondaryParentId,
      secondaryChildren: secondaryChildren,
      tertiaryChildren: tertiaryChildren,
    };
  }
);
