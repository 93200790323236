import { useState, Dispatch, SetStateAction } from 'react';
import { auth } from 'app/firebase';
import 'firebaseui-ja/dist/firebaseui.css';

type Props = {
  setFlag: Dispatch<SetStateAction<boolean>>;
};
export const useCheckProvider = ({ setFlag }: Props) => {
  const [isProvider, setIsProvider] = useState(false);

  // Provider認証かチェック
  const checkProvider = () => {
    const providerData = auth.currentUser?.providerData;
    const target = {
      providerId: 'google.com',
    };
    const some = providerData?.some((element) => element?.providerId === target.providerId);
    if (some) {
      setIsProvider(true);
    }
    if (setFlag) {
      setFlag(true);
    }
  };

  return { checkProvider, isProvider };
};
