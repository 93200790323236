import { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY ? STRIPE_PUBLISHABLE_KEY : '');

export const StripeProvider: FC = ({ children }) => {
    const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

    if (!STRIPE_PUBLISHABLE_KEY) {
        console.log('STRIPE_PUBLISHABLE_KEY is undefined');
        return <>{children}</>;
    }

    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

    return <Elements stripe={stripePromise}>{children}</Elements>;
};
