import { FC, useEffect, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { productEntitiesSelector } from 'Product/redux/selector';
import { activeCategoriesSelector } from 'Settings/Categories/redux/selector';
import { getActiveCategories } from 'Settings/Categories/firebase/getActiveCategories';
import { updateCategory } from 'Product/firebase/updateCategory';
import { Box, MenuItem, Typography, Stack, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
  selectedVender: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
};

export const Category: FC<Props> = ({ selectedVender, setSelectedCategory }) => {
  const uid = useSelector(uidSelector);
  const product = useSelector(productEntitiesSelector);
  const activeCategories = useSelector(activeCategoriesSelector);
  const dispatch = useDispatch();

  // カテゴリーリストを取得
  useEffect(() => {
    dispatch(getActiveCategories({ uid, selectedVender: selectedVender }));
    return () => {
      dispatch(getActiveCategories({ uid, selectedVender: selectedVender }));
    };
  }, [dispatch, selectedVender, uid]);

  // フォームの状態管理
  const handlePrimaryChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCategory({ uid, productId: product.id, selectedId: event.target.value, level: 'primary' }));
    if (event.target.value !== 'n/a') {
      setSelectedCategory(event.target.value);
    }
  };
  const handleSecondaryChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCategory({ uid, productId: product.id, selectedId: event.target.value, level: 'secondary' }));
    if (event.target.value !== 'n/a') {
      setSelectedCategory(event.target.value);
    }
  };
  const handleTertiaryChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCategory({ uid, productId: product.id, selectedId: event.target.value, level: 'tertiary' }));
    if (event.target.value !== 'n/a') {
      setSelectedCategory(event.target.value);
    }
  };

  return (
    <Box component='section' sx={{ mb: 5 }}>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        商品カテゴリー
      </Typography>

      <Stack direction='row' alignItems='flex-start' spacing={1}>
        <Box sx={{ width: '100%' }}>
          <TextField
            id='primary-category-label'
            select
            label='大分類'
            value={product.categoryPrimary}
            onChange={handlePrimaryChange}
            helperText={product.categoryPrimary}
            fullWidth
          >
            {activeCategories
              .filter((e) => e.level === 'primary')
              .map((option) => (
                <MenuItem value={option.id} key={option.id}>
                  {option.ja}
                </MenuItem>
              ))}
          </TextField>
        </Box>

        {activeCategories.filter((e) => e.level === 'secondary').length > 0 && (
          // {secondaryList.filter((e) => e.primary === selectedPrimaryId).length > 0 && (
          <>
            <Box sx={{ pt: 2.2 }}>
              <ArrowForwardIosIcon fontSize='small' />
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextField
                id='secondary-category-label'
                select
                label='中分類'
                value={product.categorySecondary}
                onChange={handleSecondaryChange}
                helperText={product.categorySecondary}
                fullWidth
              >
                {activeCategories
                  .filter((e) => e.level === 'secondary')
                  .filter((e) => e.primary === product.categoryPrimary)
                  .map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.ja}
                    </MenuItem>
                  ))}
                <MenuItem value='n/a'>All</MenuItem>
              </TextField>
            </Box>
          </>
        )}

        {activeCategories.filter((e) => e.level === 'tertiary').length > 0 && (
          // {tertiaryList.filter((e) => e.secondary === selectedSecondaryId).length > 0 && (
          <>
            <Box sx={{ pt: 2.2 }}>
              <ArrowForwardIosIcon fontSize='small' />
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextField
                id='tertiary-category-label'
                select
                label='小分類'
                value={product.categoryTertiary}
                onChange={handleTertiaryChange}
                helperText={product.categoryTertiary}
                fullWidth
              >
                {activeCategories
                  .filter((e) => e.level === 'tertiary')
                  .filter((e) => e.secondary === product.categorySecondary)
                  .map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.ja}
                    </MenuItem>
                  ))}
                <MenuItem value='n/a'>All</MenuItem>
              </TextField>
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
