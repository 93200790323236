import { FC } from 'react';
import { useSelector } from 'react-redux';
import { nameSelector } from 'Product/redux/selector';
import { imagesSelector } from 'Product/redux/selector';
import { imageFormatSelector } from 'User/redux/selector';
import { gradesSelector } from 'Product/redux/selector';
import { appearancesSelector } from 'Product/redux/selector';
import { paymentSelector } from 'Product/redux/selector';
import { useStyles } from './styles';

type Props = {
  colorTheme:
    | {
        text: string;
        background: string;
      }
    | undefined;
};

export const Ebay01: FC<Props> = ({ colorTheme }) => {
  const name = useSelector(nameSelector);
  const images = useSelector(imagesSelector);
  const imageFormat = useSelector(imageFormatSelector);
  const grades = useSelector(gradesSelector);
  const appearances = useSelector(appearancesSelector);
  const payment = useSelector(paymentSelector);
  const styleProps = { text: colorTheme?.text, background: colorTheme?.background };
  const classes = useStyles(styleProps);

  return (
    <article className={classes.article}>
      <section className={classes.header}>
        <h1 className={classes.headerProductName}>{name?.inputEn ? name?.inputEn : name?.translated?.en}</h1>
      </section>
      <section className={classes.thumbs}>
        <ul className={classes.thumbsUl}>
          {images?.map((image, index) => (
            <li key={image.originalFileName} className={classes.thumbsLi}>
              <img
                src={imageFormat === 'webp' ? image.thumb960url + '.webp' : image.thumb960url + '.jpeg'}
                alt={name?.translated?.en + '-' + index}
                className={classes.thumbsItem}
              />
            </li>
          ))}
        </ul>
        <p className={classes.notes}>* There are larger images below.</p>
      </section>
      <section className={classes.section}>
        <h1 className={classes.sectionTitle}>Description</h1>
        {grades.length > 0 && (
          <>
            <h2 className={classes.subsectionTitle}>Grade</h2>
            <div className={classes.subsectionBody}>{grades?.find((v: any) => v.status === 'enable')?.en}</div>
          </>
        )}
        {appearances.length > 0 && (
          <>
            <h2 className={classes.subsectionTitle}>Appearance</h2>
            <div className={classes.subsectionBody}>
              <ul>
                {appearances
                  .filter((v: any) => v.status !== 'disable')
                  .map((appearance) => (
                    <li key={appearance.id}>{appearance.en ? appearance.en : appearance.translated.en}</li>
                  ))}
              </ul>
            </div>
          </>
        )}
      </section>
      <section className={classes.section}>
        <h1 className={classes.sectionTitle}>Payment</h1>
        {payment.length > 0 && (
          <>
            {/* <h2 className={classes.subsectionTitle}>Appearance</h2> */}
            <div className={classes.subsectionBody}>
              <ul>
                {payment
                  .filter((v: any) => v.status !== 'disable')
                  .map((payment) => (
                    <li key={payment.id}>{payment.en ? payment.en : payment.translated.en}</li>
                  ))}
              </ul>
            </div>
          </>
        )}
      </section>
      <section className={classes.images}>
        <ul className={classes.imagesUl}>
          {images?.map((image, index) => (
            <li key={image.originalFileName} className={classes.imagesLi}>
              <img
                src={imageFormat === 'webp' ? image.thumb1920url + '.webp' : image.thumb1920url + '.jpeg'}
                alt={image.alt ? image.alt : name?.translated?.en + '-' + index}
                className={classes.imagesItem}
              />
            </li>
          ))}
        </ul>
      </section>
    </article>
  );
};
