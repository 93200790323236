import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const initOptions = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    section: string;
    subSection: string;
    selectedCategoryId?: string;
  }
>('templates/initOptions', async ({ uid, selectedVender, section, subSection, selectedCategoryId }) => {
  let docsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | undefined;
  let toDeleteOptionIds: string[] = [];
  let presetOptions: firebase.firestore.DocumentData = [];

  switch (section) {
    case 'categories':
      docsRef = db
        .collection('users')
        .doc(uid)
        .collection('templates')
        .doc(selectedVender)
        .collection('categories')
        .doc(selectedCategoryId)
        .collection(subSection);
      break;
    case 'payment':
      docsRef = db.collection('users').doc(uid).collection('templates').doc(selectedVender).collection(section);
      break;

    default:
      break;
  }

  // 既存のoptionId一覧を取得
  if (docsRef) {
    await docsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        toDeleteOptionIds.push(doc.id);
      });
    });
  }

  // 既存のoptionを全て削除
  await Promise.all(
    toDeleteOptionIds.map(async (deleteOptionId: string) => {
      if (docsRef) {
        await docsRef
          .doc(deleteOptionId)
          .delete()
          .then(() => {
            console.log('Document successfully deleted!');
          })
          .catch((error) => {
            console.error('Error removing document: ', error);
          });
      }
    })
  );

  // プリセットを取得
  let presetDocsRef;

  switch (section) {
    case 'categories':
      presetDocsRef = db
        .collection('templates')
        .doc(selectedVender)
        .collection('categories')
        .doc(selectedCategoryId)
        .collection(subSection)
        .orderBy('order');
      break;
    case 'payment':
      presetDocsRef = db.collection('templates').doc(selectedVender).collection(section).orderBy('order');
      break;
    default:
      break;
  }

  if (presetDocsRef) {
    await presetDocsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        presetOptions.push({ id: doc.id, ...doc.data() });
      });
    });
  }

  // 取得したプリセットをセット
  await Promise.all(
    presetOptions.map(async (presetOption: firebase.firestore.DocumentData) => {
      if (docsRef) {
        await docsRef
          .doc(presetOption.id)
          .set({
            ...presetOption,
          })
          .then(() => {
            console.log('Document successfully written!');
          })
          .catch((error) => {
            console.error('Error writing document: ', error);
          });
      }
    })
  );

  // console.log('presetOptions', presetOptions);

  return { section: section, subSection: subSection, presetOptions: presetOptions };
});
