// import { DropZoneFiles } from 'types';

type Props = {
  uid: string | undefined;
  // file: DropZoneFiles;
  productId: string;
  originalFileName: string;
};

export const buildThumbUrls = ({ uid, productId, originalFileName }: Props) => {
  let withoutExtensionFileName = originalFileName.substring(originalFileName.lastIndexOf('/') + 1);

  if (withoutExtensionFileName.lastIndexOf('.') !== -1)
    withoutExtensionFileName = withoutExtensionFileName.substring(0, withoutExtensionFileName.lastIndexOf('.'));

  const thumb480 = withoutExtensionFileName + '_480x480';
  const thumb960 = withoutExtensionFileName + '_960x960';
  const thumb1920 = withoutExtensionFileName + '_1920x1920';
  const square480 = 'square_' + withoutExtensionFileName + '_480x480';
  const square960 = 'square_' + withoutExtensionFileName + '_960x960';
  const square1920 = 'square_' + withoutExtensionFileName + '_1920x1920';

  const cloudStrageDirectory = 'https://storage.googleapis.com/cloudpix-f082f.appspot.com';
  const imageDirectory = `${cloudStrageDirectory}/${uid}/public/${productId}/${originalFileName}/`;
  const thumb480url = imageDirectory + withoutExtensionFileName + '_480x480';
  const thumb960url = imageDirectory + withoutExtensionFileName + '_960x960';
  const thumb1920url = imageDirectory + withoutExtensionFileName + '_1920x1920';
  const square480url = imageDirectory + 'square_' + withoutExtensionFileName + '_480x480';
  const square960url = imageDirectory + 'square_' + withoutExtensionFileName + '_960x960';
  const square1920url = imageDirectory + 'square_' + withoutExtensionFileName + '_1920x1920';

  return {
    thumb480: thumb480,
    thumb960: thumb960,
    thumb1920: thumb1920,
    square480: square480,
    square960: square960,
    square1920: square1920,
    thumb480url: thumb480url,
    thumb960url: thumb960url,
    thumb1920url: thumb1920url,
    square480url: square480url,
    square960url: square960url,
    square1920url: square1920url,
  };
};
