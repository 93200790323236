import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { functions } from 'app/firebase';
// import { db } from 'app/firebase';

export const deleteAtPath = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    path: string;
    selectedCategoryId: string;
  }
>('templates/deleteAtPath', async ({ path, selectedCategoryId }) => {
  /**
   * Call the 'recursiveDelete' callable function with a path to initiate
   * a server-side delete.
   */
  // console.log('path', path);

  // var deleteFn = firebase.functions().httpsCallable('recursiveDelete');
  const deleteFn = functions.httpsCallable('recursiveDelete');
  await deleteFn({ path: path })
    .then(function (result) {
      // logMessage('Delete success: ' + JSON.stringify(result));
      console.log('Delete success: ' + JSON.stringify(result));
    })
    .catch(function (err) {
      // logMessage('Delete failed, see console,');
      console.warn(err);
    });

  return {
    selectedCategoryId: selectedCategoryId,
  };
});
