import { FC, useState } from 'react';
import firebase from 'firebase/app';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { imagesSelector } from 'Product/redux/selector';
import { updateCover } from 'Products/redux/slice';
import { useParams } from 'react-router-dom';
import { updateOrderUp } from 'Product/firebase/updateOrderUp';
import { updateOrderDown } from 'Product/firebase/updateOrderDown';
import { deleteOption } from 'Product/firebase/deleteOption';
import { buildThumbUrls } from 'components/buildThumbUrls';
import { deleteFilesFromStorage } from 'Product/firebase/deleteFilesFromStorage';
import { Box, Table, TableBody, TableRow, TableCell, IconButton, Tooltip, Modal } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  selectedVender: string;
};

export const Images: FC<Props> = ({ selectedVender }) => {
  const images = useSelector(imagesSelector);
  const uid = useSelector(uidSelector);
  const [openId, setOpenId] = useState('');
  const dispatch = useDispatch();
  const { productId } = useParams<{ productId: string }>();

  const handleOpen = (thumb1920url: string) => {
    setOpenId(thumb1920url);
  };

  // UP
  const handleOrderUp = (image: firebase.firestore.DocumentData) => {
    dispatch(
      updateOrderUp({
        uid: uid,
        productId: productId,
        selectedVender: selectedVender,
        section: 'images',
        subSection: '',
        currentOptionId: image.id,
        currentOptionOrder: image.order,
        selectedCategory: '',
      })
    );
    // currentOptionOrderが1（up後はorderが0になる）の場合、productsのcoverを更新する
    if (image.order === 1) {
      dispatch(
        updateCover({
          productId: productId,
          originalFileName: image.originalFileName,
          originalFileSize: image.originalFileSize,
          thumb1920url: image.thumb1920url,
          thumb960url: image.thumb960url,
          thumb480url: image.thumb480url,
          square1920url: image.square1920url,
          square960url: image.square960url,
          square480url: image.square480url,
          createdAt: image.createdAt,
        })
      );
    }
  };

  // Down
  const handleOrderDown = (image: firebase.firestore.DocumentData) => {
    dispatch(
      updateOrderDown({
        uid: uid,
        productId: productId,
        selectedVender: selectedVender,
        section: 'images',
        subSection: '',
        currentOptionId: image.id,
        currentOptionOrder: image.order,
        selectedCategory: '',
      })
    );
    // currentOptionOrderが0（Down後はorderが1になる）の場合、productsのcoverをorder===1の画像に更新する
    if (image.order === 0) {
      const newCover = images.find((e) => e.order === 1);
      if (newCover) {
        dispatch(
          updateCover({
            productId: productId,
            originalFileName: newCover.originalFileName,
            originalFileSize: newCover.originalFileSize,
            thumb1920url: newCover.thumb1920url,
            thumb960url: newCover.thumb960url,
            thumb480url: newCover.thumb480url,
            square1920url: newCover.square1920url,
            square960url: newCover.square960url,
            square480url: newCover.square480url,
            createdAt: newCover.createdAt,
          })
        );
      }
    }
  };

  // 画像削除
  const handleDelete = (imageId: string, imageOrder: number, originalFileName: string) => {
    dispatch(
      deleteOption({
        uid: uid,
        productId: productId,
        selectedVender: selectedVender,
        section: 'images',
        subSection: '',
        currentOptionId: imageId,
        optionOrder: imageOrder,
        selectedCategory: '',
      })
    );
    const { thumb480, thumb960, thumb1920, square480, square960, square1920 } = buildThumbUrls({
      uid: uid,
      productId: productId,
      originalFileName: originalFileName,
    });
    deleteFilesFromStorage({
      uid: uid,
      productId: productId,
      originalFileName: originalFileName,
      thumb480: thumb480,
      thumb960: thumb960,
      thumb1920: thumb1920,
      square480: square480,
      square960: square960,
      square1920: square1920,
    });
  };

  // 画像読み込みエラーの場合、再取得する（アップロード直後）
  const [reload, setReload] = useState(false);

  // console.log('images', images);

  return (
    <Box component='section' sx={{ mt: 5 }}>
      <Table size='small'>
        <TableBody>
          {images &&
            images.map((image) => (
              <>
                <TableRow key={image.id}>
                  <TableCell sx={{ width: '15%', px: 1, py: 2 }}>
                    {/* 画像 */}
                    <Tooltip title='クリックして拡大'>
                      <Box
                        key={image.originalFileName}
                        sx={{
                          position: 'relative',
                          height: 0,
                          paddingBottom: '100%' /* 幅に対する縦のサイズ */,
                          overflow: 'hidden',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleOpen(image.thumb1920url)}
                      >
                        {!reload && (
                          <img
                            src={image.thumb480url + '.webp'}
                            alt={image.alt}
                            loading='lazy'
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%,-50%)',
                              width: 'auto',
                              height: 'auto',
                              maxWidth: '179%' /* 横向き16:9の画像が表示エリア一杯になるように指定 */,
                              maxHeight: 'initial',
                            }}
                            onError={() => setReload(true)}
                          />
                        )}
                      </Box>
                    </Tooltip>
                    <Modal
                      open={openId === image.thumb1920url}
                      onClick={() => setOpenId('')}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                      sx={{ cursor: 'pointer' }}
                    >
                      <Tooltip title='クリックして閉じる'>
                        <Box
                          sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '75vw',
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <img
                            src={image.thumb1920url + '.webp'}
                            alt={image.alt}
                            loading='lazy'
                            style={{ width: '100%' }}
                          />
                        </Box>
                      </Tooltip>
                    </Modal>
                  </TableCell>

                  <TableCell sx={{ width: 'auto', px: 3 }}>
                    {/* ファイル名 */}
                    <Box>{image.originalFileName}</Box>
                  </TableCell>

                  <TableCell align='right' sx={{ whiteSpace: 'nowrap', px: 1 }}>
                    {/* Up */}
                    {image.order !== 0 ? (
                      <IconButton aria-label='edit'>
                        <KeyboardArrowUpIcon onClick={() => handleOrderUp(image)} />
                      </IconButton>
                    ) : (
                      <IconButton aria-label='edit' disabled>
                        <KeyboardArrowUpIcon color='disabled' />
                      </IconButton>
                    )}

                    {/* Down */}
                    {image.order !== images.length - 1 ? (
                      <IconButton aria-label='edit'>
                        <KeyboardArrowDownIcon onClick={() => handleOrderDown(image)} />
                      </IconButton>
                    ) : (
                      <IconButton aria-label='edit' disabled>
                        <KeyboardArrowDownIcon color='disabled' />
                      </IconButton>
                    )}

                    {/* Delete */}
                    <Tooltip title='この画像を削除'>
                      <IconButton aria-label='edit'>
                        <DeleteIcon
                          onClick={() => handleDelete(image.id, image.order, image.originalFileName)}
                          color='disabled'
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};
