import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getUser = createAsyncThunk<firebase.firestore.DocumentData, { uid: string }>(
  'user/getUser',
  async ({ uid }) => {
    const docRef = db.collection('users').doc(uid);
    let res: firebase.firestore.DocumentData | undefined = {};

    await docRef.get().then((doc) => {
      if (doc.exists) {
        // idとフィールドを取得してtimestampをエポック秒に変換
        const data = {
          id: doc.id,
          created: doc.data()?.created.toMillis(),
          mode: doc.data()?.mode,
          stripeLink: doc.data()?.isDarkmode,
          imageFormat: doc.data()?.imageFormat,
          stripeId: doc.data()?.stripeId,
          email: doc.data()?.email,
          fileSize: doc.data()?.fileSize,
          imageCount: doc.data()?.imageCount,
          // ...doc.data(),
        };

        // res = { id: doc.id, ...doc.data() };
        res = { ...data };
      } else {
        console.log('No such document!');
      }
    });

    return res;
  }
);
