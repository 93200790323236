import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const deleteOption = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    selectedCategoryId?: string;
    section: string;
    subSection: string;
    optionId: string;
    order: number;
  }
>(
  'templates/deleteOption',
  async ({ uid, selectedVender, selectedCategoryId, section, subSection, optionId, order }) => {
    let docsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | undefined;
    let upperOrderDocIds: string[] = [];

    switch (section) {
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection('categories')
          .doc(selectedCategoryId)
          .collection(subSection);
        break;
      case 'payment':
        docsRef = db.collection('users').doc(uid).collection('templates').doc(selectedVender).collection(section);
        break;
      default:
        break;
    }

    // orderが削除するdocより大きいdocIdを取得する
    if (docsRef) {
      await docsRef
        .where('order', '>', order)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            upperOrderDocIds.push(doc.id);
          });
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    }

    // orderを-1する
    if (upperOrderDocIds.length > 0) {
      await Promise.all(
        upperOrderDocIds.map(async (upperOrderDocId) => {
          await docsRef?.doc(upperOrderDocId).update({
            order: firebase.firestore.FieldValue.increment(-1),
          });
        })
      );
    }

    // 項目を削除する
    if (docsRef) {
      await docsRef
        .doc(optionId)
        .delete()
        .then(() => {
          console.log('Document successfully deleted!');
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });
    }

    return { section: section, subSection: subSection, optionId: optionId, upperOrderDocIds: upperOrderDocIds };
  }
);
