import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';

export const deleteUser = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    currentUser: firebase.User | null;
  }
>('product/deleteUser', async ({ currentUser }) => {
  if (currentUser) {
    await currentUser
      .delete()
      .then(function () {
        console.log('User deleted.');
      })
      .catch(function (error: { code: string }) {
        console.log('An error happened.', error);
        if (error.code === 'auth/requires-recent-login') {
          alert('エラー：一旦ログアウトした後、再度ログインしてやり直してください。');
        }
      });
  }

  return {
    currentUser: currentUser,
  };
});
