import { FC, useState, FormEvent, ChangeEvent } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useDispatch } from 'react-redux';
import { setType } from 'components/redux/slice';
import { setLightmode } from 'User/redux/slice';
import { Link } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button, Alert, Box } from '@mui/material';
// import { Alert } from '@material-ui/lab';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

export const ForgotPassword: FC = () => {
  const { sendPasswordResetEmail } = useAuth();
  const [error, setError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(null!);
  const dispatch = useDispatch();

  dispatch(setType('onBackground'));

  // 一時的にライトモードを強制する
  dispatch(setLightmode());

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await sendPasswordResetEmail(email);
      setMessage('パスワードリセット用メールを送信しました');
    } catch {
      setError('リセット用メールを送信できませんでした');
    }
    setLoading(false);
  };

  return (
    <Container maxWidth='xs'>
      <Paper
        sx={{
          py: 8,
          px: 1.5,
          textAlign: 'center',
          color: (theme) => theme.palette.text.secondary,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <VpnKeyIcon
          sx={{
            mb: 1,
            backgroundColor: (theme) => theme.palette.secondary.main,
            borderRadius: '50%',
            p: 1,
            width: '48px',
            height: '48px',
            color: 'white',
          }}
        />
        <Typography component='h1' variant='h5'>
          パスワードをお忘れですか？
        </Typography>
        {error && (
          <Alert severity='error' sx={{ m: 2 }}>
            {error}
          </Alert>
        )}
        {message && <Alert sx={{ m: 2 }}>{message}</Alert>}
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              m: 4,
              '&.MuiOutlinedInput-input:-webkit-autofill': {
                boxShadow: 'none',
              },
            }}
          >
            <TextField
              id='outlined-basic'
              label='メールアドレス'
              variant='outlined'
              type='email'
              value={email}
              onChange={handleEmailChange}
              required
              fullWidth
              sx={{ mt: 2 }}
            />
            <Button
              disabled={loading}
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              size='large'
              sx={{ mt: 3 }}
            >
              パスワードをリセット
            </Button>
          </Box>
        </form>
        <Box sx={{ mt: 5 }}>
          <Link to='/signin'>ログインフォームへ戻る</Link>
        </Box>
      </Paper>
    </Container>
  );
};
