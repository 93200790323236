import { FC } from 'react';
import 'firebaseui-ja/dist/firebaseui.css';
import { Snackbar, Alert } from '@mui/material';

type Props = {
  severity: 'error' | 'info' | 'success' | 'warning';
  isOpen: boolean;
  handleClose: () => void;
};

export const SnackbarAlert: FC<Props> = ({ severity, isOpen, handleClose, children }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      sx={{ transform: 'none', position: 'inherit', display: 'block', mb: 3 }}
    >
      <Alert severity={severity}>{children}</Alert>
    </Snackbar>
  );
};
