import { useSelector } from 'react-redux';
import { nameSelector } from 'Product/redux/selector';
import { imagesSelector } from 'Product/redux/selector';
import { imageFormatSelector } from 'User/redux/selector';
import { gradesSelector } from 'Product/redux/selector';
import { appearancesSelector } from 'Product/redux/selector';
import { paymentSelector } from 'Product/redux/selector';
import { Style } from './Style';

type Props = {
  colorTheme:
    | {
        text: string;
        background: string;
      }
    | undefined;
};

export const Ebay01 = ({ colorTheme }: Props) => {
  const name = useSelector(nameSelector);
  const images = useSelector(imagesSelector);
  const imageFormat = useSelector(imageFormatSelector);
  const grades = useSelector(gradesSelector);
  const appearances = useSelector(appearancesSelector);
  const payment = useSelector(paymentSelector);
  let { style } = Style({ text: colorTheme?.text, background: colorTheme?.background });

  // CSSの空白を削除
  style = style.replace(/\s+/g, '');

  // console.log('images', images);

  let sourceCode = `
    ${style}
    <article class="article">
      <section>
        <h1 class="headerProductName">${name?.inputEn ? name?.inputEn : name?.translated?.en}</h1>
      </section>
      <section class="thumbs">
        <ul class="thumbsUl">
          ${images?.map(
            (image, index) =>
              `<li key=${image.originalFileName} class='thumbsLi'><img src="${
                imageFormat === 'webp' ? image.thumb960url + '.webp' : image.thumb960url + '.jpeg'
              }" alt="${name?.translated?.en + '-' + (index += 1)}" class="thumbsItem"/></li>`
          )}
        </ul>
        <p class="notes">* There are larger images below.</p>
      </section>
      <section class="section">
        <h1 class="sectionTitle">Description</h1>
        ${
          grades.length > 0 &&
          `<h2 class="subsectionTitle">Grade</h2><div class="subsectionBody">${
            grades?.find((v: any) => v.status === 'enable')?.en
          }</div>`
        }
        ${
          appearances.length > 0 &&
          `<h2 class="subsectionTitle">Appearance</h2><div class="subsectionBody"><ul class="subsectionBodyUl">
            ${appearances
              .filter((v: any) => v.status !== 'disable')
              .map(
                (appearance) =>
                  `<li key=${appearance.id} class="subsectionBodyLi">${
                    appearance.en ? appearance.en : appearance.translated.en
                  }</li>`
              )}
          </ul></div>`
        }
      </section>
      <section class="section">
        <h1 class="sectionTitle">Payment</h1>
        ${
          payment.length > 0 &&
          ` <div class='subsectionBody'><ul class="subsectionBodyUl">
                ${payment
                  .filter((v: any) => v.status !== 'disable')
                  .map(
                    (payment) =>
                      `<li key=${payment.id} class="subsectionBodyLi">${
                        payment.en ? payment.en : payment.translated.en
                      }</li>`
                  )}
              </ul></div>`
        }
      </section>
      <section class="images">
        <ul class="imagesUl">
          ${images?.map(
            (image, index) =>
              `<li key=${image.originalFileName} class="imagesLi"><img src="${
                imageFormat === 'webp' ? image.thumb1920url + '.webp' : image.thumb1920url + '.jpeg'
              }" alt="${image.alt ? image.alt : name?.translated?.en + '-' + (index += 1)}" class="imagesItem"/></li>`
          )}
        </ul>
      </section>
    </article>
  `;

  // 改行を削除
  sourceCode = sourceCode.replace(/\r?\n/g, '');

  // タグ間の,を削除（その他の空白は削除しない）
  sourceCode = sourceCode.replace(/>,+</g, '><');

  // タグ間の空白を削除（その他の空白は削除しない）
  sourceCode = sourceCode.replace(/>\s+</g, '><');

  return { sourceCode };
};
