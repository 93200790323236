import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'Auth/redux/slice';
import { useError } from './useError';
import { useAnchorEl } from './useAnchorEl';
import { useAuth } from 'contexts/AuthContext';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Popover,
  Button,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Divider,
  Alert,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

type Props = {
  page?: string;
};

export const AccountDialog: FC<Props> = ({ page }) => {
  const { anchorEl, handleClick, handleClose, id, isOpen } = useAnchorEl();
  const { error } = useError();
  const { currentUser } = useAuth();

  const dispatch = useDispatch();

  return (
    <>
      {currentUser?.photoURL ? (
        <Button onClick={handleClick}>
          <Avatar src={currentUser.photoURL} alt='Avatar' />
        </Button>
      ) : (
        <Avatar>
          <Button onClick={handleClick}>
            <PersonIcon />
          </Button>
        </Avatar>
      )}
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 60,
          horizontal: 50,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component='div'>
          <ListItemButton component={Link} to='/settings' onClick={handleClose}>
            <ListItemAvatar>
              {currentUser?.photoURL ? (
                <Avatar alt='Avatar' src={currentUser.photoURL} />
              ) : (
                <Avatar>
                  <PersonIcon />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText primary={currentUser && currentUser.email} />
            {error && <Alert>{error}</Alert>}
          </ListItemButton>
          <ListItemButton onClick={() => dispatch(logout())} sx={{ textAlign: 'center' }}>
            <ListItemText primary='ログアウト' />
          </ListItemButton>
          <Divider />
          {page === 'checkout' && (
            <ListItemButton component={Link} to='/account/unsubscribe/' sx={{ textAlign: 'center' }}>
              <ListItemText primary='アカウントを削除' />
            </ListItemButton>
          )}
        </List>
      </Popover>
    </>
  );
};
