import { useState } from 'react';
import { ConfirmDialog } from 'Product/DeleteProduct/ConfirmDialog';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export const DeleteProduct = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Tooltip title='商品を削除' aria-label='delete'>
      <IconButton>
        <DeleteIcon onClick={() => setIsDialogOpen(true)} />
        <ConfirmDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
      </IconButton>
    </Tooltip>
  );
};
