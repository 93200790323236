import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateColor = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedColor: string;
  }
>('product/updateColor', async ({ uid, productId, selectedColor }) => {
  const docRef = db.collection('users').doc(uid).collection('products').doc(productId);

  await docRef
    .update({
      color: selectedColor,
    })
    .then(() => {
      console.log('Document successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating document: ', error);
    });

  return {
    color: selectedColor,
  };
});
