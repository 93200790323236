import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { productsEntitiesSelector } from 'Products/redux/selector';
import { Box, Card, CardContent, CardMedia, CardActionArea, Grid, Typography } from '@mui/material';

export const CardGrid: FC = () => {
  const products = useSelector(productsEntitiesSelector);

  return (
    <>
      <Grid container spacing={2}>
        {products.map((product: any) => (
          <Grid item key={product?.id} xs={12} sm={6} md={4} lg={4}>
            <Card sx={{ height: '100%' }}>
              <CardActionArea sx={{ '&>a': { color: 'inherit', textDecoration: 'none' } }}>
                <Link
                  key={product?.id}
                  to={{
                    pathname: `/product/${product?.id}`,
                    state: {
                      id: product?.id,
                    },
                  }}
                >
                  {product?.cover?.thumb960url ? (
                    <CardMedia
                      image={product?.cover?.thumb960url + '.webp'}
                      sx={{
                        // paddingTop: '56.25%', // 16:9
                        paddingTop: '66.67%', // 3:2
                        // paddingTop: '75%', // 4:3
                      }}
                    />
                  ) : (
                    <Box sx={{ opacity: 0.75 }}>
                      <CardMedia
                        image='https://storage.googleapis.com/cloudpix-f082f.appspot.com/public/dummy-image_480x480_480x480.webp'
                        title='image not available'
                        sx={{
                          // paddingTop: '56.25%', // 16:9
                          paddingTop: '66.67%', // 3:2
                          // paddingTop: '75%', // 4:3
                        }}
                      />
                    </Box>
                  )}
                  <CardContent>
                    <Typography variant='body1' component='h2' sx={{ wordBreak: 'break-word' }}>
                      {product?.name?.input}
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        {/* <Grid item xs={12} sm={6} md={6} lg={4} className={classes.addGrid}>
            <Card className={classes.card}>
                <CreateDialog component='product' module='grid' />
            </Card>
        </Grid> */}
      </Grid>
    </>
  );
};
