import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getOptions = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    section: string;
    subSection: string;
    selectedCategoryId?: string;
  }
>('templates/getOptions', async ({ uid, selectedVender, section, subSection, selectedCategoryId }) => {
  let docsRef;
  let res: firebase.firestore.DocumentData[] = [];

  switch (section) {
    case 'categories':
      docsRef = db
        .collection('users')
        .doc(uid)
        .collection('templates')
        .doc(selectedVender)
        .collection('categories')
        .doc(selectedCategoryId)
        .collection(subSection)
        .orderBy('order');
      break;
    case 'payment':
      docsRef = db
        .collection('users')
        .doc(uid)
        .collection('templates')
        .doc(selectedVender)
        .collection(section)
        .orderBy('order');
      break;
    default:
      break;
  }

  if (docsRef) {
    await docsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        res.push({ id: doc.id, ...doc.data() });
      });
    });
  }

  return { res: res, selectedVender: selectedVender, section: section, subSection: subSection };
});
