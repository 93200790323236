import { createSelector } from 'reselect';
import { RootState } from 'store';
import { ProductState } from 'Product/redux/slice';

export const productSelector: (state: RootState) => ProductState = (state: RootState) => state.product;

export const productEntitiesSelector = createSelector(productSelector, (product) => {
  return product.entities;
});

export const nameSelector = createSelector(productSelector, (product) => {
  return product.name;
});

export const imagesSelector = createSelector(productSelector, (product) => {
  return product.images;
});

export const gradesSelector = createSelector(productSelector, (product) => {
  return product.grades;
});

export const appearancesSelector = createSelector(productSelector, (product) => {
  return product.appearances;
});

export const paymentSelector = createSelector(productSelector, (product) => {
  return product.payment;
});

export const colorSelector = createSelector(productSelector, (product) => {
  return product.color;
});

// export const ebayAppearancesSelector = createSelector(productSelector, (product) => {
//   return product.entities.ebay.ebayAppearances;
// });
