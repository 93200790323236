import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { functions } from 'app/firebase';

export const deleteAtPath = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    path: string;
    productId?: any;
  }
>('product/deleteAtPath', async ({ path, productId }) => {
  /**
   * Call the 'recursiveDelete' callable function with a path to initiate
   * a server-side delete.
   */
  const deleteFn = functions.httpsCallable('recursiveDelete');
  await deleteFn({ path: path })
    .then(function (result) {
      console.log('Delete success: ' + JSON.stringify(result));
    })
    .catch(function (err) {
      console.warn(err);
    });

  return {
    id: productId,
  };
});
