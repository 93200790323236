import { FC } from 'react';
import { useSelector } from 'react-redux';
import { imagesSelector } from 'Product/redux/selector';
import { productEntitiesSelector } from 'Product/redux/selector';
import { SelectColor } from './SelectColor';
import { Ebay01 } from './Ebay01';
import { Typography, Box, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  colorTheme:
    | {
        text: string;
        background: string;
      }
    | undefined;
};

export const Preview: FC<Props> = ({ colorTheme }) => {
  const images = useSelector(imagesSelector);
  const product = useSelector(productEntitiesSelector);

  return (
    <Box component='section' sx={{ mt: 5, mb: 5 }}>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        プレビュー
      </Typography>
      {images.length && product.categoryPrimary ? (
        <>
          <Typography component='h1' variant='h4' paragraph sx={{ mt: 5 }}>
            ebay
          </Typography>
          <SelectColor />
          <Ebay01 colorTheme={colorTheme} />
        </>
      ) : (
        <>
          {!images.length && (
            <Stack direction='row' spacing={1} sx={{ mb: 1 }}>
              <ErrorOutlineIcon />
              <Typography>この商品には、まだ画像が存在しません</Typography>
            </Stack>
          )}
          {!product.categoryPrimary && (
            <Stack direction='row' spacing={1} sx={{ mb: 1 }}>
              <ErrorOutlineIcon />
              <Typography>この商品は、まだカテゴリーが選択されていません</Typography>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};
