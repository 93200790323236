import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { getUser } from 'User/firebase/getUser';
import { getSubscriptions } from 'User/firebase/getSubscriptions';
import { updateMode } from 'User/firebase/updateMode';
import { updateImageFormat } from 'Settings/Templates/firebase/updateImageFormat';

export interface UserState {
  entities: firebase.firestore.DocumentData;
  // user: {
  //   id: string;
  //   created: {};
  //   imageFormat: 'webp'|'jpeg';
  //   fileSize: number;
  //   isDarkmode: boolean;
  //   stripeId: string;
  //   stripeLink: string;
  //   imageCount: number;
  //   email: string;
  //   subscriptions: any[];
  // };
  status: {
    getUser: string | null;
    getSubscriptions: string | null;
    updateMode: string | null;
    updateImageFormat: string | null;
  };
}

const initialState: UserState = {
  entities: {
    id: '',
    created: {},
    imageFormat: 'webp',
    fileSize: 0,
    mode: 'light',
    stripeId: '',
    stripeLink: '',
    imageCount: 0,
    email: '',
    subscriptions: [],
  },
  status: {
    getUser: null,
    getSubscriptions: null,
    updateMode: null,
    updateImageFormat: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLightmode: (state) => {
      state.entities.isDarkmode = false;
    },
  },
  // 別で作成しておいたactionに対するreducer。外部のactionに対するreducer関数を作成しているので、extraReducersからはactionが自動的に生成されない。
  extraReducers: (builder) => {
    builder
      // ユーザー情報を全て取得
      .addCase(getUser.pending, (state) => {
        state.status.getUser = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status.getUser = 'success';
        state.entities = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status.getUser = 'failed';
        console.log('action.error', action.error);
      })

      // 決済情報を取得
      .addCase(getSubscriptions.pending, (state) => {
        state.status.getSubscriptions = 'loading';
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.status.getSubscriptions = 'success';
        state.entities = { ...state.entities, subscriptions: { ...action.payload } };
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.status.getSubscriptions = 'failed';
        console.log('action.error', action.error);
      })

      // カラーモードを更新
      .addCase(updateMode.pending, (state) => {
        state.status.updateMode = 'loading';
      })
      .addCase(updateMode.fulfilled, (state, action) => {
        state.status.updateMode = 'success';
        state.entities = { ...state.entities, mode: action.payload.newMode };
      })
      .addCase(updateMode.rejected, (state, action) => {
        state.status.updateMode = 'failed';
        console.log('action.error', action.error);
      })

      // 画像ファイル形式を更新
      .addCase(updateImageFormat.pending, (state) => {
        state.status.updateImageFormat = 'loading';
      })
      .addCase(updateImageFormat.fulfilled, (state, action) => {
        state.status.updateImageFormat = 'success';
        state.entities = { ...state.entities, imageFormat: action.payload.imageFormat };
      })
      .addCase(updateImageFormat.rejected, (state, action) => {
        state.status.updateImageFormat = 'failed';
        console.log('action.error', action.error);
      });
    // .addMatcher(
    //   (action) => action.type.endsWith('/rejected'),
    //   (state, action) => {
    //     state[action.meta.requestId] = 'rejected';
    //   }
    // )
    // .addDefaultCase((state, action) => {
    //   state.otherActions++;
    // });
  },
});

// Later, dispatch the thunk as needed in the app
// dispatch(fetchUserById(123))

// Action creators are generated for each case reducer function
export const { setLightmode } = userSlice.actions;
export default userSlice.reducer;
