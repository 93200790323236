import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { getProducts } from 'Products/firebase/getProducts';
import { addProduct } from 'Products/firebase/addProduct';
import { updateOrderUp } from 'Product/firebase/updateOrderUp';

export interface ProductsState {
  entities: firebase.firestore.DocumentData[];
  isDocEmpty: boolean;
  lastDoc: number | undefined;
  status: string | null;
}

const initialState: ProductsState = {
  entities: [],
  isDocEmpty: false,
  lastDoc: undefined,
  status: null,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateCover: (state, action) => {
      const targetProduct = state.entities.find((v) => v.id === action.payload.productId);
      if (targetProduct) {
        targetProduct.cover = {
          originalFileName: action.payload.originalFileName,
          originalFileSize: action.payload.originalFileSize,
          thumb1920url: action.payload.thumb1920url,
          thumb960url: action.payload.thumb960url,
          thumb480url: action.payload.thumb480url,
          square1920url: action.payload.square1920url,
          square960url: action.payload.square960url,
          square480url: action.payload.square480url,
          createdAt: action.payload.createdAt,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // 全商品を取得（limitあり）
      .addCase(getProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.status = 'success';
        switch (action.payload.fetchMore) {
          case true:
            state.entities = [...state.entities, ...action.payload.res];
            break;
          case false:
            state.entities = action.payload.res;
            break;
          default:
            break;
        }
        state.isDocEmpty = action.payload.isDocEmpty;
        state.lastDoc = action.payload.lastDoc;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      // 新しい商品を追加
      .addCase(addProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = 'success';
        state.entities = [...state.entities, { id: action.payload.resId, name: { input: action.payload.newName } }];
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      })

      //
      .addCase(updateOrderUp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderUp.fulfilled, (state, action) => {
        state.status = 'success';
      })
      .addCase(updateOrderUp.rejected, (state, action) => {
        state.status = 'failed';
        console.log('action.error', action.error);
      });

    // .addMatcher(
    //   (action) => action.type.endsWith('/rejected'),
    //   (state, action) => {
    //     state[action.meta.requestId] = 'rejected';
    //   }
    // )
    // .addDefaultCase((state, action) => {
    //   state.otherActions++;
    // });
  },
});

// Later, dispatch the thunk as needed in the app
// dispatch(fetchUserById(123))

// Action creators are generated for each case reducer function
export const { updateCover } = productsSlice.actions;

export default productsSlice.reducer;
