import { FC, useMemo } from 'react';
import { useSelector } from 'store';
import { modeSelector } from 'User/redux/selector';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

export const MuiThemeProvider: FC = ({ children }) => {
  const mode = useSelector(modeSelector);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                // palette values for light mode
              }
            : {
                // palette values for dark mode
                background: {
                  paper: '#13192c',
                  default: '#010203',
                },
              }),
        },
        typography: {
          fontSize: 13,
          body1: {
            lineHeight: 1.8,
          },
        },
      }),
    [mode]
  );

  // Global -----
  const useStyles = makeStyles({
    '@global': {
      body: {
        lineHeight: 1.8,
      },
    },
  });

  const GlobalStyles = () => {
    useStyles();
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};
