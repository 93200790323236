import { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { useHistory } from 'react-router-dom';

export const useError = () => {
    const [error, setError] = useState('');
    const { signOut } = useAuth();
    const history = useHistory();

    const handleLogout = async () => {
        setError('');
        try {
            await signOut();
            history.push('/signin');
        } catch {
            setError('Failed to log out');
        }
    };

    return { error, handleLogout };
};
