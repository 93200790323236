import { FC } from 'react';
import { useSelector } from 'react-redux';
import { imagesSelector } from 'Product/redux/selector';
import { imageFormatSelector } from 'User/redux/selector';
import { productEntitiesSelector } from 'Product/redux/selector';
import { CodeToClipboard } from 'Product/SourceCode/CodeToClipboard';
import { Ebay01 } from 'Product/SourceCode/ebay/01';
import { Typography, Box, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  colorTheme:
    | {
        text: string;
        background: string;
      }
    | undefined;
};

export const SourceCode: FC<Props> = ({ colorTheme }) => {
  const images = useSelector(imagesSelector);
  const imageFormat = useSelector(imageFormatSelector);
  const product = useSelector(productEntitiesSelector);
  const { sourceCode } = Ebay01({ colorTheme });

  console.log('product.categoryPrimary ', product.categoryPrimary);

  return (
    <Box component='section' sx={{ mt: 5, mb: 5 }}>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        出品用ソースコード
      </Typography>
      {images.length && product.categoryPrimary ? (
        <>
          <Box>このコードをeBayの「Item description」欄に貼り付けてください。</Box>
          <Typography variant='caption' sx={{ color: (theme) => theme.palette.grey[500] }}>
            ※ 入力欄上部のタブを「HTML」に切り替えてから貼り付けます
            <br />※ アイコンをクリックするとクリップボードにコピーします
          </Typography>
          <Box sx={{ mt: 2 }}>
            <CodeToClipboard fullWidth textData={sourceCode} />
            <Typography variant='body2' sx={{ mt: 1, ml: 1, color: (theme) => theme.palette.grey[500] }}>
              {imageFormat}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {!images.length && (
            <Stack direction='row' spacing={1} sx={{ mb: 1 }}>
              <ErrorOutlineIcon />
              <Typography>この商品には、まだ画像が存在しません</Typography>
            </Stack>
          )}
          {!product.categoryPrimary && (
            <Stack direction='row' spacing={1} sx={{ mb: 1 }}>
              <ErrorOutlineIcon />
              <Typography>この商品は、まだカテゴリーが選択されていません</Typography>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};
