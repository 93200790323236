import { FC } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MessageIcon from '@mui/icons-material/Message';

export const Footer: FC = () => {
  return (
    <Box sx={{ mt: 'auto' }}>
      <List>
        <ListItem disableGutters sx={{ p: 0 }}>
          <ListItemButton component='a' href='https://cloudpix.page/help' target='_blank' rel='noreferrer'>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary='ヘルプ' />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters sx={{ p: 0 }}>
          <ListItemButton component='a' href='https://cloudpix.page/terms-of-service' target='_blank' rel='noreferrer'>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary='ご利用規約' />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters sx={{ p: 0 }}>
          <ListItemButton component='a' href='https://cloudpix.page/contact' target='_blank' rel='noreferrer'>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary='お問い合わせ' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};
