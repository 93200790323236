import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const addProduct = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    newName: string;
  }
>('product/addProduct', async ({ uid, newName }) => {
  let resId: string = '';
  const docRef = db.collection('users').doc(uid).collection('products');

  // 新しい商品を追加
  await docRef
    .add({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((res) => {
      resId = res.id;
      console.log('Document written with ID: ', resId);
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
    });

  // 商品名をセット
  await docRef
    .doc(resId)
    .collection('name')
    .doc('doc')
    .set({
      input: newName,
    })
    .then(() => {
      console.log('Document successfully written!');
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
    });

  return {
    id: resId,
    newName: newName,
  };
});
