import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateInputEn = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    section: string;
    subSection: string;
    selectedCategoryId?: string;
    optionId: string;
    inputEn: string;
  }
>(
  'templates/updateInputEn',
  async ({ uid, section, subSection, selectedVender, selectedCategoryId, optionId, inputEn }) => {
    let docRef;

    switch (section) {
      case 'categories':
        docRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection('categories')
          .doc(selectedCategoryId)
          .collection(subSection)
          .doc(optionId);
        break;
      case 'payment':
        docRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection(section)
          .doc(optionId);
        break;

      default:
        break;
    }

    if (docRef) {
      await docRef.update({
        inputEn: inputEn,
      });
    }

    return { section: section, subSection: subSection, optionId: optionId, inputEn: inputEn };
  }
);
