import { FC, useEffect } from 'react';
import { AppDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { getUser } from 'User/firebase/getUser';
import { getSubscriptions } from 'User/firebase/getSubscriptions';
import { isUserAuthenticatedSelector } from 'Auth/redux/selector';
import { uidSelector } from 'Auth/redux/selector';

export const User: FC = ({ children }: any) => {
  const authenticated = useSelector(isUserAuthenticatedSelector);
  const uid = useSelector(uidSelector);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (authenticated && uid) {
      // DBからuserコレクションを取得
      dispatch(getUser({ uid }));

      // DBからuserコレクションのsubscriptionsを取得
      dispatch(getSubscriptions({ uid }));
    }
  }, [authenticated, dispatch, uid]);

  return <>{children}</>;
};
