import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { isDocEmptySelector } from 'Products/redux/selector';
import { lastDocSelector } from 'Products/redux/selector';
import { addProduct } from 'Products/firebase/addProduct';
import { getProducts } from 'Products/firebase/getProducts';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, TextField, Button } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

export const AddProduct = () => {
  const dispatch = useDispatch();
  const uid = useSelector(uidSelector);
  const isDocEmpty = useSelector(isDocEmptySelector);
  const lastDoc = useSelector(lastDocSelector);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');

  // 商品名input
  const onNewNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  // 「作成する」がクリックされたら処理を開始
  const handleCreateProduct = () => {
    if (!newName) {
      return;
    }
    dispatch(addProduct({ uid, newName }));
    setTimeout(function () {
      dispatch(getProducts({ uid, limit: 18, isDocEmpty, lastDoc, fetchMore: false }));
    }, 1000);

    // ダイアログを閉じる
    setIsDialogOpen(false);
    setNewName('');
  };

  return (
    <>
      <Tooltip title='新しい商品を作成' aria-label='add'>
        <IconButton onClick={() => setIsDialogOpen(true)}>
          <AddToPhotosIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        keepMounted
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-paper': {
            p: 2,
          },
        }}
      >
        <DialogTitle id='alert-dialog-slide-title'>{'新しい商品を作成'}</DialogTitle>
        <DialogContent>
          <TextField fullWidth label='商品名' value={newName} onChange={onNewNameChange} type='text' />
          <Button
            variant='outlined'
            size='large'
            startIcon={<SaveAltIcon />}
            onClick={handleCreateProduct}
            sx={{ mt: 2 }}
          >
            作成する
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
