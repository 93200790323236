import { FC, useEffect } from 'react';
import { AppDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
// import { useSelector } from 'store';
import { getProducts } from 'Products/firebase/getProducts';
import { isUserAuthenticatedSelector } from 'Auth/redux/selector';
import { subscriptionsSelector } from 'User/redux/selector';
import { uidSelector } from 'Auth/redux/selector';
import { isDocEmptySelector } from 'Products/redux/selector';
import { lastDocSelector } from 'Products/redux/selector';

export const GetProducts: FC = ({ children }: any) => {
  const uid = useSelector(uidSelector);
  const authenticated = useSelector(isUserAuthenticatedSelector);
  const subscriptions = useSelector(subscriptionsSelector);
  const isDocEmpty = useSelector(isDocEmptySelector);
  const lastDoc = useSelector(lastDocSelector);
  const dispatch: AppDispatch = useDispatch();

  let subscriptionStatus = '';
  if (subscriptions && subscriptions[0]) {
    subscriptionStatus = subscriptions[0].status;
  }

  useEffect(() => {
    if (authenticated && uid && (subscriptionStatus === 'active' || subscriptionStatus === 'trialing')) {
      // productsを取得
      dispatch(getProducts({ uid, limit: 18, isDocEmpty, lastDoc, fetchMore: false }));
    }
  }, [authenticated, dispatch, subscriptionStatus, uid]);

  return <>{children}</>;
};
