import { createSelector } from 'reselect';
import { RootState } from 'store';
import { ComponentsState } from 'components/redux/slice';

export const componentsSelector: (state: RootState) => ComponentsState = (state: RootState) => state.components;

export const typeSelector = createSelector(componentsSelector, (components) => {
  return components.type;
});

export const pageSelector = createSelector(componentsSelector, (components) => {
  return components.page;
});

export const isSidebarHiddenSelector = createSelector(componentsSelector, (components) => {
  return components.isSidebarHidden;
});
