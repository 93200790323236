import { FC } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store';
import { AuthProvider } from 'contexts/AuthContext';
import { StripeProvider } from 'Payment/StripeProvider';
import { MuiThemeProvider } from 'contexts/ThemeContext';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import { Auth } from 'Auth';
import { User } from 'User';
// import { GetData } from 'getData/GetData';
import { Layout } from 'components/Layout';
// import { ImagesContainer } from '_pages/images';
// import { ImageContainer } from '_pages/image';
import { Settings } from 'Settings';
import { Products } from 'Products';
import { Product } from 'Product';
// import { ProductContainer } from '_pages/product';
// import { DevProductContainer } from '_pages/_dev/product';
// import { ImageSetToProductsContainer } from '_pages/image/setToProducts';
// import { ProductSetImagesContainer } from '_pages/product/setImages';
// import { DashboardContainer } from '_pages/dashboard';
import { Dashboard } from 'Dashboard';
// import { SignUp } from '_modules/SignUp';
// import { SignIn } from '_modules/SignIn';
import { SignUp } from 'Auth/SignUp';
import { SignIn } from 'Auth/SignIn';
import { PrivateRoute } from 'Auth/PrivateRoute';
// import { PrivateRouteActive } from 'components/_PrivateRouteActive';
import { ForgotPassword } from 'Auth/ForgotPassword';
// import { SettingsContainer } from '_pages/account/settings';
// import { DevSettingsContainer } from '_pages/_dev/account/settings';
import { Checkout } from 'Payment/Checkout';
// import { Checkout } from 'settings/Account/checkout';
// import { UnsubscribeContainer } from '_pages/account/unsubscribe';
import { Unsubscribe } from 'Settings/Account/Unsubscribe';
// import { ReRederProvider } from 'contexts/ReRederContext';
// import { ProductsProvider } from 'contexts/ProductsContext';
import { Page404 } from '404';
// import { Page404Container } from '_pages/404';

const App: FC = () => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <AuthProvider>
          <StripeProvider>
            <MuiThemeProvider>
              {/* <DndProvider backend={HTML5Backend}> */}
              {/* <ReRederProvider> */}
              {/* <ProductsProvider> */}
              <Auth>
                <User>
                  <Layout>
                    <Switch>
                      <Route exact path='/signup' component={SignUp} />
                      <Route exact path='/signin' component={SignIn} />
                      <Route exact path='/settings/account/unsubscribe' component={Unsubscribe} />
                      {/* <Route exact path='/settings/account/checkout' component={CheckoutContainer} /> */}

                      <PrivateRoute exact path='/' component={Dashboard} />
                      <PrivateRoute exact path='/settings' component={Settings} />
                      <PrivateRoute exact path='/products' component={Products} />
                      <PrivateRoute path='/product/:productId' component={Product} />

                      {/* <GetData>
                        <Route exact path='/' component={Dashboard} />
                        <Route exact path='/settings' component={Settings} />
                        <Route exact path='/products' component={Products} />
                        <Route path='/product/:productId' component={Product} />
                      </GetData> */}

                      {/* <PrivateRouteActive path='/' component={DashboardContainer} exact={true} /> */}
                      {/* <PrivateRouteActive path='/images' component={ImagesContainer} />
                        <PrivateRouteActive path='/image/setToProducts' component={ImageSetToProductsContainer} /> */}
                      {/* <PrivateRouteActive path='/image/:image' component={ImageContainer} /> */}
                      {/* <PrivateRouteActive path='/products' component={Products} /> */}
                      {/* <PrivateRouteActive path='/product/setImages' component={ProductSetImagesContainer} /> */}
                      {/* <PrivateRouteActive path='/product/:product' component={ProductContainer} /> */}
                      {/* <PrivateRouteActive path='/_dev/product/:product' component={DevProductContainer} /> */}
                      {/* <PrivateRouteActive path='/account/settings' component={SettingsContainer} /> */}
                      {/* <PrivateRouteActive path='/_dev/account/settings' component={DevSettingsContainer} /> */}
                      {/* <Route path='/account/unsubscribe' component={UnsubscribeContainer} /> */}
                      {/* <Route path='/signupOld' component={SignUp} /> */}
                      {/* <Route exact path='/signinOld' component={SignIn} /> */}
                      <Route exact path='/forgot-password' component={ForgotPassword} />
                      <Route exact path='/account/checkout' component={Checkout} />

                      {/* <Route component={Page404Container} /> */}
                      <Route component={Page404} />
                    </Switch>
                  </Layout>
                </User>
              </Auth>
              {/* </ProductsProvider> */}
              {/* </ReRederProvider> */}
              {/* </DndProvider> */}
            </MuiThemeProvider>
          </StripeProvider>
        </AuthProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default App;
