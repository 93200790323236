import { FC } from 'react';
import { Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';

export const TableHeader: FC = () => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: '12%', px: 1 }}></TableCell>
          <TableCell sx={{ width: '37%', px: 1 }}>
            <Typography variant='caption'>日本語</Typography>
          </TableCell>
          <TableCell sx={{ width: '37%', px: 1 }}>
            <Typography variant='caption'>英語</Typography>
          </TableCell>
          <TableCell sx={{ width: '14%', px: 1 }}></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
