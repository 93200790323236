import { FC, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { productEntitiesSelector } from 'Product/redux/selector';
// import { Images } from './Images';
import { TableHeader } from './TableHeader';
import { Name } from './Name';
import { Vender } from './Vender';
import { Category } from './Category';
import { Section } from './Section';
import { SelectOptions } from './SelectOptions';
import { Options } from './Options';
import { Box } from '@mui/material';

type Props = {
  selectedVender: string;
  setSelectedVender: Dispatch<SetStateAction<string>>;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
};

export const Settings: FC<Props> = ({ selectedVender, setSelectedVender, selectedCategory, setSelectedCategory }) => {
  const product = useSelector(productEntitiesSelector);

  return (
    <>
      {/* <Images /> */}
      {/* <Section title='基本情報'> */}
      <Box sx={{ mt: 5, mb: 9 }}>
        <Vender selectedVender={selectedVender} setSelectedVender={setSelectedVender} />
        <Category selectedVender={selectedVender} setSelectedCategory={setSelectedCategory} />
        <Name />
      </Box>
      {/* </Section> */}
      {product.categoryPrimary && (
        <>
          <Section title='商品説明'>
            <TableHeader />
            <SelectOptions
              title='グレード'
              selectedVender={selectedVender}
              section='categories'
              subSection='grades'
              selectedCategory={selectedCategory}
            />
            <Options
              title='外観'
              selectedVender={selectedVender}
              section='categories'
              subSection='appearances'
              selectedCategory={selectedCategory}
            />
          </Section>
          <Section title='支払い方法'>
            <TableHeader />
            <Options
              title='支払い方法'
              selectedVender={selectedVender}
              section='payment'
              subSection=''
              selectedCategory={selectedCategory}
            />
          </Section>
        </>
      )}
    </>
  );
};
