import { FC, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { colorSelector } from 'Product/redux/selector';
import { updateColor } from 'Product/firebase/updateColor';
import { useParams } from 'react-router-dom';
import { Radio, Typography, Stack, Box } from '@mui/material';

export const SelectColor: FC = () => {
  const uid = useSelector(uidSelector);
  const selectedColor = useSelector(colorSelector);
  const { productId } = useParams<{ productId: string }>();
  const dispatch = useDispatch();

  const handleChangeColor = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateColor({
        uid: uid,
        productId: productId,
        selectedColor: event.target.value,
      })
    );
  };

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <Box>
        <Typography>カラー</Typography>
      </Box>
      <Box>
        <Radio
          checked={selectedColor === '888888'}
          onChange={handleChangeColor}
          value='888888'
          inputProps={{ 'aria-label': '888888' }}
          style={{ color: '#888888' }}
        />
        <Radio
          checked={selectedColor === 'A5977A'}
          onChange={handleChangeColor}
          value='A5977A'
          inputProps={{ 'aria-label': 'A5977A' }}
          style={{ color: '#A5977A' }}
        />
        <Radio
          checked={selectedColor === '1EC0B8'}
          onChange={handleChangeColor}
          value='1EC0B8'
          inputProps={{ 'aria-label': '1EC0B8' }}
          style={{ color: '#1EC0B8' }}
        />
        <Radio
          checked={selectedColor === 'BAB509'}
          onChange={handleChangeColor}
          value='BAB509'
          inputProps={{ 'aria-label': 'BAB509' }}
          style={{ color: '#BAB509' }}
        />
        <Radio
          checked={selectedColor === 'FD5569'}
          onChange={handleChangeColor}
          value='FD5569'
          inputProps={{ 'aria-label': 'FD5569' }}
          style={{ color: '#FD5569' }}
        />
        <Radio
          checked={selectedColor === '619B3B'}
          onChange={handleChangeColor}
          value='619B3B'
          inputProps={{ 'aria-label': '619B3B' }}
          style={{ color: '#619B3B' }}
        />
      </Box>
    </Stack>
  );
};
