import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';
import { DropZoneFiles } from 'types';
import { buildThumbUrls } from 'components/buildThumbUrls';

export const addImages = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; files: DropZoneFiles[]; images: firebase.firestore.DocumentData[]; productId: string }
>('product/addImages', async ({ uid, files, images, productId }) => {
  let res: any[] = [];

  // ファイルを名前順にソートする
  const sortedFiles = [...files].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

  // StorageのDownloadURLからStoreに登録する情報を取得し加工する
  await Promise.all(
    sortedFiles.map(async (file, index) => {
      // thumbnailのURLを作成
      const { thumb480url, thumb960url, thumb1920url, square480url, square960url, square1920url } = buildThumbUrls({
        uid: uid,
        productId: productId,
        originalFileName: file.name,
      });

      // DB/imagesに画像情報を追加
      const docsRef = db.collection('users').doc(uid).collection('products').doc(productId).collection('images');

      let length: number;
      if (images.length > 0) {
        length = images.length;
      } else {
        length = 0;
      }

      await docsRef
        .add({
          originalFileName: file.name,
          originalFileSize: file.size,
          thumb480url: thumb480url,
          thumb960url: thumb960url,
          thumb1920url: thumb1920url,
          square480url: square480url,
          square960url: square960url,
          square1920url: square1920url,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          order: length + index,
        })
        .then((docRef) => {
          // Redux State登録用データ
          res.push({
            id: docRef.id,
            originalFileName: file.name,
            originalFileSize: file.size,
            thumb480url: thumb480url,
            thumb960url: thumb960url,
            thumb1920url: thumb1920url,
            square480url: square480url,
            square960url: square960url,
            square1920url: square1920url,
            // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            order: length + index,
          });
        })
        .catch(() => {
          console.log('DB Error');
        });
    })
  );

  return { res: res };
});
