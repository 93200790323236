import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const deleteOption = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedVender: string;
    section: string;
    subSection: string;
    currentOptionId: string;
    optionOrder: number;
    selectedCategory: string;
  }
>(
  'product/deleteOption',
  async ({ uid, productId, selectedVender, section, subSection, currentOptionId, optionOrder, selectedCategory }) => {
    let docsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

    switch (section) {
      case 'images':
        docsRef = db.collection('users').doc(uid).collection('products').doc(productId).collection('images');
        break;
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection);
        break;
      default:
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section);
        break;
    }

    // orderが削除するdocより大きいdocIdを取得する
    let biggerOrderDocIds: string[] = [];
    await docsRef
      .where('order', '>', optionOrder)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          biggerOrderDocIds.push(doc.id);
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });

    // orderを-1する
    if (biggerOrderDocIds.length > 0) {
      await Promise.all(
        biggerOrderDocIds.map(async (biggerOrderDocId) => {
          await docsRef.doc(biggerOrderDocId).update({
            order: firebase.firestore.FieldValue.increment(-1),
          });
        })
      );
    }

    // // DBから選択肢のdocを削除する
    await docsRef
      .doc(currentOptionId)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });

    return {
      selectedVender: selectedVender,
      section: section,
      subSection: subSection,
      currentOptionId: currentOptionId,
      selectedCategory: selectedCategory,
      biggerOrderDocIds: biggerOrderDocIds,
    };
  }
);
