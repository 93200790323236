import { FC, ChangeEvent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { nameSelector } from 'Product/redux/selector';
import { isDocEmptySelector } from 'Products/redux/selector';
import { lastDocSelector } from 'Products/redux/selector';
import { getName } from 'Product/firebase/getName';
import { updateInputJa } from 'Product/firebase/updateInputJa';
import { updateInputEn } from 'Product/firebase/updateInputEn';
import { getProducts } from 'Products/firebase/getProducts';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Stack, ClickAwayListener, Tooltip, Input, Typography } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CreateIcon from '@mui/icons-material/Create';

export const Name: FC = () => {
  const uid = useSelector(uidSelector);
  const name = useSelector(nameSelector);
  const isDocEmpty = useSelector(isDocEmptySelector);
  const lastDoc = useSelector(lastDocSelector);
  const [isInputJaActive, setIsInputJaActive] = useState(false);
  const [isInputEnActive, setIsInputEnActive] = useState(false);
  const [newInputJa, seNewInputJa] = useState(name.input);
  const [newInputEn, seNewInputEn] = useState(name.inputEn ? name.inputEn : name?.translated?.en);
  const { productId } = useParams<{ productId: string }>();
  const dispatch = useDispatch();

  // フォームの状態管理
  const handleNewInputJaChange = (e: ChangeEvent<HTMLInputElement>) => {
    seNewInputJa(e.target.value);
  };
  const handleNewInputEnChange = (e: ChangeEvent<HTMLInputElement>) => {
    seNewInputEn(e.target.value);
  };

  // 日本語を変更
  const handleUpdateInputJa = () => {
    dispatch(
      updateInputJa({
        uid: uid,
        productId: productId,
        newInputJa: newInputJa,
        section: 'name',
        selectedVender: '',
        subSection: '',
        currentOptionId: '',
        selectedCategory: '',
      })
    );
    dispatch(getName({ uid, productId: productId }));
    dispatch(getProducts({ uid, limit: 18, isDocEmpty, lastDoc, fetchMore: false }));
  };
  // 英訳を変更
  const handleUpdateInputEn = () => {
    dispatch(
      updateInputEn({
        uid: uid,
        productId: productId,
        newInputEn: newInputEn,
        section: 'name',
        selectedVender: '',
        subSection: '',
        currentOptionId: '',
        selectedCategory: '',
      })
    );
    dispatch(getName({ uid, productId: productId }));
  };

  return (
    <Box component='section'>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        商品名
      </Typography>

      {/* Japanese */}
      <Box sx={{ ml: 1, mb: 2 }}>
        <Typography variant='caption' sx={{ width: '180px', whiteSpace: 'nowrap' }}>
          日本語（管理用）
        </Typography>
        {isInputJaActive ? (
          // 入力フォームを表示
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
            <ClickAwayListener onClickAway={() => setIsInputJaActive(false)}>
              <Input
                placeholder='日本語'
                defaultValue={name.input}
                value={newInputJa}
                onChange={handleNewInputJaChange}
                fullWidth
                multiline
                sx={{ my: 1 }}
              />
            </ClickAwayListener>
            <Box>
              <Tooltip title='保存する'>
                <IconButton aria-label='save'>
                  <SaveAltIcon onClick={() => handleUpdateInputJa()} />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        ) : (
          // テキストを表示
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
            <Box sx={{ width: '100%' }}>{name?.input}</Box>
            <Box>
              <Tooltip title='修正する'>
                <IconButton aria-label='edit'>
                  <CreateIcon onClick={() => setIsInputJaActive(true)} />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        )}
      </Box>

      {/* English */}
      <Box sx={{ ml: 1, mb: 2 }}>
        <Typography variant='caption' sx={{ width: '180px', whiteSpace: 'nowrap' }}>
          英語（出品用）
        </Typography>
        {isInputEnActive ? (
          // 入力フォームを表示
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
            <ClickAwayListener onClickAway={() => setIsInputEnActive(false)}>
              <Input
                placeholder='English'
                defaultValue={name.inputEn ? name.inputEn : name.translated?.en}
                value={newInputEn}
                onChange={handleNewInputEnChange}
                fullWidth
                multiline
                sx={{ my: 1 }}
              />
            </ClickAwayListener>
            <Box>
              <Tooltip title='保存する'>
                <IconButton aria-label='save'>
                  <SaveAltIcon onClick={() => handleUpdateInputEn()} />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        ) : (
          // テキストを表示
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
            <Box sx={{ width: '100%' }}>{name?.inputEn ? name?.inputEn : name?.translated?.en}</Box>
            <Box>
              <Tooltip title='修正する'>
                <IconButton aria-label='edit'>
                  <CreateIcon onClick={() => setIsInputEnActive(true)} />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};
