import { FC, useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { activeCategoriesSelector } from 'Settings/Categories/redux/selector';
import { getActiveCategories } from 'Settings/Categories/firebase/getActiveCategories';
import { Stack, Box, Typography, FormControl, MenuItem, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
  selectedVender: string;
  selectedPrimaryId: string;
  setSelectedPrimaryId: Dispatch<SetStateAction<string>>;
  selectedSecondaryId: string;
  setSelectedSecondaryId: Dispatch<SetStateAction<string>>;
  selectedTertiaryId: string;
  setSelectedTertiaryId: Dispatch<SetStateAction<string>>;
};

export const SelectCategory: FC<Props> = ({
  selectedVender,
  selectedPrimaryId,
  setSelectedPrimaryId,
  selectedSecondaryId,
  setSelectedSecondaryId,
  selectedTertiaryId,
  setSelectedTertiaryId,
}) => {
  const uid = useSelector(uidSelector);
  const activeCategories = useSelector(activeCategoriesSelector);
  const dispatch = useDispatch();

  // activeCategoriesを取得
  useEffect(() => {
    dispatch(getActiveCategories({ uid, selectedVender: selectedVender }));
    return () => {
      dispatch(getActiveCategories({ uid, selectedVender: selectedVender }));
    };
  }, [dispatch, selectedVender, uid]);

  // level毎に分割
  const primaryList = activeCategories.filter((e) => e.level === 'primary');
  const secondaryList = activeCategories.filter((e) => e.level === 'secondary');
  const tertiaryList = activeCategories.filter((e) => e.level === 'tertiary');

  // select
  const handleSelectedPrimaryIdChange = (event: SelectChangeEvent) => {
    setSelectedPrimaryId(event.target.value);
    setSelectedSecondaryId('');
    setSelectedTertiaryId('');
  };
  const handleSelectedSecondaryIdChange = (event: SelectChangeEvent) => {
    setSelectedSecondaryId(event.target.value);
    setSelectedTertiaryId('');
  };
  const handleSelectedTertiaryIdChange = (event: SelectChangeEvent) => {
    setSelectedTertiaryId(event.target.value);
  };

  // console.log('tertiaryList', tertiaryList);

  return (
    <Box component='section' sx={{ mb: 5 }}>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        商品カテゴリー
      </Typography>

      <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormControl fullWidth>
            <InputLabel id='category-label'>大分類</InputLabel>
            <Select
              labelId='category-label'
              id='category'
              label='大分類'
              value={selectedPrimaryId}
              onChange={handleSelectedPrimaryIdChange}
            >
              {primaryList.map((primary: any) => (
                <MenuItem value={primary.id} key={primary.id}>
                  {primary.ja}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {secondaryList.filter((e) => e.primary === selectedPrimaryId).length > 0 && (
          <>
            <ArrowForwardIosIcon fontSize='small' />
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='category-label'>中分類</InputLabel>
                <Select
                  labelId='category-label'
                  id='category'
                  label='中分類'
                  value={selectedSecondaryId}
                  onChange={handleSelectedSecondaryIdChange}
                >
                  {secondaryList
                    .filter((e) => e.primary === selectedPrimaryId)
                    .map((secondary: any) => (
                      <MenuItem value={secondary.id} key={secondary.id}>
                        {secondary.ja}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}

        {tertiaryList.filter((e) => e.secondary === selectedSecondaryId).length > 0 && (
          <>
            <ArrowForwardIosIcon fontSize='small' />
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id='category-label'>小分類</InputLabel>
                <Select
                  labelId='category-label'
                  id='category'
                  label='小分類'
                  value={selectedTertiaryId}
                  onChange={handleSelectedTertiaryIdChange}
                >
                  {tertiaryList
                    .filter((e) => e.secondary === selectedSecondaryId)
                    .map((tertiary: any) => (
                      <MenuItem value={tertiary.id} key={tertiary.id}>
                        {tertiary.ja}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
