import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const initOptions = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    selectedVender: string;
    selectedCategory: string;
    section: string;
    subSection: string;
    productId: string;
  }
>('product/initOptions', async ({ uid, selectedVender, selectedCategory, section, subSection, productId }) => {
  let res: firebase.firestore.DocumentData = [];
  let venders: firebase.firestore.DocumentData = [];
  let categories: firebase.firestore.DocumentData = [];
  let options: firebase.firestore.DocumentData = [];

  // 商品にoptions/selectedVenderが無かったらdocを追加する
  const optionsDocsRef = db.collection('users').doc(uid).collection('products').doc(productId).collection('options');
  await optionsDocsRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      venders.push(doc.id);
    });
  });
  if (venders.length === 0 || !venders.includes(selectedVender)) {
    await optionsDocsRef.doc(selectedVender).set({});
  }

  switch (section) {
    case 'categories':
      const categoriesDocsRef = db
        .collection('users')
        .doc(uid)
        .collection('products')
        .doc(productId)
        .collection('options')
        .doc(selectedVender)
        .collection(section);
      // 商品のoptions/categories（カテゴリー一覧）を取得
      await categoriesDocsRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          categories.push({ id: doc.id, ...doc.data() });
        });
      });
      break;
    default:
      const optionsDocsRef = db
        .collection('users')
        .doc(uid)
        .collection('products')
        .doc(productId)
        .collection('options')
        .doc(selectedVender)
        .collection(section);
      // 商品のoptions(2)を取得
      await optionsDocsRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          options.push({ id: doc.id, ...doc.data() });
        });
      });
      break;
  }

  switch (section) {
    case 'categories':
      // (1)で取得したリストに選択したカテゴリーがなかったら、ユーザーのtemplatesからコピーして追加する
      if (categories.length === 0 || !categories.some((e: { id: string }) => e.id === selectedCategory)) {
        // ユーザーのテンプレートから選択したカテゴリーのoptionsを取得する
        let data: firebase.firestore.DocumentData = [];
        const templateDocsRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection)
          .orderBy('order');
        await templateDocsRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
            // res.push(doc.data());
          });
        });
        // selectedCategoryのdocを追加する
        const targetCategoryDocRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory);
        await targetCategoryDocRef.set({});
        // 取得したoptionsを商品に追加する
        const productDocsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection);
        await Promise.all(
          data.map(async (data: any) => {
            if (productDocsRef) {
              await productDocsRef
                .add(data)
                .then((docRef) => {
                  res.push({ id: docRef.id, ...data });
                  console.log('Document written with ID: ', docRef.id);
                })
                .catch((error) => {
                  console.error('Error adding document: ', error);
                });
            }
          })
        );
      }
      break;
    default:
      // (2)で取得したリストにsectionがなかったら、ユーザーのtemplatesからコピーして追加する
      if (options.length === 0) {
        // ユーザーのテンプレートから選択したカテゴリーのoptionsを取得する
        let data: firebase.firestore.DocumentData = [];
        const templateDocsRef = db
          .collection('users')
          .doc(uid)
          .collection('templates')
          .doc(selectedVender)
          .collection(section)
          .orderBy('order');
        await templateDocsRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
            res.push(doc.data());
          });
        });

        // 取得したoptionsを商品に追加する
        const productDocsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section);
        await Promise.all(
          data.map(async (data: any) => {
            if (productDocsRef) {
              await productDocsRef
                .add(data)
                .then((docRef) => {
                  console.log('Document written with ID: ', docRef.id);
                })
                .catch((error) => {
                  console.error('Error adding document: ', error);
                });
            }
          })
        );
      }
      break;
  }

  return { res: res, section: section, subSection: subSection };
});
