import { FC } from 'react';
import { useSelector } from 'react-redux';
import { imageCountSelector } from 'User/redux/selector';
import { Typography, Grid, Paper } from '@mui/material';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
// import StorageIcon from '@material-ui/icons/Storage';

type Props = {
  component: string;
};

export const Stats: FC<Props> = ({ component }) => {
  const imageCount = useSelector(imageCountSelector);

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container justifyContent='center' alignItems='center' wrap='nowrap'>
        {component === 'count' && (
          <>
            <Grid item>
              <DynamicFeedIcon fontSize='large' />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container direction='column' sx={{ ml: 3 }}>
                <Grid item>
                  <Typography>合計画像数</Typography>
                </Grid>
                <Grid item>
                  <Typography component='span' variant='h4'>
                    {imageCount}
                  </Typography>
                  <Typography component='span'> 枚</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {/* {component === 'volume' && (
          <>
            <Grid item>
              <StorageIcon fontSize='large' />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction='column' className={classes.dataColumn}>
                <Grid item>
                  <Typography>データ量</Typography>
                </Grid>
                <Grid item>
                  {(() => {
                    if (String(volume).length > 9) {
                      return (
                        <>
                          <Typography component='span' variant='h4'>
                            {Math.round((volume / 1000 / 1000 / 1000) * 100) / 100}
                          </Typography>
                          <Typography component='span'> GB</Typography>
                        </>
                      );
                    } else if (String(volume).length <= 9 && String(volume).length > 6) {
                      return (
                        <>
                          <Typography component='span' variant='h4'>
                            {Math.round((volume / 1000 / 1000) * 100) / 100}
                          </Typography>
                          <Typography component='span'> MB</Typography>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Typography component='span' variant='h4'>
                            {Math.round((volume / 1000) * 100) / 100}
                          </Typography>
                          <Typography component='span'> kB</Typography>
                        </>
                      );
                    }
                  })()}
                </Grid>
              </Grid>
            </Grid>
          </>
        )} */}
      </Grid>
    </Paper>
  );
};
