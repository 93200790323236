import { CSSProperties } from 'react';

type Props = {
  text: CSSProperties['color'];
  background: CSSProperties['backgroundColor'];
};

export const Style = ({ text, background }: Props) => {
  let style = `
  <style>
  .article {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 0;
    margin-left: 0;
    padding: 40px;
    background-color: white;
    color: ${text};
    font-size: 16px;
    line-height: 1.8;
  }
  .headerProductName {
    margin: 0;
    line-height: 1.5;
    font-weight: bold;
    font-size: 32px;
  }
  .thumbs {
    margin-top: 32px;
  }
  .thumbsUl {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 8px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .thumbsLi {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
  }
  .thumbsItem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: auto;
    height: auto;
    max-width: 179%;
    max-height: initial;
  }
  .notes {}
  .section {
    margin-top: 32px;
  }
  .sectionTitle {
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 16px;
    padding-left: 16px;
    background-color: ${background};
    border-radius: 3px;
    color: white;
    font-size: 21px;
  }
  .subsectionTitle {
    margin-top: 24px;
    margin-bottom: 0;
    margin-right: 8px;
    margin-left: 8px;
    font-size: 24px;
  }
  .subsectionBody {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 19px;
    padding-left: 19px;
  }
  .subsectionBodyUl {
    padding: 0;
  }
  .subsectionBodyLi {
    list-style: none;
  }
  .images {
    margin-top: 32px;
  }
  .imagesUl {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 8px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .imagesLi {
    line-height: 0;
  }
  .imagesItem {
    width: 100%;
    border-radius: 3px;
  }
  </style>`;

  return { style };
};
