import { FC, useEffect } from 'react';
import { AppDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { setPage } from 'components/redux/slice';
import { Typography, Box, Button, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const Page404: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('404'));
    return () => {
      dispatch(setPage('404'));
    };
  }, [dispatch]);

  return (
    <Box component='section'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <ErrorOutlineIcon fontSize='large' />
        <Typography component='h1' variant='h5'>
          ページが見つかりません
        </Typography>
      </Stack>
      <Box component='p' sx={{ mt: 4 }}>
        <Typography>
          アクセスしようとしたページは、削除、変更されたか、現在利用できない可能性があります。
          <br />
          正しい操作をしたにも関わらずこのページが表示される場合は、大変お手数ですが下記よりお問い合わせください。
        </Typography>
      </Box>
      <Button variant='contained' size='large' href='https://cloudpix.page/contact' sx={{ mt: 2 }}>
        お問い合わせフォームへ
      </Button>
    </Box>
  );
};
