import { FC } from 'react';
import { List } from './List';
import { Typography, Box } from '@mui/material';

export const Categories: FC = () => {
  return (
    <Box component='section'>
      <Box sx={{ mt: 7, mb: 7 }}>
        <Typography component='h1' variant='h6' sx={{ mb: 3, fontWeight: 'bold' }}>
          出品カテゴリー設定
        </Typography>
        <Typography>使用するカテゴリーを選択します。</Typography>
      </Box>
      <List />
    </Box>
  );
};
