import { FC, useEffect, useState } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { db } from 'app/firebase';
import { useCheckProvider } from 'Auth/useCheckProvider';
import { GetProducts } from 'Products/GetProducts';

type Props = RouteProps & {
  component: any;
};
type LocationState = {
  priceId?: string;
};

export const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [flag, setFlag] = useState(false);
  const { checkProvider, isProvider } = useCheckProvider({ setFlag });
  const location = useLocation<LocationState>();

  const getStatus = async () => {
    const res: string[] = [];
    if (currentUser) {
      await db
        .collection('users')
        .doc(currentUser.uid)
        .collection('subscriptions')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            res.push(doc.data().status);
          });
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    }
    if (res.includes('active') || res.includes('trialing')) {
      setIsActiveUser(true);
    }
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      const logic = async () => {
        // メールの確認が済んでいる場合はisEmailVerifiedをtrueにする
        if (currentUser && currentUser.emailVerified) {
          // history.push('/signin');
          setIsEmailVerified(true);
        }

        await getStatus();

        // Provider認証かチェック
        checkProvider();
      };
      logic();
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkProvider, currentUser, isProvider]);

  // console.log('currentUser', currentUser);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>): React.ReactNode =>
        currentUser ? (
          <>
            {flag && (
              <>
                {isProvider ? (
                  <>
                    {isActiveUser ? (
                      <GetProducts>
                        <Component {...props} />
                      </GetProducts>
                    ) : (
                      <Redirect
                        to={{
                          pathname: '/account/checkout',
                          state: { priceId: null },
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {isEmailVerified ? (
                      <>
                        {isActiveUser ? (
                          <GetProducts>
                            <Component {...props} />
                          </GetProducts>
                        ) : (
                          <>
                            {location.state ? (
                              <Redirect
                                to={{
                                  pathname: '/account/checkout',
                                  state: { priceId: location.state.priceId },
                                }}
                              />
                            ) : (
                              <Redirect
                                to={{
                                  pathname: '/account/checkout',
                                  state: { priceId: null },
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Redirect to='/signin' />
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Redirect to='/signin' />
        )
      }
    />
  );
};
