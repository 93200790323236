import { FC, useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { vendersSelector } from 'Settings/Templates/redux/selector';
import { getVenders } from 'Settings/Templates/firebase/getVenders';
import {
  Box,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Stack,
  InputLabel,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type Props = {
  selectedVender: string;
  setSelectedVender: Dispatch<SetStateAction<string>>;
};

export const Vender: FC<Props> = ({ selectedVender, setSelectedVender }) => {
  const uid = useSelector(uidSelector);
  const venders = useSelector(vendersSelector);
  const dispatch = useDispatch();

  // vendersを取得
  useEffect(() => {
    dispatch(getVenders({ uid }));
    return () => {
      dispatch(getVenders({ uid }));
    };
  }, [dispatch, uid]);

  // select
  const handleVenderChange = (event: SelectChangeEvent) => {
    setSelectedVender(event.target.value);
  };

  return (
    <Box component='section' sx={{ mb: 5 }}>
      <Typography component='h2' variant='body1' sx={{ mb: 2, fontWeight: 'bold' }}>
        テンプレート
      </Typography>

      <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <FormControl fullWidth>
            <InputLabel id='vender-label'>出品先</InputLabel>
            <Select
              labelId='vender-label'
              id='vender'
              label='出品先'
              value={selectedVender}
              onChange={handleVenderChange}
            >
              {venders.map((vender) => (
                <MenuItem value={vender.id} key={vender.id}>
                  {vender.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );
};
