import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateCategory = createAsyncThunk<
  firebase.firestore.DocumentData,
  { uid: string | undefined; productId: string; selectedId: string; level: 'primary' | 'secondary' | 'tertiary' }
>('product/updateCategory', async ({ uid, productId, selectedId, level }) => {
  const docRef = db.collection('users').doc(uid).collection('products').doc(productId);

  // カテゴリーをDBに保存
  switch (level) {
    case 'primary':
      docRef
        .update({
          categoryPrimary: selectedId,
          categorySecondary: 'n/a',
          categoryTertiary: 'n/a',
        })
        .then(() => {
          console.log('Document successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
      break;
    case 'secondary':
      docRef
        .update({
          categorySecondary: selectedId,
          categoryTertiary: 'n/a',
        })
        .then(() => {
          console.log('Document successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
      break;
    case 'tertiary':
      docRef
        .update({
          categoryTertiary: selectedId,
        })
        .then(() => {
          console.log('Document successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
      break;

    default:
      break;
  }

  // DBからデフォルトテンプレートを読み込む

  // 削除対象ドキュメントの一覧を取得

  // ユーザーのテンプレートを一旦消す

  // デフォルトテンプレートと同じデータをセットする

  return { selectedId: selectedId, level: level };
});
// };
