import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { uidSelector } from 'Auth/redux/selector';
import { presetCategoriesSelector } from 'Settings/Categories/redux/selector';
import { preparedCategoriesSelector } from 'Settings/Categories/redux/selector';
import { getPresetCategories } from 'Settings/Categories/firebase/getPresetCategories';
import { getPreparedCategories } from 'Settings/Categories/firebase/getPreparedCategories';
import { addCategory } from 'Settings/Categories/firebase/addCategory';
import { updateCategory } from 'Settings/Categories/firebase/updateCategory';
import { Typography, Box, Grid, FormControlLabel, Checkbox } from '@mui/material';

export const List: FC = () => {
  const uid = useSelector(uidSelector);
  const presetCategories = useSelector(presetCategoriesSelector);
  const preparedCategories = useSelector(preparedCategoriesSelector);
  const dispatch = useDispatch();

  // presetCategoriesをlevel毎に分割
  const primaryPresets = presetCategories.filter((e) => e.level === 'primary');
  const secondaryPresets = presetCategories.filter((e) => e.level === 'secondary');
  const tertiaryPresets = presetCategories.filter((e) => e.level === 'tertiary');

  // カテゴリーリストを取得
  useEffect(() => {
    dispatch(getPresetCategories({ vender: 'ebay' }));
    dispatch(getPreparedCategories({ uid, selectedVender: 'ebay' }));
    return () => {
      dispatch(getPresetCategories({ vender: 'ebay' }));
      dispatch(getPreparedCategories({ uid, selectedVender: 'ebay' }));
    };
  }, [dispatch, uid]);

  // プリセットから追加
  const add = (
    categoryId: string,
    ja: string,
    level: 'primary' | 'secondary' | 'tertiary',
    primaryParentId?: string,
    secondaryParentId?: string
  ) => {
    let targetCategory;
    let secondaryChildren;
    let tertiaryChildren;
    let primaryParent;
    let secondaryParent;
    switch (level) {
      case 'primary':
        targetCategory = primaryPresets.find((v) => v.id === categoryId);
        secondaryChildren = secondaryPresets.filter((v) => v.primary === categoryId);
        tertiaryChildren = tertiaryPresets.filter((v) => v.primary === categoryId);
        break;
      case 'secondary':
        targetCategory = secondaryPresets.find((v) => v.id === categoryId);
        primaryParent = primaryPresets.find((v) => v.id === primaryParentId);
        tertiaryChildren = tertiaryPresets.filter((v) => v.secondary === categoryId);
        break;
      case 'tertiary':
        targetCategory = tertiaryPresets.find((v) => v.id === categoryId);
        primaryParent = primaryPresets.find((v) => v.id === primaryParentId);
        secondaryParent = secondaryPresets.find((v) => v.id === secondaryParentId);
        break;
      default:
        break;
    }
    dispatch(
      addCategory({
        uid: uid,
        preparedCategories: preparedCategories,
        selectedVender: 'ebay',
        ja: ja,
        level: level,
        targetCategory: targetCategory,
        secondaryChildren: secondaryChildren,
        tertiaryChildren: tertiaryChildren,
        primaryParent: primaryParent,
        secondaryParent: secondaryParent,
      })
    );
  };

  // statusのON/OFF
  const update = (
    categoryId: string,
    level: 'primary' | 'secondary' | 'tertiary',
    primaryParentId?: string,
    secondaryParentId?: string
  ) => {
    // TODO: カテゴリーが商品で使用中の場合は削除させない（アラート等で停止）
    let targetCategory;
    let secondaryChildren;
    let tertiaryChildren;
    switch (level) {
      case 'primary':
        targetCategory = primaryPresets.find((v) => v.id === categoryId);
        secondaryChildren = secondaryPresets.filter((v) => v.primary === categoryId);
        tertiaryChildren = tertiaryPresets.filter((v) => v.primary === categoryId);
        break;
      case 'secondary':
        targetCategory = secondaryPresets.find((v) => v.id === categoryId);
        tertiaryChildren = tertiaryPresets.filter((v) => v.secondary === categoryId);
        break;
      case 'tertiary':
        targetCategory = tertiaryPresets.find((v) => v.id === categoryId);
        break;
      default:
        break;
    }
    const status = preparedCategories.find((e) => e.id === categoryId)?.status;
    dispatch(
      updateCategory({
        uid,
        vender: 'ebay',
        categoryId: categoryId,
        level: level,
        status: status,
        targetCategory: targetCategory,
        primaryParentId: primaryParentId,
        secondaryParentId: secondaryParentId,
        secondaryChildren: secondaryChildren,
        tertiaryChildren: tertiaryChildren,
      })
    );
  };

  const handleChange = (
    categoryId: string,
    ja: string,
    level: 'primary' | 'secondary' | 'tertiary',
    primaryParentId?: string,
    secondaryParentId?: string
    // appearances?: []
  ) => {
    add(categoryId, ja, level, primaryParentId, secondaryParentId);
    if (preparedCategories.some((e) => e.id === categoryId)) {
      update(categoryId, level, primaryParentId, secondaryParentId);
    }
  };

  return (
    <Box component='section' sx={{ mb: 7 }}>
      <Typography component='h2' variant='body1' sx={{ fontWeight: 'bold' }}>
        カテゴリーリスト
      </Typography>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {primaryPresets?.map((primaryPreset: any) => (
          <Grid item key={primaryPreset.id}>
            <FormControlLabel
              label={primaryPreset.ja}
              control={
                <Checkbox
                  checked={
                    preparedCategories.some((e) => e.id === primaryPreset.id) &&
                    preparedCategories.find((e) => e.id === primaryPreset.id)?.status === 'enable'
                  }
                  onChange={() => handleChange(primaryPreset.id, primaryPreset.ja, 'primary', undefined, undefined)}
                />
              }
            />
            {secondaryPresets.map(
              (secondaryPreset: any) =>
                secondaryPreset.primary === primaryPreset.id && (
                  <Box key={secondaryPreset.id} sx={{ ml: 2 }}>
                    <FormControlLabel
                      label={secondaryPreset.ja}
                      control={
                        <Checkbox
                          checked={
                            preparedCategories.some((e) => e.id === secondaryPreset.id) &&
                            preparedCategories.find((e) => e.id === secondaryPreset.id)?.status === 'enable'
                          }
                          onChange={() =>
                            handleChange(
                              secondaryPreset.id,
                              secondaryPreset.ja,
                              'secondary',
                              secondaryPreset.primary,
                              undefined
                            )
                          }
                        />
                      }
                    />
                    {tertiaryPresets.map(
                      (tertiaryPreset: any) =>
                        tertiaryPreset.secondary === secondaryPreset.id && (
                          <Box key={tertiaryPreset.id} sx={{ ml: 3 }}>
                            <FormControlLabel
                              label={tertiaryPreset.ja}
                              control={
                                <Checkbox
                                  checked={
                                    preparedCategories.some((e) => e.id === tertiaryPreset.id) &&
                                    preparedCategories.find((e) => e.id === tertiaryPreset.id)?.status === 'enable'
                                  }
                                  onChange={() =>
                                    handleChange(
                                      tertiaryPreset.id,
                                      tertiaryPreset.ja,
                                      'tertiary',
                                      tertiaryPreset.primary,
                                      tertiaryPreset.secondary
                                    )
                                  }
                                />
                              }
                            />
                          </Box>
                        )
                    )}
                  </Box>
                )
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
