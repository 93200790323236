import { FC, useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { db } from 'app/firebase';
import firebase from 'firebase/app';
import { CustomerPortal } from 'Payment/CustomerPortal';
import { Typography, Box, Button, Stack } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const Plan: FC = () => {
  const { currentUser } = useAuth();
  const [subscriptions, setSubscriptions] = useState<firebase.firestore.DocumentData[]>([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const getSubscription = async () => {
      if (!unmounted && currentUser) {
        await db
          .collection('users')
          .doc(currentUser.uid)
          .collection('subscriptions')
          .where('status', 'in', ['active', 'trialing'])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setSubscriptions((subscriptions) => [...subscriptions, doc.data()]);
              setIsActive(true);
            });
          })
          .catch((error) => {
            console.log('Error getting documents: ', error);
          });
      }
    };
    getSubscription();

    return () => {
      unmounted = true;
    };
  }, [currentUser]);

  // console.log('isActive', isActive);

  return (
    <Box component='section'>
      {isActive ? (
        <>
          {subscriptions?.map((subscription: any, index: number) => (
            <div key={index}>
              <Box sx={{ mt: 7, mb: 2 }}>
                <Typography component='h1' variant='h6' sx={{ fontWeight: 'bold' }}>
                  現在のご契約プラン
                </Typography>
              </Box>
              {subscription.status === 'trialing' && subscription.role === 'basic' && (
                <Box>無料トライアル（ベーシックプラン）</Box>
              )}
              {subscription.status === 'trialing' && subscription.role === 'premium' && (
                <Box>無料トライアル（プレミアムプラン）</Box>
              )}
              {subscription.status === 'active' && subscription.role === 'basic' && <Box>ベーシック</Box>}
              {subscription.status === 'active' && subscription.role === 'premium' && <Box>プレミアム</Box>}
              {subscription.status === 'trialing' && (
                <Box sx={{ mt: 7, mb: 2 }}>
                  <Typography component='h1' variant='h6' sx={{ fontWeight: 'bold' }}>
                    お試し期間
                  </Typography>
                </Box>
              )}
              {subscription.status === 'active' && (
                <Box sx={{ mt: 7, mb: 2 }}>
                  <Typography component='h1' variant='h6' sx={{ fontWeight: 'bold' }}>
                    ご契約期間
                  </Typography>
                </Box>
              )}
              <Box>
                開始日：{subscription.current_period_start.toDate().getFullYear()}/
                {subscription.current_period_start.toDate().getMonth() + 1}/
                {subscription.current_period_start.toDate().getDate()}{' '}
                {subscription.current_period_start.toDate().getHours()}:
                {subscription.current_period_start.toDate().getMinutes().toString().padStart(2, '0')}
                <br />
                終了日：{subscription.current_period_end.toDate().getFullYear()}/
                {subscription.current_period_end.toDate().getMonth() + 1}/
                {subscription.current_period_end.toDate().getDate()}{' '}
                {subscription.current_period_end.toDate().getHours()}:
                {subscription.current_period_end.toDate().getMinutes().toString().padStart(2, '0')}
              </Box>
            </div>
          ))}
          <Box sx={{ mt: 5 }}>
            <CustomerPortal />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mt: 7, mb: 2 }}>
            <Typography component='h1' variant='h6' sx={{ fontWeight: 'bold' }}>
              現在のご契約プラン
            </Typography>
          </Box>
          <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
            <ErrorOutlineIcon />
            <Typography>現在有効なご契約がありません。</Typography>
          </Stack>
          <Box sx={{ mt: 1 }}>
            <Typography variant='caption'>引き続きご利用いただくには、ご契約の更新が必要です。</Typography>
          </Box>
          <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
            <Button
              startIcon={<OpenInNewIcon />}
              variant='contained'
              color='primary'
              size='large'
              href='/account/checkout'
            >
              プランを選択
            </Button>
            <Box>
              <CustomerPortal />
            </Box>
          </Stack>
        </>
      )}
    </Box>
  );
};
