import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { productEntitiesSelector } from 'Product/redux/selector';
import { SetImages } from 'Product/Images/SetImages';
import { Dialog, DialogContent, DialogTitle, Tooltip, IconButton } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';

export const AddImages: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const product = useSelector(productEntitiesSelector);

  return (
    <>
      <Tooltip title='画像をアップロード' aria-label='change'>
        <IconButton onClick={() => setIsOpen(true)}>
          <PublishIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isOpen}
        keepMounted
        onClose={() => setIsOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            p: 2,
          },
        }}
      >
        <DialogTitle>
          {product?.name?.input ? `${product.name.input}に画像を追加` : '画像をアップロードして商品に追加'}
        </DialogTitle>
        <DialogContent>
          <SetImages setIsOpen={setIsOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
};
