import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const getVenders = createAsyncThunk<firebase.firestore.DocumentData, { uid: string | undefined }>(
  'templates/getVenders',
  async ({ uid }) => {
    let venders: firebase.firestore.DocumentData = [];

    const docsRef = db.collection('users').doc(uid).collection('templates');
    await docsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        venders.push({ id: doc.id, ...doc.data() });
      });
    });

    return { venders: venders };
  }
);
