import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const addOption = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedVender: string;
    section: string;
    subSection: string;
    newOption: string;
    optionsLength: number;
    selectedCategory: string;
  }
>(
  'product/addOption',
  async ({ uid, productId, selectedVender, section, subSection, newOption, optionsLength, selectedCategory }) => {
    let resId: string = '';
    let docRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

    switch (section) {
      case 'categories':
        docRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection);
        break;
      default:
        docRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section);
        break;
    }

    await docRef
      .add({
        input: newOption,
        order: optionsLength,
      })
      .then((res) => {
        resId = res.id;
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });

    return {
      selectedVender: selectedVender,
      section: section,
      subSection: subSection,
      id: resId,
      input: newOption,
      order: optionsLength,
    };
  }
);
