import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateOrderUp = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedVender: string;
    section: string;
    subSection: string;
    currentOptionId: string;
    currentOptionOrder: number;
    selectedCategory: string;
  }
>(
  'product/updateOrderUp',
  async ({
    uid,
    productId,
    selectedVender,
    section,
    subSection,
    currentOptionId,
    currentOptionOrder,
    selectedCategory,
  }) => {
    let docsRef;

    switch (section) {
      case 'images':
        docsRef = db.collection('users').doc(uid).collection('products').doc(productId).collection('images');
        break;
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection);
        break;
      default:
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section);
        break;
    }

    // 一つ上のIDを取得
    let upperOptionId: string = '';
    await docsRef
      .where('order', '==', (currentOptionOrder -= 1))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          upperOptionId = doc.id;
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });

    // 自身のorderをdecrementする
    await docsRef.doc(currentOptionId).update({
      order: firebase.firestore.FieldValue.increment(-1),
    });

    // 一つ上のorderをincrementする
    await docsRef.doc(upperOptionId).update({
      order: firebase.firestore.FieldValue.increment(1),
    });

    return {
      selectedVender: selectedVender,
      section: section,
      subSection: subSection,
      currentOptionId: currentOptionId,
      selectedCategory: selectedCategory,
      upperOptionId: upperOptionId,
    };
  }
);
