import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from 'Auth/redux/slice';
import { userSlice } from 'User/redux/slice';
import { componentsSlice } from 'components/redux/slice';
import { categoriesSlice } from 'Settings/Categories/redux/slice';
import { templatesSlice } from 'Settings/Templates/redux/slice';
import { productsSlice } from 'Products/redux/slice';
import { productSlice } from 'Product/redux/slice';
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    components: componentsSlice.reducer,
    categories: categoriesSlice.reducer,
    templates: templatesSlice.reducer,
    products: productsSlice.reducer,
    product: productSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
