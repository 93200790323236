// stripe-firebase-extensions
// POSTINSTALL.md
// Redirect to the customer portal
// https://github.com/stripe/stripe-firebase-extensions/blob/next/firestore-stripe-subscriptions/POSTINSTALL.md
import { FC, useState } from 'react';
import { functions } from 'app/firebase';
import { Button, CircularProgress, Backdrop } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const CustomerPortal: FC = () => {
  const [loading, setLoading] = useState(false);

  // Redirect to the customer portal
  const handleCustomerPortal = async () => {
    setLoading(true);
    const functionRef = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: 'https://app.cloudpix.page/account/settings' });
    window.location.assign(data.url);
  };

  return (
    <>
      {loading && (
        <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Button
        startIcon={<OpenInNewIcon />}
        disabled={loading}
        type='submit'
        variant='contained'
        color='primary'
        size='large'
        onClick={handleCustomerPortal}
      >
        プランの詳細・変更
      </Button>
    </>
  );
};
