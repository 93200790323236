import { FC } from 'react';
import LogoWh from '../assets/logo-wh.png';
import { Container, Grid, Box, List, ListItem } from '@mui/material';

export const Footer: FC = () => {
  return (
    <Container
      id='footer'
      component='footer'
      maxWidth={false}
      sx={{
        mt: 'auto',
        py: 7,
        px: 3,
        background: (theme) => `linear-gradient(-135deg, ${theme.palette.primary.main} 20%, black 20%)`,
        color: 'white',
        borderBottom: '8px solid',
        borderImage: (theme) =>
          `linear-gradient(to left, ${theme.palette.secondary.main} 20%, ${theme.palette.primary.main} 40%)`,
        borderImageSlice: 1,
      }}
    >
      <Container maxWidth='lg'>
        <Grid container justifyContent='space-between'>
          <Grid item md={3}>
            <Box sx={{ maxWidth: '96px', py: 2 }}>
              <a href='https://cloudpix.page'>
                <img src={LogoWh} alt='CloudPIX' placeholder='blur' style={{ width: '100%' }} />
              </a>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Grid container justifyContent='flex-end' spacing={10}>
              <Grid item>
                <List
                  sx={{
                    flexDirection: 'row',
                    '& a': {
                      typography: 'body2',
                      color: 'white',
                      textDecoration: 'none',
                      '&:hover': {
                        opacity: 0.7,
                      },
                    },
                  }}
                >
                  <ListItem>
                    <a href='https://cloudpix.page/help'>ヘルプ</a>
                  </ListItem>
                  <ListItem>
                    <a href='https://cloudpix.page/contact'>お問い合わせ</a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item>
                <List
                  sx={{
                    flexDirection: 'row',
                    '& a': {
                      typography: 'body2',
                      color: 'white',
                      textDecoration: 'none',
                      '&:hover': {
                        opacity: 0.7,
                      },
                    },
                  }}
                >
                  <ListItem>
                    <a href='https://cloudpix.page/terms-of-service'>利用規約</a>
                  </ListItem>
                  <ListItem>
                    <a href='https://cloudpix.page/privacy-policy'>プライバシーポリシー</a>
                  </ListItem>
                  <ListItem>
                    <a href='https://cloudpix.page/specified-commercial-transactions'>特定商取引法に基づく表記</a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item>
                <Box sx={{ py: 2, typography: 'body2' }}>&copy; transphere</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
