import { FC, useEffect } from 'react';
import { AppDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { setPage } from 'components/redux/slice';
import { deleteUser } from 'app/deleteUser';
import { useAuth } from 'contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Container, Box, Button, Typography, Checkbox, Paper, Stack, FormControlLabel } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

export type FormValues = {
  agreement: boolean;
};

export const Unsubscribe: FC = () => {
  const { currentUser } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage('settings'));
    return () => {
      dispatch(setPage('settings'));
    };
  }, [dispatch]);

  const methods = useForm<FormValues>({ mode: 'onBlur' });
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   control,
  // } = methods;

  const handleDeleteUser = () => {
    dispatch(deleteUser({ currentUser }));
    return <Redirect to='/' />;
  };

  return (
    <Container component='section' maxWidth={false}>
      <Typography variant='h5' component='h1' sx={{ fontWeight: 'bold' }}>
        アカウントを削除
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Typography>アカウントはすぐに削除されます。以下の点をご確認ください。</Typography>
      </Box>
      <Box sx={{ lineHeight: 3 }}>
        <ol>
          <li>全ての画像と登録内容がサーバーから削除され、二度と復旧できません。</li>
          <li>ショッピングサイト（ebay、ヤフオク、楽天市場等）の商品画像が表示されなくなります。※1</li>
          <li>ご請求の履歴や領収書の発行画面は同時に削除され、アクセスできなくなります。</li>
          <li>ご契約残存期間の料金は返金されません。※2</li>
        </ol>
      </Box>
      <Paper sx={{ mt: 3, py: 2, px: 3 }}>
        <Stack direction='row' spacing={2}>
          <InfoIcon />
          <Typography variant='body2' sx={{ textAlign: 'justify' }}>
            プランの有効期限が切れた場合でも、アカウントを削除せずに再度プランを有効にすることで、引き続き以前の状態でご利用いただけますが、一度アカウントを削除してしまうと復旧できなくなります。
          </Typography>
        </Stack>
      </Paper>
      <Box sx={{ mt: 2 }}>
        <Typography variant='caption'>
          ※1　Cloud PIX を参照している画像がリンク切れとなり、参照元でエラーとなります。
          <br />
          ※2　残存期間に対する日割り計算と返金はありません。
        </Typography>
      </Box>
      <form
        onSubmit={methods.handleSubmit(() => {
          handleDeleteUser();
        })}
      >
        <Controller
          control={methods.control}
          name='agreement'
          rules={{ required: 'チェックが必要です' }}
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlLabel
                control={<Checkbox onChange={onChange} checked={value} />}
                label='上記の注意事項について確認しました'
                sx={{ mt: 2 }}
              />
            );
          }}
        />
        {methods.formState.errors.agreement && (
          <Typography color='error'>{methods.formState.errors.agreement.message}</Typography>
        )}
        <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
          <Button variant='contained' color='primary' type='submit'>
            アカウントを削除する
          </Button>
          <Button variant='outlined' size='large' startIcon={<SettingsBackupRestoreIcon />} href='/settings'>
            戻る
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
