import { FC, useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { auth } from 'app/firebase';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui-ja';
import 'firebaseui-ja/dist/firebaseui.css';
import { useDispatch } from 'react-redux';
import { setType } from 'components/redux/slice';
import { setLightmode } from 'User/redux/slice';
import { useError } from 'components/Header/AccountDialog/useError';
import { Container, Paper, Typography, TextField, Button, Snackbar, Alert, Box } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

export const SignUp: FC = () => {
  const { createUserWithEmailAndPassword } = useAuth();
  const [alert, setAlert] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passworConfirmd, setPassworConfirmd] = useState<string>();
  const [isEmailVerified, setIsEmailVarified] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { handleLogout } = useError();
  const dispatch = useDispatch();

  dispatch(setType('onBackground'));

  // 一時的にライトモードを強制する
  dispatch(setLightmode());

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePassworConfirmdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassworConfirmd(e.target.value);
  };

  // メールの確認が済んでいない場合は確認メールを送信
  const sendEmail = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      await auth.currentUser.sendEmailVerification();
      setIsEmailVarified(false);
      setIsOpen(true);
      setAlert('確認用メールを送信しました');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return;
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== passworConfirmd) {
      return setError('パスワードが一致しません');
    }

    try {
      setError('');
      setLoading(true);
      await createUserWithEmailAndPassword(email, password);
      await sendEmail();
      handleLogout();
      // history.push('/');
    } catch {
      setError('既に登録されているメールアドレスです');
    }
    setLoading(false);
  };

  // Google認証でログイン
  useEffect(() => {
    const uiConfig = {
      signInFlow: 'popup',
      signInSuccessUrl: '/',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    };
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (ui) {
      ui.start('#firebaseui-auth-container', uiConfig);
    } else {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
    }
    return () => {};
  }, []);

  // console.log('auth.currentUser', auth.currentUser);

  return (
    <Container maxWidth='xs'>
      <Paper
        sx={{
          py: 8,
          px: 1.5,
          textAlign: 'center',
          color: (theme) => theme.palette.text.secondary,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <CreateIcon
          sx={{
            mb: 1,
            backgroundColor: (theme) => theme.palette.secondary.main,
            borderRadius: '50%',
            p: 1,
            width: '48px',
            height: '48px',
            color: 'white',
          }}
        />
        <Typography component='h1' variant='h4'>
          新規会員登録
        </Typography>
        {alert && (
          <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={() => setIsOpen(false)}
            sx={{ top: 'auto', bottom: 'auto' }}
          >
            <Alert severity='success' sx={{ m: 2 }}>
              {alert}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={() => setIsOpen(false)}
            sx={{ top: 'auto', bottom: 'auto' }}
          >
            <Alert severity='error' sx={{ m: 2 }}>
              {error}
            </Alert>
          </Snackbar>
        )}
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              m: 4,
              '&.MuiOutlinedInput-input:-webkit-autofill': {
                boxShadow: 'none',
              },
            }}
          >
            <TextField
              label='メールアドレス'
              variant='outlined'
              type='email'
              value={email}
              onChange={handleEmailChange}
              required
              fullWidth
              sx={{ mt: 2 }}
            />
            <TextField
              label='パスワード'
              variant='outlined'
              type='password'
              value={password}
              onChange={handlePasswordChange}
              required
              fullWidth
              sx={{ mt: 2 }}
            />
            <TextField
              label='パスワード（確認用）'
              variant='outlined'
              type='password'
              value={passworConfirmd}
              onChange={handlePassworConfirmdChange}
              required
              fullWidth
              sx={{ mt: 2 }}
            />
            <Button
              disabled={loading}
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              size='large'
              sx={{ mt: 2 }}
            >
              登録する
            </Button>
          </Box>
        </form>
        <Box component='p' sx={{ mt: 3 }}>
          <Link to='/signin'>既にアカウントをお持ちの方</Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '80%',
            my: 4,
            mx: 'auto',
            fontSize: (theme) => theme.typography.caption.fontSize,
            '&::before, &::after': {
              content: '""',
              height: '1px',
              backgroundColor: (theme) => theme.palette.grey[500],
              flexGrow: 1,
              m: 1,
            },
          }}
        >
          または
        </Box>
        <Box
          id='firebaseui-auth-container'
          sx={{
            mt: 3,
            '& button': {
              maxWidth: '100%',
              border: '1px solid grey',
              boxShadow: 'none',
              py: 1.5,
              px: 2,
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        ></Box>
      </Paper>
    </Container>
  );
};
