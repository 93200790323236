import { FC, useState, useEffect, ChangeEvent } from 'react';
import { AppDispatch } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { uidSelector } from 'Auth/redux/selector';
import { imageFormatSelector } from 'User/redux/selector';
import { updateImageFormat } from 'Settings/Templates/firebase/updateImageFormat';
import { Stack, Box, Typography, Switch } from '@mui/material';

export const ImageFormat: FC = () => {
  const uid = useSelector(uidSelector);
  const imageFormat = useSelector(imageFormatSelector);
  const [checked, setChecked] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (imageFormat === 'webp') {
      setChecked(true);
    }
    return () => {};
  }, [imageFormat]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    dispatch(
      updateImageFormat({
        uid: uid,
        checked: event.target.checked,
      })
    );
  };

  return (
    <Box component='section' sx={{ mb: 5 }}>
      <Typography component='h2' variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
        画像ファイル形式
      </Typography>
      <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>WebP形式を使用する（offの場合はJPEG形式を使用します）</Box>
        <Switch checked={checked} onChange={handleChange} />
      </Stack>
    </Box>
  );
};
