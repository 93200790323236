import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from 'app/firebase';

export const updateOptionStatus = createAsyncThunk<
  firebase.firestore.DocumentData,
  {
    uid: string | undefined;
    productId: string;
    selectedVender: string;
    section: string;
    subSection: string;
    currentOptionId: string;
    selectedCategory: string;
    status: 'enable' | 'disable';
  }
>(
  'product/updateOptionStatus',
  async ({ uid, productId, selectedVender, section, subSection, currentOptionId, selectedCategory, status }) => {
    let docsRef;

    switch (section) {
      case 'categories':
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section)
          .doc(selectedCategory)
          .collection(subSection);
        break;
      default:
        docsRef = db
          .collection('users')
          .doc(uid)
          .collection('products')
          .doc(productId)
          .collection('options')
          .doc(selectedVender)
          .collection(section);
        break;
    }

    await docsRef
      .doc(currentOptionId)
      .update({
        status: status,
      })
      .then(() => {
        console.log('Document successfully updated!');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });

    return {
      selectedVender: selectedVender,
      section: section,
      subSection: subSection,
      currentOptionId: currentOptionId,
      status: status,
    };
  }
);
