// import {  } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { uidSelector } from 'Auth/redux/selector';
import { modeSelector } from 'User/redux/selector';
import { updateMode } from 'User/firebase/updateMode';
import { IconButton } from '@mui/material';
import { Tooltip } from '@material-ui/core';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';

export const ToggleColorMode = () => {
  const uid = useSelector(uidSelector);
  const mode = useSelector(modeSelector);
  const dispatch = useDispatch();
  let newMode: 'light' | 'dark';

  const handleToggleMode = () => {
    switch (mode) {
      case 'light':
        newMode = 'dark';
        break;
      case 'dark':
        newMode = 'light';
        break;
      default:
        newMode = 'light';
        break;
    }
    dispatch(updateMode({ uid, newMode }));
  };

  return (
    <>
      {mode === 'dark' ? (
        <Tooltip title='ライトモードに切り替え' aria-label='change color mode'>
          <IconButton>
            <LightModeIcon onClick={handleToggleMode} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='ダークモードに切り替え' aria-label='change color mode'>
          <IconButton>
            <DarkModeIcon onClick={handleToggleMode} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
