import { createSelector } from 'reselect';
import { RootState } from 'store';
import { TemplatesState } from 'Settings/Templates/redux/slice';

export const templatesSelector: (state: RootState) => TemplatesState = (state: RootState) => state.templates;

export const vendersSelector = createSelector(templatesSelector, (templates) => {
  return templates.venders;
});

export const categoriesSelector = createSelector(templatesSelector, (templates) => {
  return templates.categories;
});

// export const optionsSelector = createSelector(templatesSelector, (templates) => {
//   return templates.options;
// });

export const gradesSelector = createSelector(templatesSelector, (templates) => {
  return templates.grades;
});

export const appearancesSelector = createSelector(templatesSelector, (templates) => {
  return templates.appearances;
});

export const paymentSelector = createSelector(templatesSelector, (templates) => {
  return templates.payment;
});

// export const subscriptionsSelector = createSelector(templatesSelector, (templates) => {
//   return templates.entities.subscriptions;
// });
